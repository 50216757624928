import { Injectable } from '@angular/core';
import { EntityService } from '../entity-service';
import { Variavel } from '../projeto.model';
import { VariaveisApi } from './variaveis.api';
import { ToastsService } from '../toasts.service';
import { FontesApiService } from './fontes-api.service';

@Injectable({
    providedIn: 'root'
})
export class VariaveisApiService implements EntityService<Variavel, null>  {
    private variaveis: Variavel[];
    private variaveis$: Promise<Variavel[]>;

    constructor(
        private api: VariaveisApi,
        private toasts: ToastsService,
        private fontes: FontesApiService,
    ) { }

    get_by_id(_id: number): Promise<Variavel> {
        throw new Error('Method not implemented.');
    }
    async list_all(): Promise<Variavel[]> {
        if (this.variaveis != undefined)
            return this.variaveis;
        if (this.variaveis$ != undefined)
            return await this.variaveis$;

        const close_toast = this.toasts.api_info($localize `Buscando variaveis`);
        try {
            this.variaveis$ = this.api.list_all();
            this.variaveis = await this.variaveis$;
            close_toast();
            return this.variaveis;
        } catch (error) {
            this.toasts.api_error(
                $localize `Erro ao carregar as variaveis`
            )
        } finally {
            this.variaveis$ = undefined;
            close_toast();
        }
    }

    async save(item: Variavel) {
        const close_toast = this.toasts.api_info($localize `Salvando fonte`);
        try {
            const data = await this.api.save(item);
            this.toasts.api_success($localize `Salvo com sucesso`);
            this.replaceVariavel(item, data);
            return data;
        } finally {
            close_toast();
        }
    }
    clone(_item: Variavel): Promise<Variavel> {
        throw new Error('Method not implemented.');
    }
    async delete(item: Variavel): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize `Excluindo a fonte`);
        const remover_fonte = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            this.variaveis = this.variaveis
                .filter((projetoIt) => projetoIt.id != item.id);
        };

        try {
            if (item.id != undefined)
                await this.api.delete(item);
            remover_fonte();
            return true;
        } catch (error) {
            this.toasts.api_error($localize `Não foi possível excluir o projeto`);
            return false;
        } finally {
            close_toast();
        }
    }

    get_parent(_item: Variavel): Promise<null> {
        throw new Error('Method not implemented.');
    }
    list_by_parent_id(_parent_id: number): Promise<Variavel[]> {
        throw new Error('Method not implemented.');
    }

    select(_item: Variavel) {
        throw new Error('Method not implemented.');
    }
    get_selected(): Variavel {
        throw new Error('Method not implemented.');
    }
    is_selected(_item: Variavel): boolean {
        throw new Error('Method not implemented.');
    }
    clear_selected() {
        throw new Error('Method not implemented.');
    }

    clear_caches() {
        this.variaveis = undefined;
        this.fontes.clear_caches();
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////


    public replaceVariavel(old: Variavel, newP: Variavel) {
        if (old.id == undefined)
            return this.variaveis.push(newP);

        this.variaveis.map(
            (proj) => (+proj.id === +old.id) ? newP : proj
        )
    }
}
