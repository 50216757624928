<div class="modal-header" i18n>
    AJUDA DA HOME
</div>
<div class="modal-content">
    <p>
        <strong i18n>Na página home do Editor Thoreb, você tem acesso facilitado às principais funcionalidades do sistema.</strong>
    </p>
    <p i18n>
        Aqui, você pode ver e editar projetos e roteiros.
        <br>
        Clicando no ícone de <em>+</em>, você pode cadastrar um novo projeto. Lembrando que um projeto não é nada mais que um grupo de roteiros! Em geral, você vai definir um
        projeto para cada tipo de ônibus que possui (mas não está restrito à isso, a organização dos projetos pode ser feita como você bem entender).
    </p>
    <img class="full-img" src="assets/img/ajuda-projetos.png" alt="Adicionar projeto" i18n-alt>
    <p><em i18n>Você também pode se orientar pelo menu contextual no lado esquerdo da tela.</em></p>
    <p i18n>Para selecionar um projeto, clique nele. Você notará que o círculo ficará azulado, indicando que o projeto está selecionado:</p>
    <img class="full-img" src="assets/img/ajuda-projetos-2.png" alt="Selecionar projeto" i18n-alt>
    <img class="full-img" src="assets/img/ajuda-projetos-5.png" alt="Selecionar projeto" i18n-alt>
    <p i18n>
        Após selecionar o projeto, os roteiros cadastrados naquele projeto são exibidos, e serão exibidos ícones para ações com os projetos (simular, duplicar e editar).
        Também após selecionar o roteiro desejado, os paineis do projeto são exibidos.
    </p>
    <img class="full-img" src="assets/img/ajuda-projetos-3.png" alt="Selecionar tudo" i18n-alt>
    <p i18n>Após clicar em um painel, você será redirecionado para a tela de edição de mensagens.</p>
    <p><strong i18n>Sobre o menu contextual</strong></p>
    <div class="row">
        <p i18n>
            O menu contextual é uma ferramenta que auxilia o usuário a utilizar um fluxo mais rápido de acesso às páginas do sistema editor. Cada item do menu representa
            uma ação a ser tomada a partir do estado da aplicação, ou seja, do que pode ser feito! <br>
            Por exemplo, você não precisa sair da tela de edição de roteiros, navegar para a listagem para poder criar um novo projeto. Basta apenas clicar em <em>Novo Projeto</em>!
        </p>
        <img class="full-img" src="assets/img/ajuda-projetos-4.png" alt="Menu contextual" i18n-alt>
    </div>
    <p i18n>
        As opções do menu incluem:
    </p>
    <ul>
        <li i18n><b>Lista de projetos:</b> Ir para a listagem de projetos</li>
        <li i18n><b>Novo projeto:</b> Ir para a tela de cadastro de projetos</li>
        <li i18n><b>Editar projeto:</b> Ir para a edição do projeto selecionado</li>
        <li i18n><b>Roteiros:</b> Ir para a edição dos roteiros do projeto</li>
        <li i18n><b>Simulador:</b> Simular o projeto selecionado</li>
        <li i18n><b>Transferir:</b> Transferir o projeto selecionado para o painel conectado através do aplicativo do Editor</li>
    </ul>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
