<div class="ml mr">
    <h1 i18n>Editar usuário</h1>
    <!-- {{usuario | json}} -->
    <form #usuarioForm="ngForm">

        <app-usuario-form [usuario]="usuario" *ngIf="usuario != undefined"></app-usuario-form>

        <button class="btn btn-success" type="submit" name="button" (click)="salvar(usuario)" [disabled]="!usuarioForm.form.valid" i18n>Editar</button>
    </form>
</div>
