<div class="ml mr">
    <h1 i18n>Cadastro de empresa</h1>
    <form #empresaForm="ngForm" *ngIf="empresa != undefined">

        <h2 i18n>Informações da empresa</h2>
        <app-empresa-form [empresa]="empresa"></app-empresa-form>


        <h2 i18n>Informações do administrador</h2>
        <app-usuario-form [usuario]="empresa.usuarios[0]"></app-usuario-form>

        <button class="btn btn-success" type="submit" name="button" (click)="editar()" [disabled]="!empresaForm.form.valid" i18n>Editar</button>
    </form>
</div>
