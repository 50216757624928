import { Component, OnInit } from '@angular/core';
import { SuporteApiService } from '@app/services/suporte-api.service';

@Component({
    selector: 'app-downloader',
    templateUrl: './downloader.component.html',
    styleUrls: ['./downloader.component.scss']
})
export class DownloaderComponent implements OnInit {

    constructor(
        public api: SuporteApiService
    ) { }

    ngOnInit() {
    }

    public async buscar() {
        if (this.api.pesquisa) {
            this.api.projetos = [];
            const data = await this.api.pesquisarProjetos();
            console.log(data)
        }
    }
}
