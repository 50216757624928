<div class="ml mt mr">
    <h1 i18n>Cadastrar recurso</h1>
    <form #recursoForm="ngForm">
        <app-fontes-form-recurso
            [recurso]="recurso"
            [x]="recurso.largura"
            [y]="recurso.tamanho"
        ></app-fontes-form-recurso>
        <button
            id="salvar-recurso"
            class="btn btn-success"
            type="submit"
            name="button"
            (click)="submit()"
            [disabled]="!recursoForm.valid || submitting"
            i18n
        >Cadastrar recurso</button>
    </form>
</div>
