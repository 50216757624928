import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Projeto, Mensagem } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";
import { ProjetoApi } from "./projeto.api";

@Injectable({
    providedIn: "root",
})
export class MensagemApi implements ApiClient<Mensagem, Projeto> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient,
        private parent: ProjetoApi,
        @Inject(LOCALE_ID) private lang: string
    ) { }

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/mensagem`;
        } else {
            return `${this.apiPoint}/mensagem/${id}`;
        }
    }
    get_by_id(id: number): Promise<Mensagem> {
        return this.http.get<Mensagem>(this.api_endpoint(id)).toPromise();
    }
    list_all(): Promise<Mensagem[]> {
        return this.http.get<Mensagem[]>(this.api_endpoint()).toPromise();
    }
    save(item: Mensagem): Promise<Mensagem> {
        if (item.id !== undefined) {
            return this.http
                .put<Mensagem>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            return this.http
                .post<Mensagem>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
    }
    delete(item: Mensagem): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(item: Mensagem): Promise<Mensagem> {
        throw new Error("Method not implemented.");
    }

    // métodos não implementados pois porjetos não tem parente
    get_parent(item: Mensagem): Promise<Projeto> {
        return this.parent.get_by_id(item.idRoteiro);
    }
    list_by_parent_id(parent_id: number): Promise<Mensagem[]> {
        const endpoint = `${this.apiPoint}/mensagem/by-parent-id/${parent_id}`;
        return this.http.get<Mensagem[]>(endpoint).toPromise();
    }
}
