import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ProjetoApiService } from './services/projeto-api.service';
// import { DesenhosApiService } from './services/desenhos-api.service';
import { LoaderService } from './services/loader.service';
import { ContextOptionsService, Action } from './services/context-options.service';
import { HelpService } from './services/help.service';
import { AsideOpenService } from './services/aside-open.service';
import { AuthService } from './services/auth.service';

import { routeAnim } from '@app/animations/routeAnim';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { VariaveisApiService } from './services/variaveis-api.service';
import { RecursosApiService } from './services/recursos-api.service';
import { FontesApiService } from './services/fontes-api.service';
import { DesenhosApiService } from './services/desenhos-api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routeAnim]
})
export class AppComponent implements OnInit {
    public tooltips = {
        'ajuda': $localize `Ajuda`,
    };

    public constructor(
        public contextOptions: ContextOptionsService,
        public loader: LoaderService,
        public help: HelpService,
        public asideOpen: AsideOpenService,

        public auth: AuthService,
        public http: HttpClient,
        public projetoApi: ProjetoApiService,
        public desenhosApi: DesenhosApiService,
        public fonteApi: FontesApiService,
        // public recursoApi: RecursosApiService,
        // public variavelApi: VariaveisApiService,

        public toastr: ToastrService,
    ) { }

    ngOnInit(): void {
        this.contextOptions.actions.push(
            new Action('lista', $localize `Lista de projetos`, 'icon-dashboard', this.contextOptions.router, 'navigate', [['home']]),
            new Action('novo', $localize `Novo projeto`, 'icon-add', this.contextOptions.router, 'navigate', [['projetos', 'cadastrar']]),
            new Action('importar', $localize `Importar projeto`, 'icon-import', this.contextOptions.router, 'navigate', [['projetos', 'importar']]),
        );

        this.validate_login_and_load();
    }

    /**
     * Essa função carrega todo o conteúdo que sempre está disponível na iterface:
     * [ Projetos, Fontes, Recursos, Variáveis ]
     * Mas antes disso também é feita a verificação se a API está rodando
     * e também se o login atual do usuário é válido
     */
    async validate_login_and_load() {
        // Garantir que o angular não vai tentar fazer parse do conteúdo da resposta
        // O conteúdo deve ser uma string vazia, normalmente
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'text/html, application/xhtml+xml, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            responseType: 'text' as 'json'
        };

        // GET na raiz da API para verificar se ela está rodando
        try {
            const data = await this.http
                .get(`${environment.endpoint}/ping`, {
                    responseType: 'text'
                })
                .toPromise();
            if (data != "pong")
                throw new Error("falid to connect to API")
        } catch (error) {
            this.toastr.error(
                $localize `Por favor, entre em contato com o suporte`,
                $localize `Sistema inacessível`
            );
            return;
        }

        let load_info = async (user) => {
            if (user.tecnico) return;

            this.projetoApi.list_all();
            await this.desenhosApi.list_all();
            await this.fonteApi.list_all();
        };

        this.auth.user$.subscribe(user => {
            if(!user) return;
            // GET na na lista de usuários, para ver essa lista basta estar logado
            // então isso serve como uma verificação se o login atual é válido
            this.http.get(`${environment.endpoint}/login`, httpOptions)
                .subscribe(load_info, () => {
                    console.warn("GET /api/login failed, logging user out")
                    this.auth.logout()
                })
        })
    }

    exibirModal() {
        this.help.isVisible = true;
    }

    routeName(outlet: RouterOutlet) {
        if (outlet && outlet.activatedRouteData) {
            return outlet.activatedRouteData.name;
        }
        return 'home';
    }
}
