import { Component, OnInit } from '@angular/core';
import { HelpService } from '../services/help.service';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {

    constructor(public help: HelpService) { }

    ngOnInit() {
    }

    fechar() {
        this.help.isVisible = false;
    }

    exibirModal() {
        this.help.isVisible = true;
    }

    landing() {
        return this.help.local == 'landing';
    }

    home() {
        return this.help.local == 'home';
    }

    editor() {
        return this.help.local == 'editor';
    }

    projetos() {
        return this.help.local == 'projetos';
    }

    roteiros() {
        return this.help.local == 'roteiros';
    }

    simulador() {
        return this.help.local == 'simulador';
    }

    empresa() {
        return this.help.local == 'empresa'; // escrever
    }

    empresaUsuario() {
        return this.help.local == 'empresa-usuario'; // escrever
    }

    recursos() {
        return this.help.local == 'recursos'; // escrever
    }

    fontes() {
        return this.help.local == 'fontes'; // escrever
    }

    login() {
        return this.help.local == 'login'; // escrever
    }

    tutorial() {
        return this.help.local == 'tutorial'; // escrever
    }
}
