import { Injectable } from '@angular/core';
import { ContextOptionsService } from './context-options.service';
import { Painel, Projeto } from '@app/projeto.model';

import { ErrorHandlerService } from '@app/shared/services/error-handler.service';
import { EntityService } from '@app/entity-service';
import { ToastsService } from '@app/toasts.service';
import { PainelApi } from './painel.api';
// import { ProjetoApiService } from './projeto-api.service';

import { MensagemApiService } from './mensagem-api.service';

@Injectable({
    providedIn: 'root'
})
export class PainelApiService implements EntityService<Painel, Projeto>  {
    private paineis: Painel[];
    private painelAtivo: Painel;

    constructor(
        // private http: HttpClient,
        private toasts: ToastsService,
        // private projetoApi: ProjetoApiService,
        private paineisApi: PainelApi,
        private contextOptions: ContextOptionsService,
        private errorHandler: ErrorHandlerService,
        private mensagemApi: MensagemApiService,
    ) {
    }

    async list_all(): Promise<Painel[]> {
        if (this.paineis != undefined)
            return this.paineis;
        const close_toast = this.toasts.api_info($localize `Buscando projetos`);
        try {
            this.paineis = await this.paineisApi.list_all();
            close_toast();
            return this.paineis;
        } catch (error) {
            this.toasts.api_error(
                $localize `Erro ao carregar os projetos`
            )
        }
        close_toast();
    }

    async get_by_id(id: number): Promise<Painel> {
        // const close_toast = this.toasts.api_info(this.i18n('Buscando roteiros'));
        try {
            let projeto = await this.paineisApi.get_by_id(id);
            return projeto;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os roteiros`);
        } finally {
            // close_toast();
        }
    }

    async save(item: Painel): Promise<Painel> {
        const close_toast = this.toasts.api_info($localize `Salvando painel`);
        try {
            const data = await this.paineisApi.save(item);
            this.toasts.api_success($localize `Salvo com sucesso`);
            this.replaceProjeto(item, data);
            close_toast();
            return data;
        } catch (error) {
            close_toast();
            this.errorHandler.handleError(error).subscribe();
            throw error;
        }
    }

    async delete(item: Painel): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize `Excluindo o painel`);
        const remover_projeto = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            this.paineis = this.paineis
                .filter((projetoIt) => projetoIt.id != item.id);
            this.painelAtivo = undefined;
            // TODO: remover isso junto com a adição dos contextOptions
            this.contextOptions.limparPorLabel('api');
        };

        try {
            if (item.id != undefined)
                await this.paineisApi.delete(item);
            remover_projeto();
            return true;
        } catch (error) {
            this.toasts.api_error($localize `Não foi possível excluir o painel`);
            return false;
        } finally {
            close_toast();
        }
    }


    async clone(item: Painel): Promise<Painel> {
        const close_toast = this.toasts.api_info($localize `Duplicando painel`);
        try {
            const data = await this.paineisApi.clone(item);
            this.toasts.api_success($localize `Duplicado com sucesso`);
            this.paineis = [...(this.paineis), data];
            return data;
        } catch (error) {
            this.toasts.api_error($localize `Não foi possível duplicar o painel`);
        } finally {
            close_toast();
        }
    }

    async list_by_parent_id(parent_id: number): Promise<Painel[]> {
        const close_toast = this.toasts.api_info($localize `Buscando roteiros`);
        try {
            let res = await this.paineisApi.list_by_parent_id(parent_id);
            this.paineis = res;
            return this.paineis;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os roteiros`);
        } finally {
            close_toast();
        }
    }
    // get_parent(item: Painel): Promise<Projeto> {
    //     throw new Error('Method not implemented.');
    //     // return this.projetoApi.get_by_id(item.parent_id);
    // }

    // Gerenciar o painel ativo atualmente
    select(item: Painel) {
        this.painelAtivo = item;
        this.mensagemApi.clear_caches();
    }
    get_selected(): Painel {
        return this.painelAtivo;
    }
    is_selected(p: Painel): boolean {
        return this.painelAtivo && this.painelAtivo.id == p.id;
    }
    clear_selected() {
        this.painelAtivo = undefined;
    }
    clear_caches() {
        this.paineis = undefined;
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////
    public replaceProjeto(old: Painel, newP: Painel) {
        if(!this.paineis) return;
        if (old.id == undefined)
            return this.paineis.push(newP);

        this.paineis = this.paineis.map(
            (proj: Painel) => (+proj.id === +old.id) ? newP : proj
        )
    }
}
