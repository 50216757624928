import { Injectable } from '@angular/core';
import * as model from '../projeto.model';

@Injectable({
    providedIn: 'root'
})
export class PositionCalculator {

    constructor() {}

    public encontrarPosicaoVazia(mensagem: model.Mensagem, width: number, height: number, posicaoPadrao?: number[]) {
        const posicao = posicaoPadrao != undefined ? posicaoPadrao : [0, 0];

        const boxes = this.calcBoxes(mensagem.grupos);
        for (let j = posicao[1]; j < height; j++) {
            for (let i = posicao[0]; i < width; i++) {
                if (this.estaLivre(boxes, i, j)) {
                    return [i, j];
                }
            }
        }

        return false;
    }

    public estaLivre(boxes, pX: number, pY: number): boolean {
        let livre = true;
        boxes.forEach((box) => {
            if (pX >= +box[0] && pX <= box[1] && pY >= box[2] && pY <= box[3]) {
                livre = false;
            }
        });

        return livre;
    }

    public calcBoxes(grupos: model.Grupo[]) {
        const boxes = [];
        grupos.forEach((grupo: model.Grupo) => {
            boxes.push([
                grupo.start_x, grupo.end_x,
                grupo.start_y, grupo.end_y,
                grupo
            ]);
        });
        return boxes;
    }
}
