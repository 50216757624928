<div class="ml mr">
    <h1 i18n>Empresas</h1>
    <button class="btn btn-primary" type="button" name="button" routerLink="/empresa/cadastrar" i18n>Nova empresa</button>
    <div class="deck">
        <!-- {{empresas | json}} -->
        <div class="bordered card mr mb" *ngFor="let empresa of empresas">
            <h2>
                <span class="nome-empresa">
                    {{empresa.nome}}
                </span>
                <span (click)="alterarBloqueio(empresa)" [class.switch-label-on]="!empresa.bloqueado" class="switch-label" *ngIf="empresa.id != 1"></span>
            </h2>
            <p><ng-container i18n>CNPJ</ng-container> {{empresa.cnpj}}</p>
            <div class="users" *ngFor="let usuario of empresa.usuarios; last as isLast">
                <ng-container>
                    {{usuario.nome}} <ng-container *ngIf="usuario.administrador">(admin)</ng-container>
                    <br *ngIf="isLast">
                </ng-container>
            </div>
            <div class="btn-container mt">
                <button class="btn btn-outline outline-danger" (click)="excluir(empresa)" type="button" name="button" *ngIf="empresa.id != 1" i18n>Excluir</button>
                <button class="btn btn-primary" type="button" name="button" routerLink="/empresa/{{empresa.id}}/editar" i18n>Editar</button>
            </div>
        </div>
    </div>
</div>
