import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { AuthService } from '@app/services/auth.service';
import * as model from '@app/projeto.model';


@Component({
  selector: 'app-editar-empresa',
  templateUrl: './editar-empresa.component.html',
  styleUrls: ['./editar-empresa.component.scss']
})
export class EditarEmpresaComponent {
    public empresa: model.Empresa;

    constructor(
        private usuarioApi: UsuarioApiService,
        public auth: AuthService,
        private loader: LoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private help: HelpService,
    ) {
        this.titleService.setTitle($localize `Editar Empresa - Thoreb Itinerário`);
        this.help.local = 'empresa';


        const id = +this.route.snapshot.paramMap.get('id');

        this.usuarioApi.procurarEmpresaAdmin(id).subscribe((empresa: model.Empresa) => {
            if (empresa) {
                empresa.usuarios = empresa.usuarios.filter((usuario: model.Usuario) => usuario.administrador);

                this.empresa = empresa;
            } else {
                this.router.navigateByUrl('/empresa/listar');
            }
        });
    }

    editar() {
        this.loader.startLoading();
        this.usuarioApi.salvarEmpresa(this.empresa).subscribe((data) => {
            this.loader.stopLoading();
            this.router.navigate(['empresa', 'listar']);
        });
    }
}
