import { Component, OnInit } from '@angular/core';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { Router } from '@angular/router';

import * as model from '@app/projeto.model';

import { RoteiroApiService } from '@app/services/roteiro-api.service';
import { PainelApiService } from '@app/services/painel-api.service';
import { LoaderService } from '@app/services/loader.service';

@Component({
    selector: 'app-projetos-lista',
    templateUrl: './projetos-lista.component.html',
    styleUrls: ['./projetos-lista.component.scss']
})
export class ProjetosListaComponent implements OnInit {
    public projetos: model.Projeto[] = [];

    public tooltips = {
        'duplicar': $localize`Duplicar`,
        'editar': $localize`Editar`,
        'novo': $localize`Novo projeto`,
        'simular': $localize`Simular`,
        'exportar': $localize`Exportar`,
        'importar': $localize`Importar`,
    };

    constructor(
        public projetosApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
        private loader: LoaderService,
        private router: Router,
    ) { }

    async ngOnInit() {
        try {
            this.loader.startLoading();
            this.projetos = await this.projetosApi.list_all();
        } finally {
            this.loader.stopLoading();
        }
    }

    selectProjeto(projeto: model.Projeto) {
        this.projetosApi.get_by_id(projeto.id);
        this.projetosApi.select(projeto);
        this.roteiroApi.clear_selected();
        this.painelApi.clear_selected();
    }

    async duplicar(projeto: model.Projeto) {
        const data = await this.projetosApi.clone(projeto);
        this.router.navigate(['projetos', 'editar', data.id]);
    }

    exportarProjeto() {
        const projeto = this.projetosApi.get_selected();
        console.log("exportando projeto: ", projeto);
        if (!projeto || !projeto.id) return console.error("Impossível exportar, nenhum projeto selecionado", projeto);
        this.projetosApi.exportarProjeto(projeto);
    }
}
