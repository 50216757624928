<!-- <button type="button" name="button" (click)="exibirModal()">Exibir</button> -->
<div class="overlay visible" (click)="dispensar()">
    <div class="modal elevation-3" (click)="$event.stopPropagation()">
        <div class="modal-header" i18n>
            ATALHOS
        </div>
        <div class="modal-content">
            <table>
                <tr>
                    <th i18n>Alt</th>
                    <th i18n>Shift</th>
                    <th i18n>Tecla</th>
                    <th i18n>Função</th>
                </tr>
                <tr *ngFor="let shortcut of shortcuts">
                    <td>{{shortcut.alt ? '✓' : ''}}</td>
                    <td>{{shortcut.shift ? '✓' : ''}}</td>
                    <td>{{shortcut.alias}}</td>
                    <td>{{shortcut.desc}}</td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
            <a (click)="fechar.emit()" i18n>Fechar</a>
        </div>
    </div>
</div>
