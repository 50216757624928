<div class="ml mt mr">
    <h1 i18n>Edição de recursos</h1>
    <button
        id="criar-novo-recurso"
        class="btn btn-primary"
        type="button"
        routerLink="/recursos/cadastrar"
        [disabled]="recursos == null"
        i18n
    >
        Novo recurso
    </button>
    <button
        id="upload-de-recurso"
        class="btn btn-primary"
        type="button"
        routerLink="/recursos/upload"
        [disabled]="recursos == null"
        i18n
    >
        Upload de imagem
    </button>
    <ul class="container mt">
        <li
            class="bordered mb mr flex-column"
            *ngFor="let desenho of recursos; let i = index"
        >
            <div class="">{{ desenho.nome }}</div>
            <div class="flex-grow mt mb">
                <canvas class="canvas" #canvas [attr.i]="i"></canvas>
            </div>
            <div class="mt">
                <button
                    class="btn btn-outline outline-danger excluir-recurso"
                    type="button"
                    name="button"
                    #excluir
                    (click)="excluirRecurso(desenho, $event);"
                    i18n
                >
                    Excluir
                </button>
                <button
                    class="btn btn-primary editar-recurso"
                    type="button"
                    name="button"
                    (click)="navigate(desenho.id)"
                    i18n
                >
                    Editar
                </button>
            </div>
            <!-- <button class="btn btn-outline" type="button" name="button" (click)="duplicarFonte(desenho)">Duplicar Fonte</button> -->
        </li>
    </ul>
</div>
