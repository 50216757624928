import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Alteravel } from '@app/alteravel';
import * as model from '@app/projeto.model';

import { PainelApiService } from '@app/services/painel-api.service';
import { ToastsService } from '@app/toasts.service';

import { FontesApiService } from "@app/services/fontes-api.service";
import { RecursosApiService } from "@app/services/recursos-api.service";
import { VariaveisApiService } from "@app/services/variaveis-api.service";

@Component({
    selector: 'app-projetos-importar',
    templateUrl: './projetos-importar.component.html',
    styleUrls: ['./projetos-importar.component.scss']
})
export class ProjetosImportarComponent implements Alteravel {
    public alteracao: boolean;
    public reader = new FileReader();

    public file_contents: model.ExportedProject;
    public file_name: string;
    public no_file_selected_text = $localize `Nenhum arquivo selecionado`;

    constructor(
        private projetosApi: ProjetoApiService,
        private paineisApi: PainelApiService,

        private fontesApi: FontesApiService,
        private recursosApi: RecursosApiService,
        private variaveisApi: VariaveisApiService,

        private router: Router,
        private loader: LoaderService,
        private toasts: ToastsService,
        private help: HelpService,
        private titleService: Title,
    ) {
        // this.projeto = {
        //     'nome': i18n('Novo projeto'),
        //     'descricao': '',
        // };
        this.help.local = 'projetos';
        this.titleService.setTitle($localize `Importar projeto - Thoreb Itinerário`);
    }

    async salvar() {
        try {
            this.loader.startLoading();

            const proj = await this.projetosApi.importarProjeto(this.file_contents);

            this.projetosApi.clear_caches();
            this.paineisApi.clear_caches();

            this.fontesApi.clear_caches();
            this.recursosApi.clear_caches();
            this.variaveisApi.clear_caches();

            this.projetosApi.select(proj);
            this.router.navigate(['projetos', proj.id, 'roteiros']);
        } catch (e) {
            console.error(e);
            this.toasts.api_error("Falha ao salvar o projeto");
        } finally {
            this.loader.stopLoading();
        }
    }

    onFileSelected(event) {
        const file:File = event.target.files[0];
        if (file) {
            const reader = this.reader;
            reader.onload = () => {
               if (!(typeof reader.result == "string")) {
                   this.toasts.api_error("Erro ao ler arquivo");
                   return;
               }

               let file_contents: model.ExportedProject;
               try {
                   file_contents = JSON.parse(reader.result);
               } catch(e) {
                   this.toasts.api_error("Erro ao ler arquivo, formato inválido");
                   console.error(e);
                   return;
               }

               if (!(
                   (file_contents) &&
                   (file_contents.version) &&
                   (file_contents.data)
               )) {
                   throw "Formato json inválido";
               }

               this.file_contents = file_contents;
               this.file_name = file.name;

               console.log(this.file_contents);
               console.log(this.file_contents.data.descrição);
            };
            reader.readAsText(file, 'UTF-8');
        }
    }
}
