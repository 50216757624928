import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { AsideOpenService } from '@app/services/aside-open.service';


@Component({
    selector: 'app-esqueci-senha',
    templateUrl: './esqueci-senha.component.html',
    styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit {

    public email: string;
    public senha: string;
    public token: string;
    private idUser: number;

    public error = '';

    public passwordToggled = false;
    public enviouEmail: boolean;
    public submitting: boolean;

    public regex = new RegExp('[A-Za-z].*[0-9]|[0-9].*[A-Za-z]', 'i');

    constructor(
        private titleService: Title,
        public asideOpen: AsideOpenService,
        private api: UsuarioApiService,
        private router: Router,
    ) {
        this.asideOpen.isBlocked = true;
        this.titleService.setTitle($localize `Esqueci minha senha - Thoreb Itinerário`);
    }

    ngOnInit() {
        this.enviouEmail = false;
        this.submitting = false;
    }

    togglePassword() {
        this.passwordToggled = !this.passwordToggled;
    }

    enviarEmail() {
        this.submitting = true;
        this.api.enviarEmailRecuperacaoSenha(this.email)
        .pipe(
            finalize(() => this.submitting = false)
        )
        .subscribe(
            usr => {
                console.log(usr);
                this.enviouEmail = true;
                this.error = '';
                this.idUser = usr.id;
            },
            error => {
                if (error.status == 404) {
                    this.error = $localize `Usuário inexistente`;
                } else if (error.status == 422) {
                    this.error = $localize `Algum erro desconhecido aconteceu. Por favor, tente novamente. Caso o erro persista, contate a Thoreb Brasil`;
                }
            }
        );
    }

    trocarSenha() {
        this.submitting = true;
        this.api.alterarSenhaComToken(this.idUser, this.token, this.senha, this.email)
        .pipe(
            finalize(() => this.submitting = false)
        )
        .subscribe(
            res => {
                this.error = '';
                this.router.navigate(['/login']);
            },
            error => {
                if (error.status == 404) {
                    this.error = $localize `Usuário inexistente`;
                } else if (error.status == 422) {
                    this.error = $localize `Código incorreto`;
                }
            }
        );
    }

}
