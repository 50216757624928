import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    constructor(
        private toastr: ToastrService,
    ) { }

    api_info(msg: string) {
        let toast: ActiveToast<any>;
        // Sinceramente, eu não faço ideia do por quê
        // mas a criação dos toats precisa ficar dentro do setTimeout
        // senão o Angular joga um "ExpressionChangedAfterItHasBeenCheckedError"
        setTimeout(() => toast = this.toastr.info(msg, '', {
            'disableTimeOut': true
        }));
        return () => {
            setTimeout(() => {
                toast.toastRef.manualClose();
            }, 1500);
        };
    }

    api_success(msg) {
        setTimeout(() => this.toastr.success(msg));
    }

    api_error(msg) {
        setTimeout(() => this.toastr.error(msg));
    }
}
