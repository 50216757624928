import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ContatoApiService } from '@app/services/contato-api.service';
import { Title } from '@angular/platform-browser';

import * as model from '@app/projeto.model';


@Component({
    selector: 'app-contato-lista',
    templateUrl: './contato-lista.component.html',
    styleUrls: ['./contato-lista.component.scss']
})
export class ContatoListaComponent implements OnInit, AfterViewChecked {

    public isReady = false;

    public get contatos() {
        return this.api.contatos;
    }

    constructor(
        private api: ContatoApiService,
        private cdr: ChangeDetectorRef,
        private titleService: Title,
    ) {
        this.api.buscarContatos().subscribe(
            () => this.isReady = true
        );
    }

    ngOnInit() {
        this.titleService.setTitle($localize `Contatos - Thoreb Itinerário`);
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    excluir(c: model.Contato) {
        this.api.excluirContato(c).subscribe(
            () => console.log("excluido")
        );
    }

    passouDoTamanho(el) {
        return (el.scrollHeight > el.clientHeight + 1) && el.getAttribute('collapsed') == 'false';
    }

    toggle(el) {
        if (el.getAttribute('collapsed') == 'false') {
            el.setAttribute('collapsed', 'true');
        } else {
            el.setAttribute('collapsed', 'false');
        }
    }
}
