import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-file-input',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './file-input.component.html',
    styleUrl: './file-input.component.scss'
})
export class FileInputComponent {
    public mode: 'string' | 'arrayBuffer';

    public file_name: string;

    private reader = new FileReader();

    @Output() change = new EventEmitter<File | string>();

    onFileSelected(event: Event) {
        const file: File = (<HTMLInputElement>event.target).files[0];

        if (file) {
            this.file_name = file.name;

            const reader = this.reader;
            reader.onload = () => {
                this.change.emit(file);
            };
            if (this.mode == 'arrayBuffer') {
                reader.readAsArrayBuffer(file);
            } else {
                reader.readAsText(file, 'UTF-8');
            }
        }
    }
}

