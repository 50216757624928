<div class="modal-header" i18n>
    AJUDA DO LOGIN
</div>
<div class="modal-content">
    <p>
        <strong i18n>Faça login para utilizar as funcionalidades do Software Editor Thoreb.</strong>
    </p>
    <p i18n>Insira as informações que foram passadas para você pela Thoreb Brasil para utilizar o Editor.</p>
    <p i18n>
        <strong>Não possui login?</strong> Converse com o responsável pelo Itinerário em sua empresa caso ela já possua cadastro. Caso não possua, entre em contato conosco através do email <br>
        <a href="mailto:posvendas.br@thoreb.com">posvendas.br&#64;thoreb.com</a>.
    </p>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
