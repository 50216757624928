import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-recursos',
  templateUrl: './help-recursos.component.html',
  styleUrls: ['./help-recursos.component.scss']
})
export class HelpRecursosComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }
}
