import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { take, finalize, tap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from './loader.service';
import * as model from '../projeto.model';

import { environment } from '@app/../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UsuarioApiService {

    private apiPoint = environment.endpoint;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private loader: LoaderService,
        @Inject(LOCALE_ID) private lang: string,
    ) {
    }

    public async listarUsuarios({ tecnico, empresa_id }: Partial<{ tecnico: boolean, empresa_id: number }> = {}): Promise<model.Usuario[]> {
        let params = new HttpParams();
        if (tecnico != undefined) params = params.set('tecnico', `${tecnico}`);
        if (empresa_id != undefined) params = params.set('empresa', `${empresa_id}`);

        const opcoes = {
            params: params,
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return await this.http.get<model.Usuario[]>(`${this.apiPoint}/usuario`, opcoes).toPromise();
    }

    public async buscarUsuario(id: number): Promise<model.Usuario> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return await this.http.get<model.Usuario>(`${this.apiPoint}/usuario/${id}`, opcoes).toPromise();
    }

    public async salvarUsuario(usuario: model.Usuario): Promise<model.Usuario> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const toast = this.toastr.info($localize `Salvando usuário`, '', {
            'disableTimeOut': true
        });

        try {
            if (usuario.id != undefined) {
                return this.http.put<model.Usuario>(`${this.apiPoint}/usuario/${usuario.id}`, usuario, opcoes).toPromise();
            } else {
                return this.http.post<model.Usuario>(`${this.apiPoint}/usuario`, usuario, opcoes).toPromise();
            }
        } catch(e) {
            console.error(e);
            this.toastr.error($localize `Erro ao salvar usuário`);
            throw e;
        } finally {
            setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
        }
    }

    public excluirUsuario(usuario: model.Usuario): Observable<boolean> {
        const toast = this.toastr.info($localize `Excluindo usuário`, '', {
            'disableTimeOut': true
        });
        return this.http.delete<boolean>(`${this.apiPoint}/usuario/${usuario.id}`)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                })
            );
    }

    buscarEmail(valor: string, usuario: model.Usuario): Observable<boolean> {
        if (valor == usuario.email) { // se for igual não precisa bater no servidor
            // console.log('didnt hit');
            return of(false);
        }
        else {
            // console.log('hit');
            // buscar na api
            return this.http.get<boolean>(`${this.apiPoint}/email/${encodeURIComponent(valor)}`);
            // return of(true);
        }
    }

    alterarSenha(antiga: string, senha: string, usuario: model.Usuario): Observable<boolean> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const obj = {
            'antiga': antiga,
            'senha': senha
        };
        const toast = this.toastr.info($localize `Alterando senha`, '', {
            'disableTimeOut': true
        });

        return this.http.patch<boolean>(`${this.apiPoint}/usuario/${usuario.id}`, obj, opcoes)
            .pipe(
                tap(() => {
                    this.toastr.success($localize `Senha alterada com sucesso`);
                }),
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                })
            );
    }

    enviarEmailRecuperacaoSenha(email: string): Observable<any> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const obj = {
            'email': email,
            'lang': this.lang,
        };

        return this.http.post<any>(`${this.apiPoint}/senha`, obj, opcoes)
            .pipe(
                tap(() => {
                    this.toastr.success($localize `Um email com o código foi enviado para o endereço informado`)
                })
            );
    }

    alterarSenhaComToken(id: number, token: string, senha: string, email: string): Observable<boolean> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const obj = {
            'email': email,
            'senha': senha,
            'tokenTrocaSenha': token,
        };

        return this.http.patch<boolean>(`${this.apiPoint}/senha/${id}`, obj, opcoes)
            .pipe(
                tap(() => {
                    this.toastr.success($localize `Senha alterada com sucesso`)
                })
            );
    }

    salvarEmpresa(empresa: model.Empresa): Observable<model.Empresa> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const toast = this.toastr.info($localize `Salvando empresa`, '', {
            'disableTimeOut': true
        });

        if (empresa.id != undefined) {
            return this.http.put<model.Empresa>(`${this.apiPoint}/empresa/${empresa.id}`, empresa, opcoes)
                .pipe(
                    finalize(() => {
                        setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    })
                );
        } else {
            return this.http.post<model.Empresa>(`${this.apiPoint}/empresa`, empresa, opcoes)
                .pipe(
                    finalize(() => {
                        setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    })
                );
        }
    }

    public getEmpresa(): Observable<model.Empresa> {
        const toast = this.toastr.info($localize `Buscando empresa`, '', {
            'disableTimeOut': true
        });

        this.loader.startLoading();

        // buscar as fontes da api
        return this.http.get<model.Empresa>(`${this.apiPoint}/empresa`)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.loader.stopLoading();
                })
            );
    }

    public getEmpresaAdmin(): Observable<model.Empresa[]> {
        const toast = this.toastr.info($localize `Buscando empresas`, '', {
            'disableTimeOut': true
        });

        this.loader.startLoading();

        return this.http.get<model.Empresa[]>(`${this.apiPoint}/empresaadmin`)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.loader.stopLoading();
                })
            );
    }

    public procurarEmpresaAdmin(id: number): Observable<model.Empresa> {
        const toast = this.toastr.info($localize `Buscando empresa`, '', {
            'disableTimeOut': true
        });

        this.loader.startLoading();
        // buscar as fontes da api
        return this.http.get<model.Empresa>(`${this.apiPoint}/empresaadmin/${id}`)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.loader.stopLoading();
                })
            );
    }

    public atualizarEmpresa(empresa: model.Empresa): Observable<model.Empresa> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.patch<model.Empresa>(`${this.apiPoint}/empresa/${empresa.id}`, empresa, opcoes);
    }

    public excluirEmpresa(empresa: model.Empresa): Observable<boolean> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.delete<boolean>(`${this.apiPoint}/empresa/${empresa.id}`, opcoes);
    }

}
