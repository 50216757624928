<div class="modal-header" i18n>
    AJUDA DO ROTEIRO
</div>
<div class="modal-content">
    <p i18n><strong>No cadastro de roteiros, você pode especificar os diversos destinos que um mesmo projeto de ônibus pode tomar.</strong></p>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-roteiros-form.png" alt="Formulário" i18n-alt>
        <span>
            <p i18n>Esta página funciona de forma semelhante ao cadastro de paineis de um projeto, através de cards, onde cada um representa um roteiro.</p>
            <p i18n>
                Os roteiros possuem dois campos, um sendo o código de identificação e outro sendo o nome do roteiro.
                O código e o nome são informações exibidas ao motorista através do display do Itinerário.
            </p>
        </span>
    </div>
    <h2 i18n>Pesquisa</h2>
    <img class="full-img" src="assets/img/ajuda-roteiros-pesquisa-1.png" alt="Pesquisa" i18n-alt>
    <div class="row">
        <span i18n>
            Além disso, como os roteiros podem crescer de número rapidamente, nesta página é disponibilizado um campo de pesquisa. É só digitar que serão filtrados
            os roteiros, podendo ser pesquisado tanto por nome quanto por código.
        </span>
        <img class="inline-img" src="assets/img/ajuda-roteiros-pesquisa-2.png" alt="Pesquisa" i18n-alt>
    </div>
</div>
<div class="modal-footer">
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
