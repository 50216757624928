<div class="color-picker-container">
    <button #colorPreview class="color-preview card" (click)="toggleVisibility()"></button>
    <div [class.visible]="visible" class="background-filter hidden" (click)="toggleVisibility()"></div>
    <div [class.visible]="visible" class="color-picker card shadow hidden">
        <div class="canvases-container">
            <div class="canvas-container">
                <canvas
                    #valueSaturationCanvas
                    width="255"
                    height="255"
                    class="color-picker-value-saturation"
                ></canvas>
                <div #valueSaturationPin class="pin"></div>
            </div>
            <div class="canvas-container">
                <canvas
                    #hueCanvas
                    width="1"
                    height="255"
                    class="color-picker-hue"
                ></canvas>
                <div #huePin id="hue-pin" class="pin"></div>
            </div>
        </div>
        <div id="color-picker-rgb" class="form-section">
            <label class="input-group">
                R:
                <input #rInput (input)="handleRGBupdate()" type="number" class="rInput" />
            </label>
            <label class="input-group">
                G:
                <input #gInput (input)="handleRGBupdate()" type="number" class="gInput" />
            </label>
            <label class="input-group">
                B:
                <input #bInput (input)="handleRGBupdate()" type="number" class="bInput" />
            </label>
        </div>
        <button (click)="toggleVisibility()" class="confirm btn btn-success">Confirmar</button>
    </div>
</div>
