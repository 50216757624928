import { Directive, Input } from '@angular/core';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { Observable, pipe, timer } from "rxjs";
import { map, debounceTime, distinctUntilChanged, first, switchMap, skip } from 'rxjs/operators';

import * as model from '@app/projeto.model';

@Directive({
    selector: '[asyncEmail]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS, useExisting: AsyncEmailValidatorDirective, multi: true
        }
    ]
})
export class AsyncEmailValidatorDirective implements AsyncValidator {
    @Input('asyncEmail') usuario: model.Usuario;

    constructor(private usuarioApi: UsuarioApiService) {  }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        // console.log(control);
        return timer(750).pipe(
            distinctUntilChanged(),
            switchMap(() => {
                return this.usuarioApi.buscarEmail(control.value, this.usuario).pipe(
                    map (
                        result => {
                            return result ? {"asyncEmail": true} : null;
                        }
                    ),
                );

            })
        );
    }
}
