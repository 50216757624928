import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    public local: string;
    public isVisible: boolean;


    constructor() {
        this.isVisible = false;
        this.local = 'editor';
    }
}
