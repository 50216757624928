<div class="card elevation-hover-1" *ngIf="projeto != undefined" @fadeDown>
    <div class="table table-header">
        <div class="row header">
            <div class="cell">
                <span class="cell-header" i18n>
                    PAINEIS
                </span>
                <span>
                    <i class="icon clickable size-2 icon-color-white" routerLink="/projetos/editar/{{projeto.id}}">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-add.svg#icon"></use>
                        </svg>
                    </i>
                </span>
            </div>
        </div>
    </div>

    <div class="table" *ngIf="paineis != undefined">

        <button class="row"
            [class.selected]="painelApi.is_selected(painel)"
            (click)="editarPainel(painel)" *ngFor="let painel of paineis; let i = index">
            <div class="cell flex-size-1">
                <div class="checkbox"
                    [class.selected]="painelApi.is_selected(painel)"
                ></div>
            </div>
            <div class="panel-description cell flex-size-3">
                {{painel.localizacao}} x CAN {{painel.enderecoCan}}
            </div>
            <div class="cell flex-size-2">
                {{painel.dimension_y}} x {{painel.dimension_x}}
            </div>
        </button>

        <div class="row" *ngIf="paineis != undefined && paineis.length == 0">
            <div class="cell" routerLink="/projetos/editar/{{projeto.id}}" i18n>
                Nenhum painel cadastrado.<br />Clique aqui para cadastrar um.
            </div>
        </div>
    </div>
</div>
