<div class="card elevation-hover-1" *ngIf="roteiros" @fadeDown>
    <div class="table table-header">
        <div class="row header">
            <div class="cell">
                <span class="cell-header" i18n>ROTEIROS</span>
                <span>
                    <i id="roteiros-page-link" class="icon clickable size-2 icon-color-white" routerLink="/projetos/{{ projeto.id }}/roteiros">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-edit.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span>
                    <i class="icon clickable size-2 icon-color-white" routerLink="/projetos/{{ projeto.id }}/roteiros">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-add.svg#icon"></use>
                        </svg>
                    </i>
                </span>
            </div>
        </div>
    </div>

    <div class="table" *ngIf="roteiros != undefined">
        <ng-container *ngFor="let roteiro of roteiros; let i = index">
            <button class="row {{ roteirosApi.is_selected(roteiro) ? 'selected' : ''}}"
                (click)="roteirosApi.select(roteiro)">
                <div class="cell flex-size-1">
                    <div class="checkbox {{ roteirosApi.is_selected(roteiro) ? 'selected' : ''}}"></div>
                </div>
                <div class="cell flex-size-1">
                    {{roteiro.cod}}
                </div>
                <div class="destination-description cell flex-size-3">
                    {{roteiro.nome}}
                    <span *ngIf="roteiro.extra" class="badge ml" i18n>Extra</span>
                </div>
            </button>
        </ng-container>

        <div class="row" *ngIf="roteiros != undefined && roteiros.length == 0">
            <div class="cell" routerLink="/projetos/{{ projeto && projeto.id }}/roteiros" i18n>
                Nenhum roteiro cadastrado.<br />Clique aqui para cadastrar um.
            </div>
        </div>
    </div>
</div>

