import { Injectable } from '@angular/core';
import { ProjetoApi } from './projeto.api';
import { saveAs } from 'file-saver';
import * as model from '@app/projeto.model';


@Injectable({
    providedIn: 'root'
})
export class SuporteApiService {
    public pesquisa: string = "";
    private _projetos: model.Projeto[];

    set projetos(projetos: model.Projeto[]) {
        this._projetos = projetos;
    }

    get projetos() {
        return this._projetos;
    }

    constructor(
        private api: ProjetoApi,
    ) {
        this._projetos = [];
    }

    async pesquisarProjetos() {
        let projetos = await this.api.procurarProjetos(this.pesquisa);
        this.projetos = projetos;
    }

    async baixarProjeto(projeto: model.Projeto) {
        const data = await this.api.baixarProjeto(projeto.id);
        const blob = <any>(new Blob([data], { type: 'application/thd' }));
        saveAs(blob, `${projeto.nome}.thd`);
    }
}
