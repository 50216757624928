<section id="login">
    <article class="central">
        <img src="assets/img/thoreblogo.png" alt="Logo Thoreb" i18n-alt>
        <h2>Login</h2>
        <p i18n>
            Para acessar o sistema, digite as suas informações de login <br>
            Se você não possui cadastro, contate o suporte da Thoreb Brasil
        </p>
        <form>
            <div class="form-section">
                <label for="email" i18n>Email</label>
                <input id="email" type="mail" name="email" [(ngModel)]="user.email" placeholder="Digite seu email" i18n-placeholder>
            </div>
            <div class="form-section password-container">
                <label for="password" i18n>Senha</label>
                <input id="password" [attr.type]="passwordToggled ? 'text' : 'password'" name="password" [(ngModel)]="user.password" placeholder="Digite sua senha" i18n-placeholder>
                <span class="password-icon" (click)="togglePassword()">
                    <i class="icon size-2 clickable">
                        <svg viewBox="0 0 24 24">
                            <use [attr.xlink:href]="passwordToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                        </svg>
                    </i>
                </span>
            </div>
            <a routerLink="/login/esqueci-minha-senha/" class="mr mb" i18n>Esqueci minha senha</a>
            <button id="login-button" type="submit" name="button" class="btn btn-primary" (click)="submit()" [disabled]="submiting" i18n>Fazer login</button>
            <div class="alert danger" *ngIf="error">
                {{error}}
            </div>
        </form>
    </article>
</section>
