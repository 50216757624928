import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@app/../environments/environment';

import * as model from '../projeto.model';

import { ProjetoApiService } from './projeto-api.service';
import { RoteiroApiService } from './roteiro-api.service';
import { MensagemApiService } from './mensagem-api.service';
import { PainelApiService } from './painel-api.service';
import { FontesApiService } from './fontes-api.service';
import { RecursosApiService } from './recursos-api.service';
import { VariaveisApiService } from './variaveis-api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    // public user;

    public user$: BehaviorSubject<any>;
    private _user: model.Usuario;
    private endPoint = environment.endpoint;

    set user(user) {
        this._user = user;
        this.user$.next(user);
    }

    get user() {
        return this._user;
    }

    // static projetoApi = new ProjetoApiService();
    // public projetoApi: ProjetoApiService,

    constructor(
        private http: HttpClient,
        private router: Router,
        public projetoApi: ProjetoApiService,
        public painelApi: PainelApiService,
        public roteiroApi: RoteiroApiService,
        public mensagemApi: MensagemApiService,
        public fontesApi: FontesApiService,
        public recursosApi: RecursosApiService,
        public variaveisApi: VariaveisApiService,
    ) {
        this.user$ = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));
        this.user$.subscribe((user) => localStorage.setItem('user', JSON.stringify(user)));
        this._user = JSON.parse(localStorage.getItem('user'));
    }

    public async login(user) {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        this.user = await this.http
            .post<model.Usuario>(`${this.endPoint}/login`, user, opcoes)
            .toPromise();
    }

    public logout() {
        this.user = null;
        localStorage.removeItem('user')

        console.log("Limpando caches");

        this.projetoApi.clear_selected();
        this.painelApi.clear_selected();
        this.roteiroApi.clear_selected();
        this.mensagemApi.clear_selected();

        this.projetoApi.clear_caches();
        this.painelApi.clear_caches();
        this.roteiroApi.clear_caches();
        this.mensagemApi.clear_caches();
        this.fontesApi.clear_caches();
        this.recursosApi.clear_caches();
        this.variaveisApi.clear_caches();

        this.router.navigate(['login']);
    }
}
