import { trigger, transition, style, animate, query, stagger, state, sequence } from '@angular/animations';

const enter = [
    style({transform: 'translateX(-100%)'}),
    stagger('0.1s', [
        animate('0.1s', style({transform: 'translateX(0)'}))
    ])
];

const leave = [
    stagger('0.1s', [
        animate('0.15s', style({transform: 'translateX(-100%)'}))
    ])
];

export const swipeLeft = trigger('swipeLeft', [
    transition('* => *', [
        sequence([
            query(':leave', leave, { optional: true }),
            query(':enter', enter, { optional: true })
        ])
    ])
]);
