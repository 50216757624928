import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Variavel } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";

@Injectable({
    providedIn: "root",
})
export class VariaveisApi implements ApiClient<Variavel, null> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient
    ) { }

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/variavel`;
        } else {
            return `${this.apiPoint}/variavel/${id}`;
        }
    }
    cast(data) {
        return new Variavel(data);
    }
    async get_by_id(id: number): Promise<Variavel> {
        let res = await this.http.get<Variavel>(this.api_endpoint(id)).toPromise();
        return this.cast(res);
    }
    async list_all(): Promise<Variavel[]> {
        let res = await this.http.get<Variavel[]>(this.api_endpoint()).toPromise();
        return res.map(this.cast);
    }
    async save(item: Variavel): Promise<Variavel> {
        let res: Promise<Variavel>;
        if (item.id !== undefined) {
            res = this.http
                .put<Variavel>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            res = this.http
                .post<Variavel>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
        return this.cast(await res);
    }
    delete(item: Variavel): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(_item: Variavel): Promise<Variavel> {
        throw new Error("Method not implemented.");
    }

    // métodos não implementados pois fontes não tem parente
    get_parent(_item: Variavel): null {
        throw new Error("Method not implemented.");
    }
    list_by_parent_id(_parent_id: number): null {
        throw new Error("Method not implemented.");
    }
}
