<h1 i18n>Feedback</h1>
<p i18n>
    Tem alguma dúvida ou sugestão? Entre em contato conosco.
</p>
<form #formContato="ngForm">
    <div class="form-section">
        <label for="nome" i18n>Nome</label>
        <input type="text" name="nome" [(ngModel)]="contato.nome" #nomeT="ngModel" placeholder="Seu nome" required maxlength="50" [class.danger]="!nomeT.valid && !nomeT.pristine" i18n-placeholder>

        <div class="alert danger" *ngIf="nomeT.invalid && !nomeT.pristine">
            <div *ngIf="nomeT.errors?.required" i18n>Este campo é obrigatório</div>
            <div *ngIf="nomeT.errors?.maxlength" i18n>Máximo de 50 caracteres</div>
        </div>
    </div>
    <div class="form-section">
        <label for="nome" i18n>Mensagem</label>
        <textarea name="name" rows="8" cols="80" [(ngModel)]="contato.descricao" #descricaoT="ngModel" required
        [class.danger]="!descricaoT.valid && !descricaoT.pristine" placeholder="Escreva aqui seu comentário/sugestão" maxlength="65535" i18n-placeholder></textarea>

        <div class="alert danger" *ngIf="descricaoT.invalid && !descricaoT.pristine">
            <div *ngIf="descricaoT.errors?.required" i18n>Este campo é obrigatório</div>
            <div *ngIf="descricaoT.errors?.maxlength" i18n>Máximo de 65.535 caracteres</div>
        </div>
    </div>
    <button class="btn btn-success" type="submit" name="button" (click)="enviar()" [disabled]="!formContato.form.valid || submitting" i18n>Enviar mensagem</button>
</form>
