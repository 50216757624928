import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { VersionCheck } from '@app/services/version_check.service';
import { catchError, tap } from 'rxjs/operators';

/// Para garantir que o código do frontend é compatível com o backend
/// usamos o header "x-thoreb-api-version" para manter os dois sincronizados
/// o valor desse header é uma string, ela é preenchida na primeira request
/// que for tratada por esse interceptor.
/// Uma vez definido o valor, ele sempre será enviado em todas as requests.
/// Caso seja detectada uma mudança ao fazer o request, o backend ira retornar um erro.
/// Por redundância, o frontend também verifica se o valor retornado condiz com o atual
@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {
    constructor(
        private version_check: VersionCheck,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.version_check.versionString) {
            request = request.clone({
                setHeaders: {
                    "x-thoreb-api-version": this.version_check.versionString,
                }
            });
        }

        return next
            .handle(request)
            .pipe(
                tap((httpEvent: HttpEvent<any>) => {
                    if (httpEvent.type === 0) {
                        return;
                    }

                    if (httpEvent instanceof HttpResponse) {
                        let version = httpEvent.headers.get("x-thoreb-api-version");
                        if (version) {
                            this.version_check.update(version);
                        }
                    }
                }),
                catchError(err => {
                    try {
                        if (err.error.message_code == "OUTDATED_CLIENT_CODE") {
                            this.version_check.force_reload();
                        }
                    } finally {
                        return throwError(err);
                    }
                })
            );
    }
}
