import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { EditorViewComponent } from '@app/editor/editor-mensagem/editor-view/editor-view.component';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { TranslateResourcesService } from '@app/services/translate-resources.service';
import * as model from '@app/projeto.model';

import { ToastrService } from 'ngx-toastr';

import { ProjetoApiService } from '@app/services/projeto-api.service';
import { RoteiroApiService } from '@app/services/roteiro-api.service';
import { PainelApiService } from '@app/services/painel-api.service';
import { MensagemApiService } from '@app/services/mensagem-api.service';

@Component({
    selector: 'app-simulador',
    templateUrl: './simulador.component.html',
    styleUrls: ['./simulador.component.scss']
})
export class SimuladorComponent implements OnInit, OnDestroy {

    public projeto: model.Projeto;
    public roteiros: model.Roteiro[];
    public paineis: model.Painel[];
    public roteiroSelecionado: model.Roteiro;
    private roteiroComp: string;

    public mensagens_por_roteiro: Record<number, model.Mensagem[]> = {};
    public struct = [];
    public maxSize = 1;
    public speed = 32;

    private interval;

    @ViewChildren('view') views: QueryList<EditorViewComponent>;

    constructor(
        private route: ActivatedRoute,
        private projetoApi: ProjetoApiService,
        private roteiroApi: RoteiroApiService,
        private painelApi: PainelApiService,
        private mensagemApi: MensagemApiService,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title,
        private translate: TranslateResourcesService,
        private toastr: ToastrService,
    ) {
        this.titleService.setTitle($localize `Simulador - Thoreb Itinerário`);
        this.help.local = 'simulador';
    }

    async ngOnInit() {
        this.loader.startLoading();

        const id = +this.route.snapshot.paramMap.get('id');
        const projeto = await this.projetoApi.get_by_id(id);
        const roteiro_selecionado = await this.roteiroApi.get_selected();
        const roteiros = await this.roteiroApi.list_by_parent_id(projeto.id);
        const paineis = await this.painelApi.list_by_parent_id(projeto.id);

        if (!projeto) {
            this.toastr.warning($localize `Nenhum Projeto Selecionado`);
            this.loader.stopLoading();
            this.router.navigateByUrl('/home');
            return;
        }

        // Aqui fazemos uma cópia dos dados para que nenhum dado importante seja modificado por acidente
        this.projeto = JSON.parse(JSON.stringify(projeto));
        this.roteiros = JSON.parse(JSON.stringify(roteiros));
        this.paineis = JSON.parse(JSON.stringify(paineis));

        if (this.roteiros.length < 1) {
            this.toastr.warning($localize `Nenhum Roteiro`);
            this.loader.stopLoading();
            this.router.navigateByUrl('/home');
            return;
        }

        if (roteiro_selecionado) {
            const roteiro_local = this.roteiros.find(l_roteiro => l_roteiro.id == roteiro_selecionado.id);
            this.selectRoteiro(roteiro_local);
        } else {
            this.selectRoteiro(this.roteiros[0]);
        }

        this.loader.stopLoading();
    }

    ngOnDestroy() {
        clearTimeout(this.interval);
    }

    selectRoteiro(roteiro: model.Roteiro) {
        this.roteiroSelecionado = roteiro;
        this.roteiroComp = JSON.stringify(roteiro);
        this.prepararRoteiro(roteiro);
    }

    async prepararRoteiro(roteiro: model.Roteiro) {
        let mensagens = await this.mensagemApi.list_by_parent_id(roteiro.id);
        const paineis_mensagens = mensagens
            .reduce((accumulator, mensagem) => {
                accumulator[mensagem.idPainel] = accumulator[mensagem.idPainel] || [];
                accumulator[mensagem.idPainel].push(mensagem);
                return accumulator;
            }, {});

        this.mensagens_por_roteiro = paineis_mensagens;

        this.struct = this.paineis.map(p => {
            return {
                'painel': p,
                'mensagem': (this.mensagens_por_roteiro[p.id]) ? this.mensagens_por_roteiro[p.id][0] : {
                    'ordem': 0,
                    'tempo': 999999,
                    'descricao': '',
                    'idPainel': p.id,
                    'grupos': []
                }
            };
        });

        // inicializar os offsets dos efeitos
        this.struct.forEach(s => {
            this.prepararMensagem(s.mensagem);
            // console.log(s);
            if (+s.painel.dimension_x > this.maxSize) {
                this.maxSize = +s.painel.dimension_x;
            }
        });

        clearTimeout(this.interval);
        this.prepararIntervalos();
    }

    prepararMensagem(mensagem: model.Mensagem) {
        mensagem.grupos.forEach(async grupo => {
            if (grupo.efeito == 1) {
                grupo.offsetSimY = - (grupo.end_y - grupo.start_y - grupo.offsetY) - 1;
                grupo.endOffsetSimY = 0;
            } else if (grupo.efeito == 2) {
                grupo.offsetSimX = (grupo.end_x - grupo.start_x + 1 - grupo.offsetX);
                const pontos = await this.translate.translate(grupo, false);
                // console.log(JSON.parse(JSON.stringify(pontos)));
                if (pontos.length > 0) {
                    grupo.endOffsetSimX = - (pontos[pontos.length - 1].x - (grupo.start_x + grupo.offsetSimX)) - 1 - grupo.offsetX;
                } else {
                    grupo.endOffsetSimX = 0;
                }
            } else if (grupo.efeito == 3) {
                grupo.offsetSimY = (grupo.end_y - grupo.start_y - grupo.offsetY) + 1;
                grupo.endOffsetSimY = 0;
            }
        });
    }

    prepararIntervalos() {
        // console.log('');
        this.interval = setTimeout(() => {
            this.views.toArray().forEach(view => {
                let change = true;

                view.mensagem.grupos.forEach(grupo => {
                    if (grupo.offsetSimX != grupo.endOffsetSimX || grupo.offsetSimY != grupo.endOffsetSimY) {

                        change = false;
                    }
                    // down
                    if (grupo.efeito == 1) {
                        this.moverBaixo(grupo);
                    } else if (grupo.efeito == 2) {
                        this.moverEsquerda(grupo);
                    } else if (grupo.efeito == 3) {
                        this.moverAcima(grupo);
                    }
                });
                if (!view.waiting) {
                    view.update();
                }

                if (change && !view.waiting) {
                    view.waiting = true;
                    setTimeout(() => {
                        this.nextMessage(view.painel.id, view.mensagem);
                        view.waiting = false;
                    }, view.mensagem.tempo * 1000);
                }

            });

            this.prepararIntervalos();

        }, this.speed);
    }

    nextMessage(idPainel: number, mensagem: model.Mensagem) {
        let index = this.mensagens_por_roteiro[idPainel].indexOf(mensagem) + 1;
        if (index >= this.mensagens_por_roteiro[idPainel].length) {
            index = 0;
        }

        this.prepararMensagem(this.mensagens_por_roteiro[idPainel][index]);
        this.struct.find(s => s.painel.id == idPainel).mensagem = this.mensagens_por_roteiro[idPainel][index];
    }

    moverBaixo(grupo: model.Grupo) {
        if (grupo.offsetSimY < grupo.endOffsetSimY) {
            grupo.offsetSimY++;
        }
    }

    moverAcima(grupo: model.Grupo) {
        if (grupo.offsetSimY > grupo.endOffsetSimY) {
            grupo.offsetSimY--;
        }
    }

    moverEsquerda(grupo: model.Grupo) {
        if (grupo.offsetSimX > grupo.endOffsetSimX) {
            grupo.offsetSimX--;
        }
    }

    buscarPainel(id: number) {
        return this.paineis.find(painel => painel.id == id);
    }

    editar(grupo: model.Grupo) {
    }

    click(event: MouseEvent) {
    }

    async irEditor() {
        const projeto = await this.projetoApi.get_selected();
        const paineis = await this.painelApi.list_by_parent_id(projeto.id);
        const roteiros = await this.roteiroApi.list_by_parent_id(projeto.id);

        this.roteiroApi.select(
            roteiros
                .find((roteiro: model.Roteiro) => JSON.stringify(roteiro) == this.roteiroComp)
        );
        this.painelApi.select(paineis[0]);
        this.router.navigate(['editor']);
    }
}
