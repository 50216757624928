
<div class="container" *ngIf="painel != undefined">
    <canvas class="canvas editor-canvas elevation-2"
      [class.editor-canvas-selected]="selected"
      [attr.data-width]="painel.dimension_x"
      [attr.data-height]="painel.dimension_y"
      tabindex="1"
      (keypress)="keyPressEvent.emit($event)"
      (keydown)="keyDownEvent.emit($event)"
      #canvas></canvas>
</div>
<!--
<svg xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="svgViewbox()">
    <rect x="0" y="0" width="100%" height="100%" class="rect" />
    <ng-container *ngFor="let i of x; let idx = index; trackBy: trackFn">
        <circle *ngFor="let j of y; let idy = index; trackBy: trackFn" class="point" [class.filled]="inPoints(i, j)" [attr.cx]="cxs[idx]" [attr.cy]="cys[idy]" r="0.3"/>
    </ng-container>
</svg>
-->
