<div class="card elevation-hover-1" *ngIf="projetos.length > 0">
    <div class="table table-header">
        <div class="row header">
            <div class="cell">
                <span class="cell-header" i18n>
                    PROJETOS
                </span>
                <span [attr.tooltip]="tooltips.simular">
                    <i class="icon size-2 clickable icon-color-white" routerLink="/projetos/{{projetosApi.get_selected()?.id}}/simulador" *ngIf="projetosApi.get_selected() != undefined">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-play.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span [attr.tooltip]="tooltips.duplicar">
                    <i class="icon size-2 clickable icon-color-white" (click)="duplicar(projetosApi.get_selected())" *ngIf="projetosApi.get_selected() != undefined">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-duplicate.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span [attr.tooltip]="tooltips.editar">
                    <i id="project-edit-page-link" class="icon size-2 clickable icon-color-white" routerLink="/projetos/editar/{{projetosApi.get_selected()?.id}}" *ngIf="projetosApi.get_selected() != undefined">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-edit.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span [attr.tooltip]="tooltips.exportar" *ngIf="projetosApi.get_selected() != undefined">
                    <i class="icon size-2 clickable icon-color-white" (click)="exportarProjeto()">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-export.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span [attr.tooltip]="tooltips.importar">
                    <i class="icon size-2 clickable icon-color-white" routerLink="/projetos/importar/">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-import.svg#icon"></use>
                        </svg>
                    </i>
                </span>
                <span [attr.tooltip]="tooltips.novo">
                    <i class="icon size-2 clickable icon-color-white" routerLink="/projetos/cadastrar/">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-add.svg#icon"></use>
                        </svg>
                    </i>
                </span>
            </div>
        </div>
    </div>

    <div class="table">
        <ng-container *ngFor="let projeto of projetos let i = index">
            <button class="row"
                [class.selected]="projetosApi.is_selected(projeto)"
                (click)="projetosApi.select(projeto);">
                <div class="cell flex-size-1">
                    <div class="checkbox"
                        [class.selected]="projetosApi.is_selected(projeto)"
                    ></div>
                </div>
                <div class="cell flex-size-1">
                    {{projeto.encryptedId}}
                </div>
                <div class="cell flex-size-3">
                    {{projeto.nome}}
                </div>
                <div class="cell flex-size-2">
                    {{projeto.descricao}}
                </div>
                <div class="cell flex-size-2">
                    {{projeto.data_alteracao.date | date : 'dd/MM/yy'}}
                </div>
            </button>
        </ng-container>

    </div>
    <!-- <pre>
        {{ projetosApi.projetos | json }}
    </pre> -->
</div>
<div class="alert warning" *ngIf="projetos.length == 0" i18n>
    Você não possui nenhum projeto cadastrado. <a routerLink="/projetos/cadastrar">Clique aqui para cadastrar um</a>.
</div>
