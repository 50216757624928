import { trigger, transition, style, animate, query, stagger, state, sequence } from '@angular/animations';

const enter = [
    style({transform: 'translateY(-50%)', opacity: '0'}),
    animate('.15s', style({transform: 'translateY(0)', opacity: '1'}))
];

const leave = [
    animate('.1s', style({transform: 'translateY(-50%)', opacity: '0'}))
];

export const fadeDown = trigger('fadeDown', [
    // transition('* => *', [
    //     query(':leave', leave, { optional: true }),
    //     query(':enter', enter, { optional: true })
    // ])
    transition('void => *', [
        query(':self', enter)
    ]),
    transition('* => void', [
        query(':self', leave)
    ])
]);
