import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ShortcutsService {

    public shortcuts = [
        {'alt': true,     'shift': true,     'key': '+',             'func': 'aumentarEspacamento',     'desc': $localize `Aumentar espaçamento`,                    'alias': '+'},
        {'alt': true,     'shift': true,     'key': '_',             'func': 'diminuirEspacamento',     'desc': $localize `Diminuir espaçamento`,                    'alias': '_'},
        {'alt': true,     'shift': true,     'key': 'Q',             'func': 'toggleInvertido',         'desc': $localize `Inverter LEDs`,                         'alias': 'Q'},
        {'alt': true,     'shift': true,     'key': 'A',             'func': 'toggleAutoSize',         'desc': $localize `Habilitar/Desabilitar Auto Size`,         'alias': 'A'},
        {'alt': true,     'shift': true,     'key': 'R',             'func': 'replicarGrupo',         'desc': $localize `Replicar grupo para outras mensagens`,    'alias': 'R'},
        {'alt': false,     'shift': false, 'key': 'Delete',         'func': 'excluirGrupo',         'desc': $localize `Excluir grupo`,                            'alias': 'Del'},
        {'alt': false,     'shift': false, 'key': 'ArrowUp',         'func': 'moverCima',             'desc': $localize `Mover para cima`,                         'alias': '⭡'},
        {'alt': false,     'shift': false, 'key': 'ArrowRight',     'func': 'moverDireita',         'desc': $localize `Mover para direita`,                     'alias': '⭢'},
        {'alt': false,     'shift': false, 'key': 'ArrowDown',     'func': 'moverBaixo',             'desc': $localize `Mover para baixo`,                         'alias': '⭣'},
        {'alt': false,     'shift': false, 'key': 'ArrowLeft',     'func': 'moverEsquerda',         'desc': $localize `Mover para esquerda`,                    'alias': '⭠'},
        {'alt': true,     'shift': true,     'key': 'ArrowUp',         'func': 'alinharCima',             'desc': $localize `Alinhar acima`,                            'alias': '⭡'},
        {'alt': true,     'shift': true,     'key': 'ArrowRight',     'func': 'alinharDireita',         'desc': $localize `Alinhar a direita`,                        'alias': '⭢'},
        {'alt': true,     'shift': true,     'key': 'ArrowDown',     'func': 'alinharBaixo',         'desc': $localize `Alinhar abaixo`,                        'alias': '⭣'},
        {'alt': true,     'shift': true,     'key': 'ArrowLeft',     'func': 'alinharEsquerda',         'desc': $localize `Alinhar a esquerda`,                     'alias': '⭠'},
        {'alt': true,     'shift': true,     'key': 'V',             'func': 'justificarVertical',     'desc': $localize `Centralizar verticalmente`,                'alias': 'V'},
        {'alt': true,     'shift': true,     'key': 'H',             'func': 'justificarHorizontal', 'desc': $localize `Centralizar horizontalmente`,             'alias': 'H'},
        {'alt': false,     'shift': true,     'key': 'ArrowUp',         'func': 'diminuirAltura',         'desc': $localize `Diminuir altura`,                        'alias': '⭡'},
        {'alt': false,     'shift': true,     'key': 'ArrowRight',     'func': 'aumentarGrupo',         'desc': $localize `Aumentar largura`,                        'alias': '⭢'},
        {'alt': false,     'shift': true,     'key': 'ArrowDown',     'func': 'aumentarAltura',         'desc': $localize `Aumentar altura`,                        'alias': '⭣'},
        {'alt': false,     'shift': true,     'key': 'ArrowLeft',     'func': 'diminuirGrupo',         'desc': $localize `Diminiur largura`,                        'alias': '⭠'},
    ];

}
