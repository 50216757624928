<h1 i18n>Contatos no site</h1>
<div class="card" *ngFor="let contato of contatos">
    <div class="header">
        <span class="title" i18n>Realizado por {{contato.nome}} no dia {{contato.data | date:'dd/MM/yyyy HH:mm:ss'}}</span>
        <span class="button-group">
            <button class="btn btn-danger" type="button" name="button" (click)="excluir(contato)" i18n>Excluir</button>
        </span>
    </div>
    <div class="conteudo" [class.overflow-grad]="passouDoTamanho(cont)" #cont collapsed="false">
        {{contato.descricao}}
    </div>
    <button class="btn btn-more" type="button" name="button" (click)="toggle(cont)" *ngIf="passouDoTamanho(cont)" i18n>Mostrar tudo</button>
</div>
<div class="alert warning" *ngIf="contatos.length == 0 && isReady" i18n>
    Não há nenhum contato a ser exibido
</div>
