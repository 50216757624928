import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-help-roteiros',
    templateUrl: './help-roteiros.component.html',
    styleUrls: ['./help-roteiros.component.scss']
})
export class HelpRoteirosComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
