import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Title } from '@angular/platform-browser';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-tecnico-cadastrar',
    templateUrl: './tecnico-cadastrar.component.html',
    styleUrls: ['./tecnico-cadastrar.component.scss']
})
export class TecnicoCadastrarComponent implements OnInit {

    public tecnico: model.Usuario;

    constructor(
        private usuarioApi: UsuarioApiService,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title,
    ) {
        this.tecnico = {
            nome: '',
            email: '',
            telefone: '',
            senha: '',
            tecnico: true,
        };
        this.help.local = 'empresa-tecnico';
    }

    ngOnInit() {
        this.titleService.setTitle($localize `Cadastro de técnico - Thoreb Itinerário`);
    }

    public async salvar(tecnico: model.Usuario) {
        this.loader.startLoading();

        try {
            await this.usuarioApi.salvarUsuario(tecnico);
            this.router.navigate(['tecnicos']);
        } finally {
            this.loader.stopLoading();
        }
    }

}
