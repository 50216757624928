import { Injectable } from '@angular/core';
import { BackendMessagesService } from '@app/shared/API_I18N.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class VersionCheck {
    public versionString: string;

    public constructor(
        private toastr: ToastrService,
        private backend_messages: BackendMessagesService,
    ) { }

    public update(newVersion: string) {
        if (this.versionString && newVersion && this.versionString != newVersion) {
            console.log("VersionCheck: differing versions, reloading", this.versionString, newVersion);
            return this.force_reload();
        }

        if (!this.versionString) {
            console.log("VersionCheck: setting version to ", newVersion);
            this.versionString = newVersion;
        }
    }

    public force_reload() {
        this.info_toast();
        setTimeout(() => {
            window.location.replace(
                window.location.pathname +
                window.location.search +
                window.location.hash
            );
        }, 1000);
    }

    private info_toast() {
        this.toastr.info("", this.backend_messages.messages.OUTDATED_CLIENT_CODE);
    }
}
