import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, HostListener } from '@angular/core';

import { EditorViewComponent } from './editor-view/editor-view.component';
import { EditorFormFloatingComponent } from './editor-form-floating/editor-form-floating.component';

import { PositionCalculator } from '../position-calculator';
import { FontCalculator } from '../font-calculator';
import { GrupoService } from '../grupo.service';

import { ToastrService } from 'ngx-toastr';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-editor-mensagem',
    templateUrl: './editor-mensagem.component.html',
    styleUrls: ['./editor-mensagem.component.scss']
})
export class EditorMensagemComponent implements OnInit, OnDestroy {

    @Input() mensagem: model.Mensagem;
    @Input() painel: model.Painel;
    @Input() i: number;

    @Output() duplicar$ = new EventEmitter<model.Mensagem>();
    @Output() excluir$ = new EventEmitter<model.Mensagem>();
    @Output() replicar$ = new EventEmitter<model.Grupo>();

    @ViewChild('view') private view: EditorViewComponent;
    @ViewChild('form') private floatingForm: EditorFormFloatingComponent;

    private editandoMsg = false;
    public adicionando = false;

    public tooltips = {
        'editar': $localize `Tempo`,
        'duplicar': $localize `Duplicar mensagem`,
        'excluir': $localize `Excluir mensagem`,
        'adicionar': $localize `Adicionar conteúdo ao painel`,
    };

    constructor(
        private calculator: PositionCalculator,
        private fontCalculator: FontCalculator,
        public grupoService: GrupoService,
        private toastr: ToastrService,
    ) { }

    ngOnInit() {
    }

    ngOnDestroy() {
        document.body.style.cursor = 'default';
    }

    keyPressEvent(event: KeyboardEvent) {
        if (this.mensagem.grupos.indexOf(this.grupoService.grupo) != -1) {
            return this.floatingForm.triggerKeyPress(event);
        }
    }

    keyDownEvent(event: KeyboardEvent) {
        if (this.mensagem.grupos.indexOf(this.grupoService.grupo) != -1) {
            return this.floatingForm.triggerKeyDown(event);
        }
    }

    pasteEvent(event: ClipboardEvent) {
        if (this.mensagem.grupos.indexOf(this.grupoService.grupo) == -1) {
            return console.warn("Trying to paste with no group selected");
        }

        let clipboardData = event.clipboardData;
        let pastedData = clipboardData.getData('Text');
        console.log(`Clipboard content "${pastedData}"`);

        return this.floatingForm.appendText(pastedData);
    }

    clickListener(event: MouseEvent) {
        if (!this.adicionando) {
            this.view.registerClick(event);
        } else {
            document.body.style.cursor = 'default';
            this.adicionando = false;
            this.adicionarGrupo(this.view.clickNew(event));
        }
    }

    editando() {
        return this.editandoMsg;
    }

    toggleEditar() {
        this.editandoMsg = !this.editandoMsg;
    }

    duplicar() {
        this.duplicar$.emit(this.mensagem);
    }

    excluir() {
        this.excluir$.emit(this.mensagem);
    }

    render(args: {focusEditor?: boolean} = {}) {
        this.view.update();
        this.view.updateFlicker();

        if (args.focusEditor !== false) {
            this.view.focusEditor();
        }
    }

    aumentarTempo() {
        if (this.mensagem.tempo < 10) {
            this.mensagem.tempo++;
        }
    }

    diminuirTempo() {
        if (this.mensagem.tempo > 0) {
            this.mensagem.tempo--;
        }
    }

    aumentarOrdenacao() {
        if (this.mensagem.ordem < 127) {
            this.mensagem.ordem++;
        }
    }

    diminuirOrdenacao() {
        if (this.mensagem.ordem > -128) {
            this.mensagem.ordem--;
        }
    }

    // funções dos grupos

    editarGrupo(grupo: model.Grupo) {
        this.mandarParaEdicao(grupo);
    }

    mandarParaEdicao(grupo: model.Grupo) {
        // this.view.stopFlicker();

        this.grupoService.grupo = grupo;
    }

    tirarDaEdicao() {
        this.view.stopFlicker();

        this.grupoService.grupo = undefined;
    }

    excluirGrupo(grupo: model.Grupo) {
        if (this.mensagem.grupos.indexOf(grupo) !== -1) {
            this.mensagem.grupos.splice(this.mensagem.grupos.indexOf(grupo), 1);
            this.tirarDaEdicao();
            this.render();
            this.view.stopFlicker();
        }
    }

    novoGrupo() {
        if (!this.adicionando) {
            const toast = this.toastr.info($localize `Clique no local que deseja inserir o grupo`);
            this.adicionando = true;
            document.body.style.cursor = 'pointer';
        }
    }

    private async adicionarGrupo(posicaoInicial: number[]) {
        const boxes = this.calculator.calcBoxes(this.mensagem.grupos);

        if (!this.calculator.estaLivre(boxes, posicaoInicial[0], posicaoInicial[1])) {
            return;
        }

        const posicao = this.calculator.encontrarPosicaoVazia(this.mensagem, this.painel.dimension_x, this.painel.dimension_y, posicaoInicial);
        let final = posicao[1];
        for (let j = +final; j < +this.painel.dimension_y; j++) {
            if (this.calculator.estaLivre(boxes, posicao[0], j)) {
                final = j;
            }
        }

        if (posicao !== false && this.mensagem.grupos.length < 30) {
            const fonte = await this.fontCalculator.calcularMelhorFonte(final - posicao[1] + 1);
            const grupo = {
                'content': '',
                'spacing': 1,
                'useColors': this.painel.rgb,
                'cor': '#f2d21f',
                'invertido': false,
                'autoSize': true,
                'start_x': posicao[0],
                'start_y': posicao[1],
                'end_x': posicao[0],
                'end_y': final,
                'offsetX': 0,
                'offsetY': 0,
                'efeito': 0,
                'origem': 1,
                'idOrigem': fonte.id
            };

            this.mensagem.grupos.push(grupo);
            this.mandarParaEdicao(grupo);
            this.render();

            this.view.flicker({
                'grupo': grupo,
                'start_x': grupo.start_x,
                'start_y': grupo.start_y,
                'end_x': grupo.end_x,
                'end_y': grupo.end_y
            });
        } else {
            alert($localize `Esta mensagem está cheia!`);
        }
    }


    replicar(grupo: model.Grupo) {
        this.replicar$.emit(grupo);
    }
}
