import { Component, OnInit, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { RecursosApiService } from '@app/services/recursos-api.service';
import { HelpService } from '@app/services/help.service';
import * as model from '@app/projeto.model';


@Component({
    selector: 'app-recursos-page',
    templateUrl: './recursos-page.component.html',
    styleUrls: ['./recursos-page.component.scss']
})
export class RecursosPageComponent implements OnInit {
    @ViewChildren('canvas') private canvases: QueryList<ElementRef>;

    recursos: model.Recurso[];

    constructor(
        public recursosApi: RecursosApiService,
        private router: Router,
        private titleService: Title,
        private help: HelpService,
    ) {
        this.titleService.setTitle($localize `Recursos - Thoreb Itinerário`);
        this.help.local = 'recursos';
    }

    async ngOnInit() {
        this.recursos = await this.recursosApi.list_all();

        if (!this.canvases)
            console.error("no valid canvas");

        console.log("trying to draw canvas");
        this.canvases.forEach(canvas => {
            console.log("drawing canvas");
            this.draw(canvas);
        });

        this.canvases.changes.subscribe(() => {
            this.canvases.forEach(canvas => {
                this.draw(canvas);
            });
        });
    }

    private draw(canvas) {
        const index = canvas.nativeElement.attributes.i.value;

        const cx = canvas.nativeElement.getContext('2d');

        const recurso = this.recursos[index];
        if (!recurso || !recurso.largura || !recurso.tamanho)
            console.error("Recurso inválido", recurso);

        cx.canvas.width = recurso.largura * 10 + 8;
        cx.canvas.height = recurso.tamanho * 10 + 8;

        cx.clearRect(0, 0, cx.canvas.width, cx.canvas.height); // limpar o canvas

        recurso.pontos.forEach(ponto => {
            if (!ponto.x || !ponto.y)
                console.error("Ponto com formato inválido", ponto);

            cx.beginPath();
            cx.arc((+ponto.x * 10 + 4), (+ponto.y * 10 + 4), 4, 0, 2 * Math.PI, false);
            cx.fillStyle = '#f2d21f';
            if(recurso.rgb && ponto.color) {
                cx.fillStyle = ponto.color;
            }
            cx.fill();
        });
    }

    async excluirRecurso(recurso: model.Recurso, event: MouseEvent) {
        const botao: HTMLButtonElement = event.target as HTMLButtonElement;
        botao.disabled = true
        if (confirm($localize `Você tem certeza que deseja excluir esse recurso?`)) {
            await this.recursosApi.delete(recurso);
            this.recursos = await this.recursosApi.list_all();
        }
        botao.disabled = false;
    }

    navigate(id: number) {
        console.log('id: ' + id);
        this.router.navigate(['recursos', id, 'editar']);
    }
}
