<form #projetoForm="ngForm">
    <div class="form-section">
        <label class="label" for="nome" i18n>Nome</label>
        <input id="projeto-nome" type="text" name="nome" [(ngModel)]="projeto.nome" required maxlength="255" #nome="ngModel" [class.danger]="!nome.valid && !nome.pristine">
        <div class="alert danger" *ngIf="!nome.valid && !nome.pristine">
            <div *ngIf="nome.errors.required" i18n>Campo obrigatório</div>
            <div *ngIf="nome.errors.maxlength" i18n>Máximo de 255 caracteres</div>
        </div>
    </div>
    <div class="form-section">
        <label class="label" for="descricao" i18n>Descrição</label>
        <input id="projeto-descricao" type="text" name="descricao" [(ngModel)]="projeto.descricao" maxlength="255" #descricao="ngModel" [class.danger]="!descricao.valid && !descricao.pristine">
        <small i18n>Exemplos de uso: Ano 2018, Modelo 1, Modelo 2, Dois andares</small>
        <div class="alert danger" *ngIf="!descricao.valid && !descricao.pristine">
            <div *ngIf="descricao.errors.maxlength" i18n>Máximo de 255 caracteres</div>
        </div>
    </div>

    <div class="form-section">
        <p class="label" for="descricao" i18n>Tarifas</p>
        <div class="checkbox-label">
            <input id="projeto-habilitar-tarifas" type="checkbox" name="habilitar_tarifas" [(ngModel)]="projeto.habilitar_tarifas" #habilitar_tarifas="ngModel">
            <label for="projeto-habilitar-tarifas" i18n>Habilitar tarifas no projeto</label>
        </div>
        <small i18n>Define se os roteiros poderão cadastrar o valor das tarifas</small>
    </div>

    <h3>
        <ng-container i18n>
            Paineis
        </ng-container>
        <i class="size-4 icon icon-color-white">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-painel.svg#icon"></use>
            </svg>
        </i>
    </h3>

    <div class="form-section" *ngIf="paineis.length > 0">
        <ng-container *ngFor="let painel of paineis; let i = index; trackBy: track">
            <div class="form-section-painel form-section-inline bordered">
                <app-projetos-painel-form [i]="i" [painel]="painel" [outrosPaineis]="paineis"></app-projetos-painel-form>
                <button class="btn btn-danger" type="button" name="button" (click)="excluir_painel(painel)" i18n>Excluir Painel</button>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="paineis.length == 0">
        <div class="alert warning" i18n>
            Você não possui nenhum painel cadastrado!
        </div>
    </ng-container>
    <div class="form-section">
        <button id="painel-add-button" class="btn btn-primary" type="button" name="button" (click)="novoPainel()" [disabled]="paineis.length >= 50" i18n>Novo painel</button>
    </div>

    <button id="salvar-projeto" class="btn btn-success" type="submit" name="button" (click)="submit()" [disabled]="!projetoForm.form.valid" i18n>Salvar</button>
</form>
