import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Title } from '@angular/platform-browser';


import * as model from '@app/projeto.model';

@Component({
    selector: 'app-tecnico-editar',
    templateUrl: './tecnico-editar.component.html',
    styleUrls: ['./tecnico-editar.component.scss']
})
export class TecnicoEditarComponent implements OnInit {

    public tecnico: model.Usuario;

    constructor(
        private usuarioApi: UsuarioApiService,
        private route: ActivatedRoute,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title
    ) { }

    async ngOnInit() {
        this.titleService.setTitle($localize `Editar usuário - Thoreb Itinerário`);
        this.help.local = 'empresa-usuario';

        this.loader.startLoading();
        try {
            const id = +this.route.snapshot.paramMap.get('id');
            this.tecnico = await this.usuarioApi.buscarUsuario(id);
        } catch(e) {
            this.router.navigateByUrl('/tecnicos');
            throw e;
        } finally {
            this.loader.stopLoading();
        }
    }

    public async salvar(tecnico: model.Usuario) {
        this.loader.startLoading();
        try {
            await this.usuarioApi.salvarUsuario(tecnico)
            this.router.navigate(['tecnicos']);
        } finally {
            this.loader.stopLoading();
        }
    }

}
