import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loadCount = 0;

    constructor() { }

    isLoading() {
        return this.loadCount > 0;
    }

    startLoading() {
        this.loadCount++;
    }

    stopLoading() {
        if (this.loadCount > 0) {
            this.loadCount--;
        }
    }

    clearLoading() {
        this.loadCount = 0;
    }
}
