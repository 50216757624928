<form>
<button class="btn btn-success" type="submit" name="button" (click)="salvar()" i18n>Salvar</button>

<h2 i18n>Informações da fonte</h2>

<div class="font-details-container">
    <div class="form-section">
        <label for="nome-fonte" i18n>Nome da fonte</label>
        <input id="nome-fonte" type="text" name="nome" [(ngModel)]="fonte.nome">
    </div>
    <div class="form-section">
        <label for="altura-fonte" i18n>Altura</label>
        <input id="altura-fonte"type="number" name="altura" [(ngModel)]="fonte.tamanho">
    </div>
    <div class="form-section">
        <label for="largura-fonte" i18n>Largura</label>
        <input id="largura-fonte" type="number" name="largura" [(ngModel)]="fonte.largura">
    </div>
</div>


<h2 i18n>Caracteres da fonte</h2>

<div class="filter-container form-section">
    <label for="filtro-letra">Filtro: </label>
    <input type="text" name="filtro-letra" [(ngModel)]="filter_text">
</div>

<ul class="letra-container">
    <ng-container *ngFor="let letra of fonte.letras; let i = index">
        <li *ngIf="is_letra_visivel(letra)">
            <app-letra [letra]="letra" [fonte]="fonte" [canvasX]="fonte.largura" 
                [canvasY]="fonte.tamanho" [i]="i" (excluir$)="excluirCaractere(i)" 
                (duplicar$)="duplicarCaractere(letra)">
            </app-letra>
        </li>
    </ng-container>
</ul>

<div *ngIf="letras_excluidas.length > 0" class="letras-deletadas">
    <h2 i18n>Caracteres Para Excluir</h2>
    <ul class="letra-container">
        <ng-container *ngFor="let letra of letras_excluidas; let i = index">
            <li *ngIf="is_letra_visivel(letra)">
                <app-letra-deletada [letra]="letra" [canvasX]="fonte.largura" [canvasY]="fonte.tamanho" [i]="i"
                    (restaurar$)="RestaurarCaractere(i)"></app-letra-deletada>
            </li>
        </ng-container>
    </ul>
</div>

<button id="adicionar-caractere" class="btn btn-primary" type="button" name="button" [disabled]="salvando" (click)="adicionarCaractere()" i18n>Adicionar caractere</button>
<button id="salvar-fonte" class="btn btn-success" type="submit" name="button" [disabled]="salvando || isFontValid()" (click)="salvar()" i18n>Salvar</button>
</form>
