<!-- <button type="button" name="button" (click)="exibirModal()">Exibir</button> -->
<div class="overlay" [class.visible]="help.isVisible" (click)="fechar()">
    <!-- <div class="modal" (click)="$event.stopPropagation()"> -->
        <app-help-landing (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="landing()"></app-help-landing>
        <app-help-home (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="home()"></app-help-home>
        <app-help-editor (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="editor()"></app-help-editor>
        <app-help-projetos (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="projetos()"></app-help-projetos>
        <app-help-roteiros (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="roteiros()"></app-help-roteiros>
        <app-help-simulador (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="simulador()"></app-help-simulador>
        <app-help-empresa (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="empresa()"></app-help-empresa>
        <app-help-empresa-usuario (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="empresaUsuario()"></app-help-empresa-usuario>
        <app-help-recursos (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="recursos()"></app-help-recursos>
        <app-help-fontes (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="fontes()"></app-help-fontes>
        <app-help-login (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="login()"></app-help-login>
        <app-help-tutorial (fechar)="fechar()" class="modal elevation-3" (click)="$event.stopPropagation()" *ngIf="tutorial()"></app-help-tutorial>
    <!-- </div> -->
</div>
