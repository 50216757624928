import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Alteravel } from '@app/alteravel';
import * as model from '@app/projeto.model';

import { ToastsService } from '@app/toasts.service';
import { PainelApiService } from '@app/services/painel-api.service';

@Component({
    selector: 'app-projetos-editar',
    templateUrl: './projetos-editar.component.html',
    styleUrls: ['./projetos-editar.component.scss']
})
export class ProjetosEditarComponent implements Alteravel, OnInit {

    public get alteracao() {
        if (this.projeto != undefined && JSON.stringify(this.projeto) != JSON.stringify(this.oldProjeto)) {
            return true;
        }
        return false;
    }
    public projeto: model.Projeto;
    private oldProjeto: model.Projeto;

    public paineis: model.Painel[];
    public roteiros: model.Roteiro[];
    public loading = false;

    constructor(
        private route: ActivatedRoute,
        private projetosApi: ProjetoApiService,
        private paineisApi: PainelApiService,
        private router: Router,
        private loader: LoaderService,
        private toasts: ToastsService,
        private help: HelpService,
        private titleService: Title,
    ) {
        this.paineis = [];
    }

    async ngOnInit() {
        this.loader.startLoading();

        const id = +this.route.snapshot.paramMap.get('id');
        await this.projetosApi.list_all()
        this.loader.stopLoading();

        const p = await this.projetosApi.get_by_id(id);
        if (p) {
            this.projeto = JSON.parse(JSON.stringify(p));
            this.oldProjeto = JSON.parse(JSON.stringify(this.projeto));

            this.paineis = await this.paineisApi.list_by_parent_id(this.projeto.id);
            // this.roteiros = await this.roteirosApi.list_by_parent_id(this.projeto.id);
        } else {
            this.router.navigateByUrl('/home');
        }

        this.help.local = 'projetos';
        this.titleService.setTitle($localize `Editar projeto - Thoreb Itinerário`);
    }

    async salvar({ projeto, paineis, paineis_para_excluir }: { projeto: model.Projeto, paineis: model.Painel[], paineis_para_excluir: model.Painel[] }) {
        try {
            this.loader.startLoading();

            const proj = await this.projetosApi.save(projeto);
            this.projeto = JSON.parse(JSON.stringify(proj));
            this.oldProjeto = JSON.parse(JSON.stringify(proj));

            for (let painel of paineis_para_excluir) {
                await this.paineisApi.delete(painel);
            }
            for (let painel of paineis) {
                painel.parent_id = proj.id;
                await this.paineisApi.save(painel);
            }

            this.projetosApi.clear_caches();
            this.paineisApi.clear_caches();

            this.projetosApi.select(proj);
            this.router.navigate(['projetos', proj.id, 'roteiros']);
        } catch (e) {
            console.error(e);
            this.toasts.api_error("Falha ao salvar o projeto");
        } finally {
            this.loader.stopLoading();
        }
    }

    async excluir() {
        if (window.confirm($localize `Você tem certeza que quer excluir este projeto? Não é possível desfazer esta ação!`)) {
            this.loader.startLoading();
            const resultado = await this.projetosApi.delete(this.projeto)
            this.loader.stopLoading();
            if (resultado) {
                this.router.navigateByUrl('/home');
            }
        }
    }
}
