import { trigger, transition, style, animate, query, stagger, state, sequence, animateChild, group } from '@angular/animations';

export const swipeLeftIn = [
    style({ opacity: 0, transform: 'translateX(50%)' }),
    group([
        animate('.15s', style({ transform: 'translateX(0)' })),
        animate('.1s', style({ opacity: 1 }))
    ])
];

export const swipeLeftOut = [
    style({ opacity: 1}),
    group([
        animate('.15s', style({ opacity: 0 }))
    ])
];

export const swipeRightIn = [
    style({ opacity: 0, transform: 'translateX(-50%)' }),
    group([
        animate('.15s', style({ transform: 'translateX(0)' })),
        animate('.1s', style({ opacity: 1 }))
    ])
];

export const swipeRightOut = [
    style({ opacity: 1 }),
    group([
        animate('.15s', style({ opacity: 0 }))
    ])
];

export const swipeDownIn = [
    style({ opacity: 0, transform: 'translateY(-50%)' }),
    group([
        animate('.15s', style({ transform: 'translateY(0)' })),
        animate('.1s', style({ opacity: 1 }))
    ])
];

export const swipeDownOut = [
    style({ opacity: 1 }),
    group([
        animate('.15s', style({ opacity: 0 }))
    ])
];

export const swipeUpIn = [
    style({ opacity: 0, transform: 'translateY(50%)' }),
    group([
        animate('.15s', style({ transform: 'translateY(0)' })),
        animate('.1s', style({ opacity: 1 }))
    ])
];

export const swipeUpOut = [
    style({ opacity: 1 }),
    group([
        animate('.15s', style({ opacity: 0 }))
    ])
];
