<div class="container">
    <app-nav></app-nav>

    <app-help-modal></app-help-modal>

    <div class="body">
        <app-menu-aside></app-menu-aside>

        <section class="body-content" [@routeAnim]="routeName(o)">
            <!-- {{routeName(o)}} -->
            <router-outlet #o="outlet" style="display: none;"></router-outlet>
        </section>
        <button type="button" name="button" class="fab" routerLink="/contato" *ngIf="routeName(o) != 'contato' && routeName(o) != 'login'">
            <i class="size-1 icon icon-color-white">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-feedback.svg#icon"></use>
                </svg>
            </i>
            <span>
                Feedback
            </span>
        </button>
    </div>
</div>

<div class="loader" [class.loader-visible]="loader.isLoading()">
    <div class="loader-content-wrapper">
        <div class="loader-content">
            <!-- Carregando... -->
            <!-- <button class="btn btn-success" type="button" name="button" (click)="loader.stopLoading()">Dispensar</button> -->
            <i class="icon icon-loader icon-loader-1">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/semi-circle.svg#icon"></use>
                </svg>
            </i>
            <i class="icon icon-loader icon-loader-2">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/semi-circle.svg#icon"></use>
                </svg>
            </i>
            <i class="icon icon-loader icon-loader-3">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/semi-circle.svg#icon"></use>
                </svg>
            </i>
        </div>
    </div>
</div>
