import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-empresa-usuario',
  templateUrl: './help-empresa-usuario.component.html',
  styleUrls: ['./help-empresa-usuario.component.scss']
})
export class HelpEmpresaUsuarioComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
