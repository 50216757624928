import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Action } from '@app/action';

export class Action {
    'label': string;
    'name': string;
    'icon': string;
    'context': any;
    'func': string;
    'params': any[];

    constructor(label: string, name: string, icon: string, context: any, func: string, params: any[]) {
        this.label = label;
        this.name = name;
        this.icon = icon;
        this.context = context;
        this.params = params;
        this.func = func;
    }

    trigger(): any {
        return this.context[this.func](...this.params);
    }
}


@Injectable({
    providedIn: 'root'
})
export class ContextOptionsService {

    public actions: Action[] = [];

    constructor(public router: Router) {
        // this.actions = [];
    }

    limparPorLabel(label: string) {
        this.actions = this.actions.filter((el) => el.label !== label);
    }


}
