import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as model from '@app/projeto.model';

@Injectable({
    providedIn: 'root'
})
export class GrupoService {

    public grupo$: BehaviorSubject<model.Grupo>;
    private _grupo: model.Grupo;

    set grupo(grupo: model.Grupo) {
        this._grupo = grupo;
        this.grupo$.next(grupo);
    }

    get grupo() {
        return this._grupo;
    }

    constructor() {
        this.grupo$ = new BehaviorSubject(null);
    }
}
