<div class="modal-header" i18n>
    AJUDA DO SIMULADOR
</div>
<div class="modal-content">
    <p i18n><strong>Nesta página, você pode validar os roteiros que cadastrou, tendo um feedback gráfico, com uma prévia dos efeitos e dos grupos cadastrados.</strong></p>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-simulador.png" alt="Seleção de roteiro" i18n-alt>
        <span>
            <p i18n>
                Esta página funciona de forma semelhante à listagem de paineis, roteiros e projetos. Uma vez que selecione um projeto e clique em "simulador", você será
                redirecionado para esta tela. Você pode selecionar um roteiro do projeto que deseja visualizar, e, ao selecionar, as mensagens serão exibidas em um painel Itinerário
                emulado por software nesta tela.
            </p>
            <p i18n>
                Utilize esta ferramenta para conferir se todos os detalhes dos roteiros cadastrados estão corretos, como os <strong>tempos das mensagens</strong>, os
                <strong>efeitos</strong> e as <strong>trocas entre as mensagens</strong>.
            </p>
        </span>
    </div>
</div>
<div class="modal-footer">
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
