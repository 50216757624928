<!-- <button type="button" name="button" (click)="exibirModal()">Exibir</button> -->
<div class="overlay visible" (click)="cancelar()">
    <div class="modal elevation-3" (click)="$event.stopPropagation()">
        <div class="modal-header" i18n>
            TEMPLATES
        </div>
        <div class="modal-content">
            <p i18n>Clique para escolher o template que deseja adicionar</p>
            <ng-container *ngIf="painel != undefined">
                <div *ngFor="let tipo of templateCalculator.getDisponiveis(painel); index as i" class="bordered elevation-hover-2 clickable mb ml">
                    <h3>{{tipo.titulo}}</h3>
                    <div
                        *ngIf="tipo.editable_width"
                        class="columns-input-group">
                        <label for="template_width" i18n>
                            Colunas
                        </label>
                        <input
                            [(ngModel)]="template_widths[tipo.id]"
                            (change)="on_templates_modified()"
                            (input)="on_templates_modified()"
                            type="number"
                            mdInput
                            min="1"
                            [max]="painel.dimension_x - 1"
                            name="template_width"
                            id="template_width">
                    </div>
                    <div [id]="'template-' + tipo.id" (click)="selecionar(tipo.id)">
                        <app-editor-view
                            #view
                            *ngIf="mensagem(tipo.id) != undefined"
                            [painel]="painel"
                            [mensagem]="mensagem(tipo.id)"
                            [rects]="true"
                            (editar)="editar($event)">
                        </app-editor-view>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
            <a (click)="fechar.emit()" i18n>Fechar</a>
        </div>
    </div>
</div>
