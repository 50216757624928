<div class="row">
    <div class="item">
        <app-projetos-lista></app-projetos-lista>
    </div>
    <div id="home-destination-list" class="item" *ngIf="projetoApi.get_selected()">
        <app-roteiros-lista [projeto]="projetoApi.get_selected()"></app-roteiros-lista>
    </div>
    <div id="home-panel-list" class="item" *ngIf="projetoApi.get_selected()">
        <app-paineis-lista [projeto]="projetoApi.get_selected()"></app-paineis-lista>
    </div>
</div>
