<ng-container>
    <div class="form-section">
        <label for="nomeEmpresa" i18n>Nome da empresa</label>
        <input type="text" name="nomeEmpresa" [(ngModel)]="empresa.nome" required maxlength="50" #nomeEmpresa="ngModel" [class.danger]="!nomeEmpresa.valid && !nomeEmpresa.pristine">
        <div class="alert danger" *ngIf="!nomeEmpresa.valid && !nomeEmpresa.pristine">
            <div *ngIf="nomeEmpresa.errors.maxlength" i18n>Máximo de 50 caracteres</div>
            <div *ngIf="nomeEmpresa.errors.required" i18n>Este campo é obrigatório</div>
        </div>
    </div>
    <div class="form-section">
        <label for="cnpj" i18n>CNPJ</label>
        <input type="text" name="cnpj" [(ngModel)]="empresa.cnpj" required maxlength="18" mask="00.000.000/0000-00" cnpj-validator #cnpj="ngModel" [class.danger]="!cnpj.valid && !cnpj.pristine">
        <div class="alert danger" *ngIf="!cnpj.valid && !cnpj.pristine">
            <div *ngIf="cnpj.errors.maxlength" i18n>Máximo de 18 caracteres</div>
            <div *ngIf="cnpj.errors.required" i18n>Este campo é obrigatório</div>
            <div *ngIf="cnpj.errors.invalidCNPJ" i18n>CNPJ inválido</div>
        </div>
    </div>
</ng-container>
