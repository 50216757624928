<div class="modal-header" i18n>
    AJUDA DO PROJETO
</div>
<div class="modal-content">
    <p i18n><strong>No cadastro/edição de projetos você pode cadastrar os projetos de Itinerário da sua empresa.</strong></p>
    <div class="row">
        <span>
            <p i18n>Ao entrar nesta tela, você verá um curto formulário de cadastro (ou edição) de um projeto.</p>
            <p i18n>
                Primeiro, temos as informações do projeto, que são o <b>nome</b> e uma <b>descrição</b>. Ambos são usados para auxiliar na hora de seleção na lista de projetos.
                Sugerimos que o nome seja o tipo de ônibus e a descrição sejam informações específicas que diferenciam um de outro, como o modelo, o ano, o fabricante. No
                entanto, você pode definir como quiser.
            </p>
        </span>
        <img class="inline-img" src="assets/img/ajuda-projetos-form.png" alt="Formulário" i18n-alt>
    </div>

    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-projetos-paineis.png" alt="Paineis" i18n-alt>
        <span>
            <p i18n>
                Logo após, a página possui uma seção para cadastro dos paineis daquele ônibus. Você pode incluir um novo painel clicando no botão <em>Novo painel</em>,
                e excluir um painel existente clicando em <em>Excluir painel</em>, sendo que cada card diz respeito a um painel.
            </p>
            <p i18n>
                Acerca das informações do painel, existem 2 dropdowns que representam as dimensões do painel, colunas e linhas. Também estão presentes a localização do painel
                (esta é um informação interna, ou seja, é apenas informativa e não altera o comportamento do sistema) e o endereço CAN do Itinerário.
                Este endereço pode ser obtido através do roteiro de testes presente no Itinerário Thoreb, sendo que, em geral, são os endereços a partir do 101.
                Se os endereços da CAN forem repetidos, as mensagens que são exibidas em um painel serão exibidas no outro também.
            </p>
        </span>
    </div>
    <p i18n>
        Ao alterar o tamanho de um painel, o sistema automaticamente calculará as dimensões dos grupos para se adequar ao tamanho do novo painel. Não esqueça de
        verificar no editor para pequenos ajustes.
    </p>
</div>
<div class="modal-footer">
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
