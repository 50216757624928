import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { BackendMessagesService } from '@app/shared/API_I18N.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
        private toastr: ToastrService,
        private backend_messages: BackendMessagesService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 && !this.router.url.includes('login')) {
                console.warn("Received error 401, logging user out");
                // auto logout if 401 response returned from api
                this.auth.logout();
            }

            const error = err.error;
            console.error(error);
            switch (error.message_code) {
                case "CANNOT_DELETE_FONT_IN_USE":
                    this.character_in_use_toast(error);
                    break;
                case "CANNOT_DELETE_CHARACTER_IN_USE":
                    this.character_in_use_toast(error);
                    break;
                case "NO_ACCESS_TOKEN_PROVIDED":
                    // NO_ACCESS_TOKEN_PROVIDED significa que o usuário não fez login,
                    // isso não é um erro então não será mostrada uma notificação
                    break;
                case "OUTDATED_CLIENT_CODE":
                    // OUTDATED_CLIENT_CODE é tratado no version_check interceptor
                    break;
                default:
                    this.default_toast(error)
            }

            return throwError(err);
        }));
    }

    private default_toast(error) {
        const message_code = error.message_code;
        const title = this.backend_messages.from_code(message_code);
        if (title)
            this.toastr.error("", title);
    }

    private character_in_use_toast(error) {
        let { message_code, used_in } = error;
        let { projeto, roteiro, grupo } = used_in;

        const title = this.backend_messages.from_code(message_code);

        let msg = "";

        msg = `${msg}no projeto: ${projeto}<br>`;
        msg = `${msg}no roteiro: ${roteiro}<br>`;
        msg = `${msg}no grupo: ${grupo}`;

        this.toastr.error(msg, title, {
            closeButton: true,
            disableTimeOut: true,
            enableHtml: true,
        });
    }
}
