import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ProjetoApiService } from '@app/services/projeto-api.service';
import { ContextOptionsService, Action } from '@app/services/context-options.service';
import { GrupoService } from './grupo.service';
import { HelpService } from '@app/services/help.service';
import { Alteravel } from '@app/alteravel';
import { ShortcutsService } from '@app/shortcuts.service';

import { EditorMensagemComponent } from './editor-mensagem/editor-mensagem.component';
import { PositionCalculator } from './position-calculator';
import { TemplateCalculator } from './template-calculator';
import { ChangeCalculator } from './change-calculator';

import * as model from '@app/projeto.model';

import { RoteiroApiService } from '@app/services/roteiro-api.service';
import { PainelApiService } from '@app/services/painel-api.service';
import { MensagemApiService } from '@app/services/mensagem-api.service';
import { LoaderService } from '@app/services/loader.service';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy, Alteravel, AfterViewInit {
    public projeto: model.Projeto;
    public roteiro: model.Roteiro;
    public paineis: model.Painel[] = [];
    public painel: model.Painel;
    public mensagens: model.Mensagem[] = undefined;
    public mensagens_original_json: string = undefined;
    public mensagens_para_excluir: model.Mensagem[] = [];

    private salvando = false;

    public editando: model.Mensagem;
    public editandoGrupo: model.Grupo;

    private shouldScroll = false;
    public exibirModalTemplate = false;
    public exibirModalAtalho = false;

    @ViewChildren('editor') private editores: QueryList<EditorMensagemComponent>;
    @ViewChildren('mensagemContainer') private containers: QueryList<ElementRef>;

    constructor(
        private titleService: Title,
        public projetosApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
        public mensagemApi: MensagemApiService,
        private router: Router,
        private help: HelpService,
        private contextOptions: ContextOptionsService,
        private calculator: PositionCalculator,
        private grupoService: GrupoService,
        public templateCalculator: TemplateCalculator,
        public changeCalculator: ChangeCalculator,
        public shortcuter: ShortcutsService,
        public loader: LoaderService,
    ) { }

    async ngOnInit() {
        try {
            this.loader.startLoading();

            const painelAtivo = this.painelApi.get_selected();
            const roteiroAtivo = this.roteiroApi.get_selected();
            if (!painelAtivo || !roteiroAtivo) {
                console.error("No panel or route selected");
                return this.router.navigateByUrl('/home'); // se for vazio, volta pra lista
            }

            this.titleService.setTitle($localize`Editor - Thoreb Itinerário`);

            this.projeto = this.projetosApi.get_selected();
            this.painel = painelAtivo;
            this.roteiro = roteiroAtivo;

            this.paineis = await this.painelApi.list_by_parent_id(this.projeto.id);

            if (this.roteiroApi.get_selected() != undefined) {
                this.carregarRoteiro();
            }

            this.mensagens = await this.mensagemApi.list_by_parent_id(this.roteiro.id);
            this.ordenarMensagens();
            this.mensagens_original_json = JSON.stringify(this.mensagens);

            this.contextOptions.actions.push(
                new Action('salvar', $localize`Salvar projeto`, 'icon-save', this, 'salvar', [])
            );
            this.help.local = 'editor';
        } finally {
            this.loader.stopLoading();
        }
    }

    ngAfterViewInit() {
        this.containers.changes.subscribe(() => {
            if (this.containers && this.containers.last && this.shouldScroll) {
                this.containers.last.nativeElement.scrollIntoView({
                    'behavior': 'smooth',
                    'block': 'end'
                });
                this.shouldScroll = false;
            }
        });
    }

    ngOnDestroy() {
        this.contextOptions.limparPorLabel('salvar');
        this.painelApi.select(undefined);
    }

    public get alteracao(): boolean {
        const base = this.mensagens_original_json;
        const atual = this.mensagens;

        return base != JSON.stringify(atual);
    }

    private carregarRoteiro() {
        this.roteiro = JSON.parse(JSON.stringify(this.roteiroApi.get_selected()));
    }

    listarMensagens(painel = this.painel) {
        if (this.mensagens == undefined) return;
        return this.mensagens.filter(
            (mensagem: model.Mensagem) => mensagem.idPainel == painel.id);
    }

    async salvar() {
        if (this.salvando)
            return;
        this.salvando = true;
        try {
            this.loader.startLoading();

            for (let msg of this.mensagens_para_excluir)
                await this.mensagemApi.delete(msg);
            this.mensagens_para_excluir = [];

            for (let msg of this.mensagens)
                await this.mensagemApi.save(msg);
            this.mensagens = await this.mensagemApi.list_by_parent_id(this.roteiro.id);
            this.mensagens_original_json = JSON.stringify(this.mensagens);

            this.roteiroApi.clear_caches();

            this.carregarRoteiro();
            this.painel = this.painelApi.get_selected();
        } catch (error) {
            console.error(error);
        } finally {
            this.salvando = false;
            this.projetosApi.clear_caches();
            this.loader.stopLoading();
        }
    }

    ordenarMensagens() {
        if (this.mensagens == undefined) return;
        return this.listarMensagens()
            .sort((el1, el2) => ((+el1.ordem) - (+el2.ordem)));
    }

    novoTemplate() {
        if (this.listarMensagens().length < 11) {
            this.exibirModalTemplate = true;
        } else {
            alert($localize`O roteiro está cheio!`);
        }
    }

    desistirTemplate() {
        this.exibirModalTemplate = false;
    }

    abrirAtalhos() {
        this.exibirModalAtalho = true;
    }

    fecharAtalhos() {
        this.exibirModalAtalho = false;
    }

    novaOrdem() {
        const mensagens = this.listarMensagens();
        const ordem = mensagens.length > 0 ? Math.max(...mensagens.map((e) => +e.ordem)) + 1 : 0;
        return ordem;
    }

    adicionarDeTemplate(grupos: model.Grupo[]) {
        grupos.forEach(grupo => {
            grupo.useColors = this.painel.rgb
        });
        const newMsg = {
            'id': undefined,
            'rstr': Date.now(),
            'ordem': this.novaOrdem(),
            'tempo': 2,
            'descricao': '',
            'idPainel': this.painel.id,
            'grupos': grupos,
            'idRoteiro': this.roteiro.id,
        };

        this.grupoService.grupo = grupos[0];
        this.mensagens.push(newMsg);
        this.shouldScroll = true;
        this.exibirModalTemplate = false;
        this.ordenarMensagens();
    }

    duplicar(mensagem: model.Mensagem) {
        if (this.listarMensagens().length < 10) {
            const nova_mensagem = (({ id, grupos, ordem, rstr, ...attrs }) => attrs).apply(null, [mensagem]);
            const campos_diferentes = {
                'grupos': mensagem.grupos.map(({ id, ...attrs }) => attrs),
                'ordem': this.novaOrdem(),
                'rstr': Date.now(),
            };
            this.mensagens.push(
                Object.assign(nova_mensagem, campos_diferentes)
            );
            this.shouldScroll = true;
        } else {
            alert($localize`O roteiro está cheio!`);
        }
    }

    excluir(mensagem: model.Mensagem) {
        if (window.confirm($localize`Você tem certeza que deseja excluir esta mensagem?`)) {
            if (mensagem.grupos.indexOf(this.grupoService.grupo) > -1) {
                this.grupoService.grupo = undefined;
            }

            const index = this.mensagens.findIndex((msg: model.Mensagem) => JSON.stringify(msg) === JSON.stringify(mensagem));
            if (index !== -1) {
                this.mensagens_para_excluir.push(
                    ...this.mensagens.splice(index, 1)
                );
            }
        }
    }

    replicar(grupo: model.Grupo) {
        this.listarMensagens().forEach((msgIt: model.Mensagem) => {
            const boxes = this.calculator.calcBoxes(msgIt.grupos);

            for (let i = +grupo.start_x; i <= +grupo.end_x; i++) {
                for (let j = +grupo.start_y; j <= +grupo.end_y; j++) {
                    for (let k = 0; k < boxes.length; k++) {
                        const box = boxes[k];

                        if (i >= +box[0] && i <= box[1] && j >= box[2] && j <= box[3] && box[4].content === '' && +box[4].origem === 1) {
                            boxes.splice(k, 1);
                            msgIt.grupos.splice(msgIt.grupos.indexOf(box[4]), 1);
                            break;
                        }
                    }
                }
            }

            let livre = true;
            for (let i = +grupo.start_x; i <= +grupo.end_x; i++) {
                for (let j = +grupo.start_y; j <= +grupo.end_y; j++) {
                    if (!this.calculator.estaLivre(boxes, i, j)) {
                        livre = false;
                    }
                }
            }

            if (livre) {
                let { id, ...attrs } = grupo;
                msgIt.grupos.push({
                    ...attrs,
                    'efeito': msgIt.grupos.find((grupoIt: model.Grupo) => grupoIt.efeito == 2) == undefined ? grupo.efeito : 0,
                });
            }
        });

        this.editores.toArray().map(editor => editor.render());
    }

    chipWarning(painel: model.Painel) {
        if (this.mensagens == undefined) return;
        return this.mensagens.filter(
            (mensagem: model.Mensagem) =>
                mensagem.idPainel == painel.id
        ).length == 0 && painel != this.painel;
    }

    trocarPainel(item: model.Painel) {
        this.painelApi.select(item);
        this.painel = this.painelApi.get_selected();
    }

    async idxRoteiroSelecionado(): Promise<number> {
        let roteiros = await this.roteiroApi.list_by_parent_id(this.projeto.id);
        let roteiro_atual = this.roteiroApi.get_selected();
        let roteiro_atual_na_lista = roteiros.find(r => r.id == roteiro_atual.id);

        return roteiros.indexOf(roteiro_atual_na_lista);
    }

    async selecionarIdxRoteiro(idx: number) {
        let roteiros = await this.roteiroApi.list_by_parent_id(this.projeto.id);

        if (idx >= roteiros.length) {
            idx = 0;
        }
        if (idx < 0) {
            idx = roteiros.length - 1;
        }

        this.roteiroApi.select(roteiros[idx]);

        this.carregarRoteiro();

        this.mensagens = await this.mensagemApi.list_by_parent_id(this.roteiro.id);
        this.mensagens_original_json = JSON.stringify(this.mensagens);
    }

    async proximoRoteiro() {
        if (this.alteracao &&
            !window.confirm($localize`Você tem alterações não salvas. Deseja sair mesmo assim?`)) {
            return;
        }

        try {
            let current = await this.idxRoteiroSelecionado();
            this.selecionarIdxRoteiro(current + 1);
        } catch (error) {
            console.error("cannot load next route", error);
        }
    }

    async anteriorRoteiro() {
        if (this.alteracao &&
            !window.confirm($localize`Você tem alterações não salvas. Deseja sair mesmo assim?`)) {
            return;
        }

        try {
            let current = await this.idxRoteiroSelecionado();
            this.selecionarIdxRoteiro(current - 1);
        } catch (error) {
            console.error("cannot load previous route", error);
        }
    }

    novoRoteiro() {
        this.router.navigate(['projetos', this.projetosApi.get_selected().id, 'roteiros']);
    }

    async replicarConteudo() {
        let paineis_vazios = (await this.painelApi.list_all())
            .filter((painel: model.Painel) => painel != this.painel) // considerar apenas os outros paineis
            .filter((painel: model.Painel) => this.listarMensagens(painel).length == 0); // considerar apenas os paineis vazios

        let painel_atual_mensagens = this.listarMensagens();

        for (let painel of paineis_vazios) {
            for (let mensagem of painel_atual_mensagens) {
                let nova_mensagem = await this.changeCalculator.calculate(mensagem, this.painel, painel); // subsituir pela cópia modificada
                nova_mensagem = Object.assign(
                    (({ id, grupos, rstr, ...attrs }) => attrs).apply(null, [nova_mensagem]), // remover id rstr da mensagem
                    { 'grupos': nova_mensagem.grupos.map(({ id, ...attrs }) => attrs), 'rstr': Date.now() } // adicionar os grupos sem id
                );
                this.mensagens.push(nova_mensagem);
            }
        }
    }

    trackPainel(index: number, item: model.Painel) {
        return item.id;
    }
}
