<h1 i18n>Importação de projeto</h1>

<form #projetoForm="ngForm">
    <input type="file" class="file-input" id="file-input"
           (change)="onFileSelected($event)" #fileUpload>

    <label class="file-display" for="file-input">
        <i class="icon size-2 clickable icon-color-white btn btn-primary">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-import.svg#icon"></use>
            </svg>
        </i>
        <ng-container *ngIf="file_name; else elseBlock" i18n>
            Arquivo pronto para importar: {{ file_name }}
        </ng-container>
        <ng-template #elseBlock i18n>
            Escolha um arquivo
        </ng-template>
    </label>

    <div class="projeto" *ngIf="file_contents">
        <h1 i18n>
            Informações do Projeto
        </h1>
        <h3 class="file-info" *ngIf="file_contents.version" i18n>
            Protocolo de exportação: v{{ file_contents.version }}
        </h3>
        <ng-container *ngIf="file_contents.data">
            <h2 i18n>Projeto</h2>
            <div class="projeto-info"
                *ngIf="file_contents.data as project">
                <p *ngIf="project.nome" i18n>
                    Nome: {{ project.nome }}
                </p>
                <p *ngIf="project['descrição']" i18n>
                    Descrição: {{ project['descrição'] }}
                </p>
            </div>
            <h2 i18n>Paineis</h2>
            <ul class="paineis"
                *ngIf="file_contents.data.paineis as panels">
                <li class="painel-info" *ngFor="let panel of panels">
                    <p *ngIf="panel.nome" i18n>
                        Nome: {{ panel.nome }}
                    </p>
                    <p *ngIf="panel['endereco']" i18n>
                        Endereço: {{ panel['endereco'] }}
                    </p>
                    <p *ngIf="panel['localização']" i18n>
                        Localização: {{ panel['localização'] }}
                    </p>
                    <p *ngIf="panel['largura'] && panel['altura']" i18n>
                        Tamanho: {{ panel['largura'] }} x {{ panel['altura'] }}
                    </p>
                </li>
            </ul>
            <h2 i18n>Roteiros</h2>
            <ul class="roteiros"
                *ngIf="file_contents.data.roteiros as routes">
                <li class="roteiro-info" *ngFor="let route of routes">
                    <p *ngIf="route.nome" i18n>
                        Nome: {{ route.nome }}
                    </p>
                    <p *ngIf="route['codigo']" i18n>
                        Codigo: {{ route['codigo'] }}
                    </p>
                    <p i18n>
                        Mensagens: {{ route['mensagens'] && route['mensagens'].length || 0 }}
                    </p>
                </li>
            </ul>

            <ng-container *ngIf="file_contents.data.fontes && file_contents.data.fontes.length > 0">
                <h2 i18n>Fontes</h2>
                <ul class="fontes"
                    *ngIf="file_contents.data.fontes as fonts">
                    <li class="desenho-info" *ngFor="let font of fonts">
                        <p *ngIf="font.nome" i18n>
                            Nome: {{ font.nome }}
                        </p>
                        <p i18n>
                            Letras: {{ font['letras'] && font['letras'].length || 0 }}
                        </p>
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="file_contents.data['variáveis'] && file_contents.data['variáveis'].length > 0">
                <h2 i18n>Variáveis</h2>
                <ul class="variaveis"
                    *ngIf="file_contents.data['variáveis'] as variaveis">
                    <li class="desenho-info" *ngFor="let variavel of variaveis">
                        <p *ngIf="variavel.codigo" i18n>
                            Codigo: {{ variavel.codigo }}
                        </p>
                        <p *ngIf="variavel.tipo_de_variavel" i18n>
                            Tipo: {{ variavel.tipo_de_variavel }}
                        </p>
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="file_contents.data.recursos && file_contents.data.recursos.length > 0">
                <h2 i18n>Recursos</h2>
                <ul class="variaveis"
                    *ngIf="file_contents.data.recursos as recursos">
                    <li class="recurso-info" *ngFor="let recurso of recursos">
                        <pre *ngFor="let line of recurso.points"> {{ line }} <br />
                        </pre>
                    </li>
                </ul>
            </ng-container>

        </ng-container>

    </div>

    <button [disabled]="!file_contents" class="btn btn-success" name="button" (click)="salvar()" i18n>Salvar</button>

</form>
