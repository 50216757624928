<section id="landing">
    <article class="central">
        <img src="assets/img/thoreblogo.png" alt="Logo Thoreb" i18n-alt>
        <h2 i18n>Bem-vindo ao Editor de Mensagens Thoreb!</h2>
        <p i18n>Aqui, você pode editar os seus projetos, cadastrar seus roteiros e mensagens, e também transferí-los para os Itinerários através do aplicativo.</p>
        <p i18n>O que você quer fazer hoje?</p>
        <button class="btn btn-primary btn-block" type="button" name="button" routerLink="/home" i18n>Lista de projetos</button>
        <button class="btn btn-primary btn-block" type="button" name="button" routerLink="/projetos/cadastrar" i18n>Novo projeto</button>
        <button class="btn btn-primary btn-block" type="button" name="button" routerLink="/tutorial" i18n>Tutorial</button>
    </article>
</section>
