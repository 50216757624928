import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendMessagesService } from '../API_I18N.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  // The array is never used but is need to have a function call i18n
  // so we can extract the translations.
  private knownErrors: Array<string> = [
    $localize `ERROR_FONT_IN_USE`,
    $localize `ERROR_RESOURCE_IN_USE`,
    $localize `ERROR_EMPTY_PROJECT`,
    $localize `ERROR_INCORRECT_PANEL_COUNT`,
  ]

  constructor(private toastr: ToastrService) { }

  public handleError(response: any): Observable<any> {
    return this.getErrorCodeFromResponse(response)
      .pipe(
        tap((errorCode: string) => {
          let errorMessage = this.getTranslatedErrorMessageFromErrorCode(errorCode);
          this.toastr.error(errorMessage);
        }),
        switchMap(() => {
          return throwError(response.error);
        })
      );
  }

  private getErrorCodeFromResponse(response): Observable<any> {
  console.log(response);
    if (response.error instanceof Blob) {
      return this.parseErrorBlob(response)
        .pipe(
          switchMap((error) => {
            return of(error.message);
          })
        );
    } else {
      return of(response.error.message);
    }
  }

  private getTranslatedErrorMessageFromErrorCode(errorCode: string): string {
    let translatedError = (new BackendMessagesService).from_code(errorCode);
    if (translatedError != errorCode) {
      return translatedError;
    } else {
      return $localize `Ocorreu um erro inesperado ao executar essa ação.` + ' ' + errorCode;
    }
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.next(JSON.parse(this.toString(reader.result)));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  private toString(content: any): string {
    if (content instanceof ArrayBuffer) {
      return String.fromCharCode.apply(null, new Uint16Array(content));
    } else {
      return content;
    }
  }

}
