import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { HelpService } from '@app/services/help.service';


@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

    constructor(
        private titleService: Title,
        private help: HelpService,
    ) {
        this.titleService.setTitle($localize `Tutorial - Thoreb Itinerário`);
        this.help.local = 'tutorial';
    }

    ngOnInit() {
    }

}
