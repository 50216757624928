<ng-container [ngModelGroup]="(i).toString()" #painelFC="ngModelGroup">
    <ng-container *ngIf="painel.id != undefined"><small class="bordered warning mb" i18n>Atenção! Editar o tamanho deste painel vai ativar o cálculo de tamanho do grupo. Verifique as mensagens após a edição!</small></ng-container>
    <div class="painel-linhas">
        <label for="dimension_x" i18n>Colunas</label>
        <select name="dimension_x" #dimension_x [(ngModel)]="painel.dimension_x" (change)="painel.dimension_x = +dimension_x.value;">
            <option [value]="x" *ngFor="let x of opcoesPossiveis[painel.dimension_y]">{{x}}</option>
        </select>
    </div>
    <div class="painel-colunas">
        <label for="dimension_y" i18n>Linhas</label>
        <select name="dimension_y" #dimension_y [(ngModel)]="painel.dimension_y" (change)="painel.dimension_y = +dimension_y.value;">
                <option [value]="y" *ngFor="let y of keys(opcoesPossiveis)">{{y}}</option>
        </select>
    </div>
    <div class="painel-icone">
        <i class="icon icon-color-white {{+painel.dimension_x < +painel.dimension_y ? 'rotated' : ''}}">
            <svg viewBox="0 0 24 14">
                <use xlink:href="assets/img/icon-painel-flat.svg#icon"></use>
            </svg>
        </i>
    </div>
    <div class="painel-localizacao">
        <div class="form-section">
            <label for="rgb" i18n>RGB</label>
            <div class="rgb-toggle">
                <input id="rgb-recurso" type="checkbox" name="rgb" [(ngModel)]="painel.rgb" #rgb (change)="change_rgb(rgb.checked)">
                <span i18n>Esse painel tem suporte a RGB</span>
            </div>
        </div>
        <div class="form-section">
            <label for="localizacao" i18n>Localização</label>
            <input type="text" name="localizacao" [(ngModel)]="painel.localizacao" #localizacao="ngModel" required maxlength="50" [class.danger]="!localizacao.valid && !localizacao.pristine" placeholder="Ex: Lateral, Frontal" i18n-placeholder>
            <div class="alert danger" *ngIf="!localizacao.valid && !localizacao.pristine">
                <div *ngIf="localizacao.errors.required" i18n>Campo obrigatório</div>
                <div *ngIf="localizacao.errors.maxlength" i18n>Máximo de 50 caracteres</div>
            </div>
        </div>

        <div class="form-section">
            <label for="enderecoCan" i18n>Endereço CAN</label>
            <input type="number" name="enderecoCan" [(ngModel)]="painel.enderecoCan"
                #enderecoCan="ngModel" required [min]="101" [max]="127" [notEqualMultiple]="enderecosCan(painel)"
                [class.danger]="!enderecoCan.valid && !enderecoCan.pristine" placeholder="Endereço" i18n-placeholder
            >
            <small i18n>Valores de 101 a 127</small>
            <div class="alert danger" *ngIf="!enderecoCan.valid && !enderecoCan.pristine">
                <div *ngIf="enderecoCan.errors.required" i18n>Campo obrigatório</div>
                <div *ngIf="enderecoCan.errors.min" i18n>Valor mínimo: 101</div>
                <div *ngIf="enderecoCan.errors.max" i18n>Valor máximo: 127</div>
                <div *ngIf="enderecoCan.errors.notEqualMultiple" i18n>Não pode haver endereços CAN repetidos</div>
            </div>
        </div>
    </div>
</ng-container>
