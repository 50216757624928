<div class="letra-item">
    <div class="character-input-container form-section">
        <label for="caractere" i18n>Caractere</label>
        <input class="character-input" type="text" minlength=1 maxlength=1 name="caractere" [(ngModel)]="letra.caractere" [attr.tabindex]="i">
    </div>
    <div class="error">
        <p class="alert danger" *ngIf="isDuplicatedCharacter(letra, fonte)" i18n>Caracter repetido</p>
        <p class="alert danger" *ngIf="isCharacterEmpty(letra)" i18n>Caractere não pode ser vazio</p>
    </div>
    <div>
        <canvas class='canvas' #canvas (click)="registerClick($event)" id={{letra.caractere}}
          [attr.data-width]="canvasX"
          [attr.data-height]="canvasY"
        ></canvas>
    </div>
    <div class="letra-buttons">
        <button class="btn btn-primary" type="button" name="button" (click)="duplicar()">
            <i class="fas fa-copy"></i>
        </button>
        <button class="btn btn-danger" type="button" name="button" (click)="excluir()">
            <i class="fas fa-trash"></i>
        </button>
    </div>
</div>
