import { Injectable } from '@angular/core';
import * as model from '../projeto.model';
import { FontesApiService } from '@app/services/fontes-api.service';

@Injectable({
    providedIn: 'root'
})
export class FontCalculator {

    constructor(
        private fontesApi: FontesApiService,
    ) { }


    // usamos "+" para converter strings para int
    async calcularMelhorFonte(altura: number) {
        let fontes = await this.fontesApi.list_all();
        if (!fontes.length) return;

        const ALFABETO = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const alfabeto = ALFABETO.toLowerCase();

        let fontes_com_alfabeto = fontes.filter(({ letras }) => {
            let contem_ALFABETO = Array.from(ALFABETO).every(c => {
                return letras.some(letra => letra.caractere == c);
            });
            let contem_alfabeto = Array.from(alfabeto).every(c => {
                return letras.some(letra => letra.caractere == c);
            });
            return contem_ALFABETO || contem_alfabeto;
        });

        console.log("fontes_com_alfabeto", fontes_com_alfabeto);

        if (fontes_com_alfabeto.length)
            fontes = fontes_com_alfabeto;

        const menor_fonte = fontes
            .reduce((a, b) => +a.tamanho < +b.tamanho ? a : b, fontes[0]);

        const fonte_com_tamanho_correto =  fontes
            // Remover fontes que não cabem na altura
            .filter((fonte) => +fonte.tamanho <= altura)
            // Pegar a maior fonte que sobrou, se não pegar a menor de todas
            .reduce((a, b) => +a.tamanho > +b.tamanho ? a : b, menor_fonte);

        return fontes
            // Filtrar as fontes com o tamanho correto
            .filter((fonte) => fonte.tamanho == fonte_com_tamanho_correto.tamanho)
            // Pegar a fonte com a maior largura
            .reduce((a, b) => +a.largura > +b.largura ? a : b, fonte_com_tamanho_correto);
    }
}
