import { Injectable } from '@angular/core';
import { EntityService } from '../entity-service';
import { Fonte } from '../projeto.model';
import { FontesApi } from './fontes.api';
import { ToastsService } from '../toasts.service';

@Injectable({
    providedIn: 'root'
})
export class FontesApiService implements EntityService<Fonte, null>  {
    private fontes: Fonte[];
    private fontes$: Promise<Fonte[]>;

    constructor(
        private api: FontesApi,
        private toasts: ToastsService,
    ) { }

    async get_by_id(id: number): Promise<Fonte> {
        try {
            let cached = this.fontes.find(font => font.id == id);
            if (cached) return cached;

            return this.api.get_by_id(id);
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar a fonte`);
        }
    }
    async list_all(): Promise<Fonte[]> {
        if (this.fontes != undefined)
            return this.fontes;
        if(this.fontes$)
            return await this.fontes$;

        const close_toast = this.toasts.api_info($localize `Buscando fontes`);
        try {
            this.fontes$ = this.api.list_all();
            this.fontes = await this.fontes$;
            close_toast();
            return this.fontes;
        } catch (error) {
            this.toasts.api_error(
                $localize `Erro ao carregar as fontes`
            )
        } finally {
            this.fontes$ = undefined;
            close_toast();
        }
    }

    async save(item: Fonte) {
        const close_toast = this.toasts.api_info($localize `Salvando fonte`);
        try {
            const data = await this.api.save(item);
            this.toasts.api_success($localize `Fonte salva com sucesso`);
            this.replaceFonte(item, data);
            return data;
        } finally {
            close_toast();
        }
    }
    async clone(item: Fonte): Promise<Fonte> {
        const close_toast = this.toasts.api_info($localize `Duplicando projeto`);
        try {
            let cloned_item = structuredClone(item);
            cloned_item.id = undefined;
            cloned_item.letras.forEach(letra => {
                letra.id = undefined;
            });
            const data = await this.api.save(cloned_item);
            this.toasts.api_success($localize `Duplicado com sucesso`);
            this.fontes.push(data);
            return data;
        } catch (err) {
            console.error("Error cloning project", err);
            this.toasts.api_error($localize `Não foi possível duplicar o projeto`);
        } finally {
            close_toast();
        }
    }
    async delete(item: Fonte): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize `Excluindo a fonte`);
        const remover_fonte = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            this.fontes = this.fontes
                .filter((projetoIt) => projetoIt.id != item.id);
        };

        try {
            if (item.id != undefined)
                await this.api.delete(item);
            remover_fonte();
            return true;
        } catch (error) {
            // this.toasts.api_error($localize `Não foi possível excluir a fonte`);
            return false;
        } finally {
            close_toast();
        }
    }

    get_parent(item: Fonte): Promise<null> {
        throw new Error('Method not implemented.');
    }
    list_by_parent_id(parent_id: number): Promise<Fonte[]> {
        throw new Error('Method not implemented.');
    }

    select(item: Fonte) {
        throw new Error('Method not implemented.');
    }
    get_selected(): Fonte {
        throw new Error('Method not implemented.');
    }
    is_selected(item: Fonte): boolean {
        throw new Error('Method not implemented.');
    }
    clear_selected() {
        throw new Error('Method not implemented.');
    }
    clear_caches() {
        this.fontes = undefined;
        this.fontes$ = undefined;
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////


    public replaceFonte(old: Fonte, newP: Fonte) {
        if (old.id != undefined) {
            this.fontes = this.fontes.map(
                (proj) => (proj.id === old.id) ? newP : proj
            )
            return;
        }

        this.fontes = this.fontes.map(
            (font) => (font === old) ? newP : font
        )

        if (this.fontes.indexOf(newP) == -1) {
            this.fontes.push(newP);
        }
    }
}
