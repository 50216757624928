import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-tecnico-form',
    templateUrl: './tecnico-form.component.html',
    styleUrls: ['./tecnico-form.component.scss'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class TecnicoFormComponent implements OnInit {

    private _tecnico: model.Usuario;
    public oldUser: model.Usuario;
    public regex = new RegExp('[A-Za-z].*[0-9]|[0-9].*[A-Za-z]', 'i');
    public passwordToggled = false;

    @ViewChild('confirmacaoSenha') confirmacaoSenhaFormControl: NgModel;

    @Input()
    set tecnico(tecnico: model.Usuario) {
        this._tecnico = tecnico;
        this.oldUser = JSON.parse(JSON.stringify(tecnico));
    }
    get tecnico() {
        return this._tecnico;
    }

    constructor() { }

    ngOnInit() {
    }

    togglePassword() {
        this.passwordToggled = !this.passwordToggled;
    }

    validatePasswordConfirmation(){
        this.confirmacaoSenhaFormControl.control.updateValueAndValidity()
    }

}
