import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Directive, forwardRef, Attribute } from "@angular/core";

@Directive({
    selector: '[mustMatch][formControlName],[mustMatch][formControl],[mustMatch][ngModel]',
    providers: [{ 
        provide: NG_VALIDATORS, 
        useExisting: forwardRef(() => MustMatchDirective), 
        multi: true 
    }]
})
export class MustMatchDirective implements Validator {

    constructor( @Attribute('mustMatch') public mustMatch: string) {}

    validate(control: AbstractControl): { [key: string]: any } | null {
        
        let other = control.parent.get(this.mustMatch);
        
        if (other && control.value !== other.value) {
            return {
                doesntMatch: true
            }
        }
        return null;
    } 

}