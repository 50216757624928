<div class="modal-header" i18n>
    AJUDA DAS FONTES
</div>
<div class="modal-content">
    <p>
        <strong i18n>No editor de fontes você pode cadastrar e editar as fontes do sistema.</strong>
    </p>
    <p i18n>Nesta tela você pode editar as fontes do sistema, bem como adicionar ou remover novos caracteres.</p>

    <p i18n>
        <strong>Atenção!</strong> Caso a fonte não possua todos os caracteres, o usuário digitará o caractere e ele não será renderizado.
    </p>
    <div class="row">
        <p i18n>
            Você pode criar fontes para diversas línguas, basta que você coloque o caractere e a representação visual dele.
        </p>
        <img class="inline-img" src="assets/img/ajuda-fontes-1.png" alt="Caractere japonês" i18n>
    </div>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
