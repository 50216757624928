import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, AbstractControl } from "@angular/forms";

import { cnpj } from 'cpf-cnpj-validator'

@Directive({
    selector: '[cnpj-validator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CNPJValidatorDirective, multi: true }]
})
export class CNPJValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } | null {
        if(!cnpj.isValid(control.value, true)){
            return {
                invalidCNPJ: true
            }
        }
    }
    
}