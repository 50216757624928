import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Fonte } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";

@Injectable({
    providedIn: "root",
})
export class FontesApi implements ApiClient<Fonte, null> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient,
        @Inject(LOCALE_ID) private lang: string
    ) { }

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/fonte`;
        } else {
            return `${this.apiPoint}/fonte/${id}`;
        }
    }
    from_transport_format(data: Fonte): Fonte {
        data.letras.forEach(letra => {
            letra.pontos = (<any>letra.pontos)
                .map(([x, y]) => {
                    return {
                        x: +x,
                        y: +y
                    }
                });
        });
        return Object.assign(new Fonte(), data);
    }
    into_transport_format(fonte: Fonte): Fonte {
        fonte.letras.forEach(letra => {
            letra.pontos = (<any>letra.pontos)
                .map(({x, y}) => {
                    return [x, y];
                });
        });
        return Object.assign(new Fonte(), fonte);
    }
    async get_by_id(id: number): Promise<Fonte> {
        let res = await this.http.get<Fonte>(this.api_endpoint(id)).toPromise();
        return this.from_transport_format(res);
    }
    async list_all(): Promise<Fonte[]> {
        let res = await this.http.get<Fonte[]>(this.api_endpoint()).toPromise();
        return res.map(this.from_transport_format);
    }
    async save(item: Fonte): Promise<Fonte> {
        let res;
        if (item.id !== undefined) {
            res = this.http
                .put<Fonte>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            res = this.http
                .post<Fonte>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
        return this.from_transport_format(await res);
    }
    delete(item: Fonte): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(_item: Fonte): Promise<Fonte> {
        throw new Error("Method not implemented.");
    }

    // métodos não implementados pois fontes não tem parente
    get_parent(_item: Fonte): null {
        throw new Error("Method not implemented.");
    }
    list_by_parent_id(_parent_id: number): null {
        throw new Error("Method not implemented.");
    }
}
