<div class="ml mt mr">
    <h1 i18n>Upload de imagem para recurso</h1>
    <form>
        <app-file-input mode="arrayBuffer" (change)="change($event)" />

        <button
            id="salvar-recurso"
            class="btn btn-success"
            type="submit"
            name="button"
            (click)="submit()"
            i18n
        >
            Cadastrar recurso
        </button>
    </form>
</div>
