import { trigger, transition, style, animate, query, stagger, state, sequence, animateChild, group } from '@angular/animations';
import { swipeLeftIn, swipeLeftOut, swipeRightIn, swipeRightOut, swipeUpIn, swipeUpOut, swipeDownIn, swipeDownOut } from '@app/animations/swipe';

export const routeAnim = trigger('routeAnim', [
    transition('projeto => roteiros, landing => home, landing => projeto, landing => roteiros, landing => tutorial', [
        query(':enter, :leave', style({ position: 'fixed', width: '*', opacity: 0 }), {optional: true}),
        sequence([
            query(':enter, :leave', animateChild(), {optional: true}),
            query(':leave', swipeLeftOut, {optional: true}),
            query(':enter', swipeLeftIn, {optional: true}),
        ])
    ]),
    transition('roteiros => projeto, editor => home, home => landing, projeto => landing, editor => landing, roteiros => landing, tutorial => landing', [
        query(':enter, :leave', style({ position: 'fixed', width: '*', opacity: 0 }), {optional: true}),
        sequence([
            query(':enter, :leave', animateChild(), {optional: true}),
            query(':leave', swipeRightOut, {optional: true}),
            query(':enter', swipeRightIn, {optional: true}),
        ])
    ]),
    transition('home => projeto, home => roteiros, editor => roteiros, editor => projeto, simulador => projeto, simulador => roteiros, simulador => home, simulador => editor', [
        query(':enter, :leave', style({ position: 'fixed', width: '*', opacity: 0 }), {optional: true}),
        sequence([
            query(':enter, :leave', animateChild(), {optional: true}),
            query(':leave', swipeDownOut, {optional: true}),
            query(':enter', swipeDownIn, {optional: true}),
        ])
    ]),
    transition('projeto => home, roteiros => home, roteiros => editor, projeto => editor, projeto => simulador, roteiros => simulador, home => simulador, editor => simulador', [
        query(':enter, :leave', style({ position: 'fixed', width: '*', opacity: 0 }), {optional: true}),
        sequence([
            query(':enter, :leave', animateChild(), {optional: true}),
            query(':leave', swipeUpOut, {optional: true}),
            query(':enter', swipeUpIn, {optional: true}),
        ])
    ])
]);
