import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { ContextOptionsService } from '../services/context-options.service';
import { HelpService } from '../services/help.service';
import { AsideOpenService } from '../services/aside-open.service';
import { AuthService } from '../services/auth.service';


@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
    public tooltips = {
        'ajuda': $localize `Ajuda`,
    };

    public constructor(
        public contextOptions: ContextOptionsService,
        public loader: LoaderService,
        public help: HelpService,
        public asideOpen: AsideOpenService,
        public auth: AuthService,

    ) { }

    ngOnInit(): void {
    }


    exibirModal() {
        this.help.isVisible = true;
    }
}
