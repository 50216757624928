import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { EditorViewComponent } from '@app/editor/editor-mensagem/editor-view/editor-view.component';

import { TemplateCalculator } from '../template-calculator';
import * as model from '@app/projeto.model';


@Component({
    selector: 'app-editor-templates-modal',
    templateUrl: './editor-templates-modal.component.html',
    styleUrls: ['./editor-templates-modal.component.scss']
})
export class EditorTemplatesModalComponent implements OnInit, OnChanges {
    @Output() fechar = new EventEmitter<boolean>();
    @Output() grupos = new EventEmitter<model.Grupo[]>();
    @Input() painel: model.Painel;

    template_widths: Record<string, number> = {};

    @ViewChildren('view') views: QueryList<EditorViewComponent>;

    private grupos_disponiveis: Record<string, model.Grupo[]> = {};

    constructor(public templateCalculator: TemplateCalculator) { }

    ngOnInit() { this.init(); }
    ngOnChanges() { this.init(); }

    async init() {
        this.template_widths = {};
        this.grupos_disponiveis = {};

        // Inicializar as larguras para o input e os grupso para preview
        for (let disp of this.templateCalculator.getDisponiveis(this.painel)) {
            let g: model.Grupo[] = await this.templateCalculator.calcularTemplate(disp.id, this.painel);
            // Isso vem do campo end_x, então precisa ser um valor a cima
            this.template_widths[disp.id] = g[0].end_x + 1;
            this.grupos_disponiveis[disp.id] = g;
        }

        this.on_templates_modified();
    }

    async on_templates_modified() {
        console.log("Updating templates");
        console.log("Requested sizes are", this.template_widths);
        for (let disp of this.templateCalculator.getDisponiveis(this.painel)) {
            // Isso será usado no campo end_x, então precisa ser um valor a baixo
            let first_column_width = this.template_widths[disp.id] - 1;

            if (first_column_width < 0) {
                console.error("Cannot have zero width group");
                continue;
            }
            if ((first_column_width + 1) >= +this.painel.dimension_x) {
                console.error(`Cannot have group wider than panel, panel is ${this.painel.dimension_x} wide, you want ${first_column_width + 1}`);
                continue;
            }

            console.log("Requesting template with width", first_column_width);
            let g: model.Grupo[] = await this.templateCalculator.calcularTemplate(disp.id, this.painel, undefined, first_column_width);
            console.log("Template returned groups", g, "with width: ", g[0].end_x);
            // Isso vem do campo end_x, então precisa ser um valor a cima
            this.template_widths[disp.id] = (first_column_width ?? g[0].end_x) + 1;
            this.grupos_disponiveis[disp.id] = g;
        }

        console.log("pedindo update para todos os views filhos", this.views.toArray());
        this.views.toArray().forEach(view => {
            view.update();
        });
    }

    cancelar() {
        this.fechar.emit(true);
    }

    mensagem(tipo: string): model.Mensagem {
        if (!this.grupos_disponiveis[tipo])
            return undefined;

        return {
            'id': undefined,
            'ordem': 0,
            'tempo': 0,
            'descricao': '',
            'idPainel': this.painel.id,
            'grupos': this.grupos_disponiveis[tipo],
            'idRoteiro': undefined,
        };
    }

    editar(event) {

    }

    async selecionar(tipo: string) {
        let first_column_width = this.template_widths[tipo] - 1;
        let grupos = await this.templateCalculator.calcularTemplate(tipo, this.painel, true, first_column_width);
        this.grupos.emit(grupos);
    }
}
