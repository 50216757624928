import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UsuarioApiService } from '@app/services/usuario-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { AuthService } from '@app/services/auth.service';
import * as model from '@app/projeto.model';


@Component({
    selector: 'app-empresa',
    templateUrl: './empresa.component.html',
    styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

    public empresa: model.Empresa;
    public logado: model.Usuario;
    public qrContent: string;

    constructor(
        private usuarioApi: UsuarioApiService,
        public auth: AuthService,
        private loader: LoaderService,
        private titleService: Title,
        private help: HelpService
    ) {
        this.titleService.setTitle($localize `Empresa - Thoreb Itinerário`);
        this.help.local = 'empresa-usuario';

        this.usuarioApi.getEmpresa().subscribe((empresa: model.Empresa) => {
            this.empresa = empresa;
            this.logado = empresa.usuarios.find(
                (usuario: model.Usuario) => usuario.id == this.auth.user.id
            );
        });
    }

    ngOnInit() {
        this.qrContent = JSON.stringify(this.auth.user);
    }

    excluirUsuario(usuario: model.Usuario) {
        if (confirm($localize `Você tem certeza que deseja excluir esse usuário?`)) {
            this.loader.startLoading();
            this.usuarioApi.excluirUsuario(usuario).subscribe((data) => {
                this.loader.stopLoading();
                if (data) {
                    this.empresa.usuarios.splice(this.empresa.usuarios.findIndex((usuarioIt: model.Usuario) => usuarioIt == usuario), 1);
                }
            });
        }
    }

}
