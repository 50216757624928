<div class="modal-header" i18n>
    AJUDA DA PÁGINA INICIAL
</div>
<div class="modal-content">
    <p i18n>Bem-vindo ao software Editor Thoreb! Para começar a utilizar o software, você pode clicar no botão <b>Lista de projetos</b>, que o redirecionará para a lista de projetos, onde você pode começar a sua jornada pelo sistema!</p>
    <p i18n>Também é possível pular direto desta tela para o cadastro de um novo projeto, clicando em <b>Novo projeto</b>.</p>
    <p i18n>Caso seja sua primeira vez usando o sistema, recomendamos que clique em <b>Tutorial</b> para ficar a par de todas as funcionalidades e terminologias do sistema</p>
    <p i18n><b>Bom uso!</b></p>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
