<div class="mr ml">
    <h1 i18n>Editar senha</h1>
    <form #senhaForm="ngForm">
        <div class="form-section password-container">
            <label for="atual" i18n>Senha atual</label>
            <input [attr.type]="atualToggled ? 'text' : 'password'" name="atual" [(ngModel)]="atual" required minlength="8" [pattern]="regex" #atualS="ngModel" [class.danger]="!atualS.valid && !atualS.pristine">
            <span class="password-icon" (click)="toggle('atual')">
                <i class="icon size-2 clickable">
                    <svg viewBox="0 0 24 24">
                        <use [attr.xlink:href]="atualToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                    </svg>
                </i>
            </span>
            <div class="alert danger" *ngIf="!atualS.valid && !atualS.pristine">
                <div *ngIf="atualS.errors.minlength" i18n>Mínimo de 8 caracteres</div>
                <div *ngIf="atualS.errors.pattern" i18n>A senha deve possuir pelo menos uma letra e um número</div>
                <div *ngIf="atualS.errors.required" i18n>Este campo é obrigatório</div>
            </div>
        </div>

        <div class="form-section password-container">
            <label for="nova" i18n>Nova senha</label>
            <input [attr.type]="novaToggled ? 'text' : 'password'" name="nova" [(ngModel)]="nova" required minlength="8" [pattern]="regex" #novaS="ngModel" [class.danger]="!novaS.valid && !novaS.pristine">
            <span class="password-icon" (click)="toggle('nova')">
                <i class="icon size-2 clickable">
                    <svg viewBox="0 0 24 24">
                        <use [attr.xlink:href]="novaToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                    </svg>
                </i>
            </span>
            <div class="alert danger" *ngIf="!novaS.valid && !novaS.pristine">
                <div *ngIf="novaS.errors.minlength" i18n>Mínimo de 8 caracteres</div>
                <div *ngIf="novaS.errors.pattern" i18n>A senha deve possuir pelo menos uma letra e um número</div>
                <div *ngIf="novaS.errors.required" i18n>Este campo é obrigatório</div>
            </div>
        </div>
        <button class="btn btn-success" type="submit" name="button" (click)="alterarSenha()" [disabled]="!senhaForm.form.valid || submitting" i18n>Alterar senha</button>
        <div class="alert danger" *ngIf="erro">
            {{erro}}
        </div>
    </form>
</div>
