<label class="file-display">
    <input type="file" class="file-input" id="file-input"
           (change)="onFileSelected($event)" #fileUpload>

    <i class="icon size-2 clickable icon-color-white btn btn-primary">
        <svg viewBox="0 0 24 24">
            <use xlink:href="assets/img/icon-import.svg#icon"></use>
        </svg>
    </i>
    <ng-container *ngIf="file_name; else elseBlock" i18n>
        Arquivo pronto para enviar: {{ file_name }}
    </ng-container>
    <ng-template #elseBlock i18n>
        Escolha um arquivo
    </ng-template>
</label>
