import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AsideOpenService {

    public closed = false;
    public isBlocked = false;

    constructor() { }

    public toggle() {
        this.closed = !this.closed;
    }
}
