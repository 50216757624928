<div class="modal-header" i18n>
    AJUDA DA LISTA DE EMPRESAS
</div>
<div class="modal-content">
    <p>
        <strong i18n>Na lista de empresas, você pode bloquear, visualizar, cadastrar e excluir empresas. Esta página está disponível apenas para usuários da Thoreb Brasil.</strong>
    </p>
    <p i18n>Para cadastrar uma nova empresa, clique no botão <b>Nova empresa</b></p>
    <p i18n>
        Isto abrirá um formulário para cadastro das informações da nova empresa e das informações de login do administrador dela.<br>
        Cada empresa possui 1 administrador, que pode cadastrar até outros 3 usuários, totalizando 4 usuários da mesma empresa.
        Todos estes usuários editam, visualizam e simulam os mesmos projetos. <br>
        <b>
            Lembre-se: o email do administrador da empresa, bem como os emails dos outros usuários não podem ser repetidos, e o sistema bloqueará
            o cadastro enquanto o email informado não for válido ou for repetido.
        </b>
    </p>
    <p i18n>
        Na listagem das empresas, a primeira que aparecerá será a Thoreb Brasil. Esta empresa não pode ser excluída ou bloqueada, mas suas informações podem ser alteradas.
        Nas outras empresas, estão disponíveis um botão para excluir a respectiva empresa e um switch para bloqueio/desbloqueio. Empresas bloqueadas não permitem que seus usuários
        façam login, e as sessões que já foram feitas são canceladas (não podem mais fazer nada no sistema).
    </p>
    <img class="full-img" src="assets/img/ajuda-empresa-1.png" alt="Lista de empresas" i18m-alt>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
