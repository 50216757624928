import { Injectable } from '@angular/core';

import { Alteravel } from '@app/alteravel';


@Injectable({
    providedIn: 'root'
})
export class ConfirmarDeixarPagina  {


    canDeactivate(target: Alteravel) {
        if (target.alteracao) {
            return window.confirm($localize `Você tem alterações não salvas. Deseja sair mesmo assim?`);
        }
        return true;
    }

}
