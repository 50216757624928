<div class="row-l" *ngIf="projeto != undefined">
    <div class="table second-aside">
        <div class="row" (click)="selectRoteiro(roteiro)" *ngFor="let roteiro of roteiros; let i = index" [class.selected]="roteiroSelecionado == roteiro">
            <div class="cell flex-size-1">
                <div class="checkbox" [class.selected]="roteiroSelecionado == roteiro"></div>
            </div>
            <div class="cell flex-size-2">
                {{roteiro.cod}} -
                {{roteiro.nome}}
            </div>
        </div>
    </div>
    <div class="simulador">
        <div *ngIf="roteiroSelecionado != undefined">
            <ng-container *ngFor="let v of struct">
                <h3>{{v.painel.dimension_y}} x {{v.painel.dimension_x}} - {{v.painel.localizacao}}</h3>
                <app-editor-view [painel]="v.painel" [mensagem]="v.mensagem" [rects]="false" (editar)="editar($event)" (click)="click($event)" #view [style.width]="v.painel.dimension_x / maxSize * 100 + '%'"></app-editor-view>
            </ng-container>
            <button class="btn btn-primary" type="button" name="button" (click)="irEditor()" i18n>Ir para o editor</button>
        </div>
    </div>
</div>
