import { Injectable } from '@angular/core';

import * as model from '@app/projeto.model';

import { ErrorHandlerService } from '@app/shared/services/error-handler.service';
import { EntityService } from '@app/entity-service';
import { MensagemApi } from './mensagem.api';
// import { ProjetoApiService } from './projeto-api.service';
import { ToastsService } from '@app/toasts.service';
// import { RoteiroApiService } from './roteiro-api.service';

@Injectable({
    providedIn: 'root'
})
export class MensagemApiService implements EntityService<model.Mensagem, model.Roteiro> {
    private mensagens: model.Mensagem[];
    private mensagens_by_parent: Record<number, model.Mensagem[]> = {};
    private mensagemAtivo: model.Mensagem;

    constructor(
        private toasts: ToastsService,
        private api: MensagemApi,
        // private parent: RoteiroApiService,
        private errorHandler: ErrorHandlerService
    ) { }

    async get_by_id(id: number): Promise<model.Mensagem> {
        const close_toast = this.toasts.api_info($localize `Buscando mensagens`);
        try {
            let projeto = await this.api.get_by_id(id);
            close_toast();
            return projeto;
        } catch (error) {
            close_toast();
            this.toasts.api_error($localize `Erro ao carregar os mensagens`);
        }
    }
    async list_all(): Promise<model.Mensagem[]> {
        if (this.mensagens != undefined)
            return this.mensagens;
        const close_toast = this.toasts.api_info($localize `Buscando mensagens`);
        try {
            let res = await this.api.list_all();
            this.mensagens = res;
            return this.mensagens;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os mensagens`);
        } finally {
            close_toast();
        }
    }
    async save(item: model.Mensagem) {
        const close_toast = this.toasts.api_info($localize `Salvando projeto`);
        try {
            const data = await this.api.save(item);
            this.toasts.api_success($localize `Salvo com sucesso`);
            this.replaceMensagem(item, data);
            return data;
        } catch (error) {
            this.errorHandler.handleError(error).subscribe();
        } finally {
            close_toast();
        }
    }
    clone(item: model.Mensagem): Promise<model.Mensagem> {
        throw new Error('Method not implemented.');
    }
    async delete(item: model.Mensagem): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize `Excluindo a mensagem`);
        const remover = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            if (this.mensagens)
                this.mensagens = this.mensagens
                    .filter((projetoIt) => projetoIt.id != item.id);
            this.clear_selected()
        };

        try {
            if (item.id != undefined)
                await this.api.delete(item);
            remover();
            return true;
        } catch (error) {
            console.error(error);
            this.toasts.api_error($localize `Não foi possível excluir a mensagem`);
            return false;
        } finally {
            close_toast();
        }
    }
    // get_parent(item: model.Mensagem): Promise<model.Roteiro> {
    //     return this.parent.get_by_id(item.id);
    // }
    async list_by_parent_id(parent_id: number): Promise<model.Mensagem[]> {
        const close_toast = this.toasts.api_info($localize `Buscando mensagens`);
        try {
            let res = await this.api.list_by_parent_id(parent_id);
            close_toast();
            this.mensagens_by_parent[parent_id] = res;
            return res;
        } catch (error) {
            console.error(error);

            close_toast();
            this.toasts.api_error($localize `Erro ao carregar os mensagens`);
        }
    }

    select(item: model.Mensagem) {
        this.mensagemAtivo = item;
    }
    get_selected(): model.Mensagem {
        return this.mensagemAtivo;
    }
    is_selected(item: model.Mensagem): boolean {
        return this.mensagemAtivo == item;
    }
    clear_selected() {
        this.mensagemAtivo = undefined;
    }
    clear_caches() {
        this.mensagens;
        this.mensagens_by_parent = {};
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////

    public replaceMensagem(old: model.Mensagem, newP: model.Mensagem) {
        if (this.mensagens == undefined)
            return;
        if (old.id == undefined)
            return this.mensagens.push(newP);

        this.mensagens = this.mensagens.map(
            proj => (+proj.id === +old.id) ? newP : proj
        )
    }

}
