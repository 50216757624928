<div class="modal-header" i18n>
    AJUDA DOS RECURSOS
</div>
<div class="modal-content">
    <p>
        <strong i18n>No editor de recursos, você pode criar desenhos à mão livre para usar nos seus projetos.</strong>
    </p>
    <p i18n>
        Nesta tela você pode visualizar e gerenciar os recursos da sua empresa. Os recursos são desenhos feitos por você que podem ser usados em qualquer mensagem nos projetos. <br>
        Geralmente, você desenheará ícones e logomarcas.
    </p>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-recursos-1.png" alt="Formulário de cadastro de recursos" i18n-alt>
        <p i18n>
            Para desenhar é muito simples: selecione as dimensões que você quer o desenho e comece a clicar nos pontos! Ao clicar em um ponto apagado, ele acende, e vice-versa. <br>
            Quando estiver satisfeito com o resultado, salve e teste num projeto!
        </p>
    </div>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
