<div class="ml mt mr">
    <h1 i18n>Edição de fontes</h1>
    <button id="criar-nova-fonte" class="btn btn-primary" type="button" name="button" (click)="novaFonte()" [disabled]="fontes == null" i18n>Nova fonte</button>
    <ul class="font-list">
        <li class="font-item" *ngFor="let fonte of fontes; let i = index" [ngClass]="{editing: editando == fonte}">
            <button class="editar-fonte btn" type="button" name="button" [attr.tooltip]="tooltips.edit"
                (click)="editar(fonte)">
                <i class="fas fa-pen"></i>
            </button>
            <button class="duplicar-fonte btn btn-primary" type="button" name="button"  [attr.tooltip]="tooltips.dupe"
                (click)="duplicarFonte(fonte)">
                <i class="fas fa-copy"></i>
            </button>
            <button class="excluir-fonte btn btn-danger" type="button" name="button"  [attr.tooltip]="tooltips.delete"
                (click)="excluirFonte(fonte, $event);" #excluir>
                <i class="fas fa-trash"></i>
            </button>

            {{ fonte.nome }}
            <ng-container *ngIf="editando == fonte">
                <app-fontes-form-fonte [fonte]="fonte" (salvar$)="salvarListener($event)"></app-fontes-form-fonte>
            </ng-container>
        </li>
    </ul>
</div>
