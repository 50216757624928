<div class="ml mr mt">
    <h1 i18n>Baixar um projeto</h1>
    <small i18n>Use o campo a seguir para pesquisar o projeto que deseja, em seguida faça o download.</small>
    <form>
        <div class="form-section">
            <input type="text" name="pesquisa" [(ngModel)]="api.pesquisa" placeholder="Digite para pesquisar" i18n-placeholder>
        </div>
        <div class="form-section">
            <button class="btn btn-success" type="submit" name="button" (click)="buscar()" i18n>Buscar</button>
        </div>
        <div class="form-section">
            <div class="bordered mb" *ngFor="let projeto of api.projetos">
                {{ projeto.nomeEmpresa }} > {{ projeto.nome }}&nbsp;
                <div class="break-mobile">
                    <button type="button" name="button" class="btn btn-primary" (click)="api.baixarProjeto(projeto)" i18n>Download</button>
                </div>
            </div>
        </div>
    </form>
</div>
