import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Painel, Projeto, Roteiro } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";
import { ProjetoApi } from "./projeto.api";

@Injectable({
    providedIn: "root",
})
export class PainelApi implements ApiClient<Painel, Projeto> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient,
        private projetos: ProjetoApi,
        @Inject(LOCALE_ID) private lang: string
    ) {}

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/painel`;
        } else {
            return `${this.apiPoint}/painel/${id}`;
        }
    }
    get_by_id(id: number): Promise<Painel> {
        return this.http.get<Painel>(this.api_endpoint(id)).toPromise();
    }
    list_all(): Promise<Painel[]> {
        throw new Error("Method not implemented.");
    }
    save(item: Painel): Promise<Painel> {
        if (item.id !== undefined) {
            return this.http
                .put<Painel>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            return this.http
                .post<Painel>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
    }
    delete(item: Painel): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(item: Painel): Promise<Painel> {
        throw new Error("Method not implemented.");
    }

    get_parent(item: Painel): Promise<Projeto> {
        return this.projetos.get_by_id(item.parent_id);
    }
    list_by_parent_id(parent_id: number): Promise<Painel[]> {
        const url = `${this.apiPoint}/painel/by-parent-id/${parent_id}`;
        return this.http.get<Painel[]>(url, this.json_options).toPromise();
    }
}
