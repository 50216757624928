import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@app/../environments/environment";

import { Projeto, Roteiro, ExportedProject } from "@app/projeto.model";
import { ApiClient } from "@app/api-client";

@Injectable({
    providedIn: "root",
})
export class ProjetoApi implements ApiClient<Projeto, null> {
    private apiPoint = environment.endpoint;
    private json_options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient,
        @Inject(LOCALE_ID) private lang: string
    ) { }

    api_endpoint(id: number | undefined = undefined): string {
        if (id == undefined) {
            return `${this.apiPoint}/projeto`;
        } else {
            return `${this.apiPoint}/projeto/${id}`;
        }
    }
    get_by_id(id: number): Promise<Projeto> {
        return this.http.get<Projeto>(this.api_endpoint(id)).toPromise();
    }
    list_all(): Promise<Projeto[]> {
        return this.http.get<Projeto[]>(this.api_endpoint()).toPromise();
    }
    save(item: Projeto): Promise<Projeto> {
        if (item.id !== undefined) {
            return this.http
                .put<Projeto>(
                    this.api_endpoint(item.id),
                    item,
                    this.json_options
                )
                .toPromise();
        } else {
            return this.http
                .post<Projeto>(
                    this.api_endpoint(),
                    item,
                    this.json_options
                )
                .toPromise();
        }
    }
    delete(item: Projeto): Promise<boolean> {
        return this.http
            .delete<boolean>(this.api_endpoint(item.id), this.json_options)
            .toPromise();
    }
    clone(item: Projeto): Promise<Projeto> {
        return this.http
            .put<Projeto>(
                `${this.apiPoint}/duplicarprojeto/${item.id}`,
                item,
                this.json_options
            )
            .toPromise();
    }

    // métodos não implementados pois porjetos não tem parente
    get_parent(item: Projeto): null {
        throw new Error("Method not implemented.");
    }
    list_by_parent_id(parent_id: number): null {
        throw new Error("Method not implemented.");
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////

    baixarProjeto(idProjeto: number): Promise<Blob> {
        const headers = new HttpHeaders({
            Accept: "application/thd",
        });
        return this.http.get(
            `${this.apiPoint}/bitmap/${idProjeto}/${this.lang}`,
            { headers: headers, responseType: "blob" }
        ).toPromise();
    }

    imprimirProjeto(idProjeto: number): Promise<Blob> {
        const headers = new HttpHeaders({
            Accept: "application/x-pdf",
        });
        return this.http.get(
            `${this.apiPoint}/imprimir/${idProjeto}/${this.lang}`,
            { headers: headers, responseType: "blob" }
        ).toPromise();
    }

    exportarProjeto(idProjeto: number): Promise<Blob> {
        return this.http.get(
            `${this.apiPoint}/projeto/exportar/${idProjeto}`,
            { responseType: "blob" }
        ).toPromise();
    }
    importarProjeto(item: ExportedProject): Promise<Projeto> {
        return this.http.post<Projeto>(
            `${this.apiPoint}/projeto/importar`,
            item,
            this.json_options
        ).toPromise();
    }

    procurarProjetos(pesquisa: string): Promise<Projeto[]> {
        return this.http.get<Projeto[]>(
            `${this.apiPoint}/suporte/${encodeURIComponent(pesquisa)}`,
            this.json_options
        ).toPromise();
    }

    buscarRoteiro(idRoteiro: number): Promise<Roteiro> {
        return this.http
            .get<Roteiro>(`${this.apiPoint}/roteiro/${idRoteiro}`)
            .toPromise();
    }
}
