<aside class="menu" *ngIf="!asideOpen.closed && !asideOpen.isBlocked">
    <!-- <img src="assets/img/thoreblogobranco.png" alt="Logo Thoreb" class="logo-img"> -->
    <!-- {{contextOptions.actions.length}} -->
    <div class="menu-container" [@swipeLeft]="contextOptions.actions.length">
        <div class="menu-item" *ngFor="let action of contextOptions.actions; trackBy: trackFn">
            <a class="menu-item-link" (click)="action.trigger()">
                <i class="size-2 icon icon-color-white">
                    <svg viewBox="0 0 36 36">
                        <use [attr.xlink:href]="'assets/img/' + action.icon + '.svg#icon'"></use>
                    </svg>
                </i>
                <span>{{action.name}}</span>
            </a>
        </div>

        <div class="menu-item-bottom" *ngIf="auth.user">
            <hr>
            <ng-container *ngIf="auth.user.idEmpresa == 1">
                <div class="menu-item">
                    <a class="menu-item-link" routerLink="/suporte">
                        <i class="size-2 icon icon-color-white">
                            <svg viewBox="0 0 36 36">
                                <use xlink:href="assets/img/icon-support.svg#icon"></use>
                            </svg>
                        </i>
                        <span i18n>Suporte</span>
                    </a>
                </div>
                <div class="menu-item">
                    <a class="menu-item-link" routerLink="/contato/listar">
                        <i class="size-2 icon icon-color-white">
                            <svg viewBox="0 0 36 36">
                                <use xlink:href="assets/img/icon-feedback.svg#icon"></use>
                            </svg>
                        </i>
                        <span i18n>Contatos</span>
                    </a>
                </div>
                <div class="menu-item">
                    <a class="menu-item-link" routerLink="/empresa/listar">
                        <i class="size-2 icon icon-color-white">
                            <svg viewBox="0 0 36 36">
                                <use xlink:href="assets/img/icon-enterprise.svg#icon"></use>
                            </svg>
                        </i>
                        <span i18n>Empresas</span>
                    </a>
                </div>
                <div class="menu-item">
                    <a class="menu-item-link" routerLink="/tecnicos">
                        <i class="size-2 icon icon-color-white">
                            <svg viewBox="0 0 36 36">
                                <use xlink:href="assets/img/technician.svg#icon"></use>
                            </svg>
                        </i>
                        <span i18n>Técnicos</span>
                    </a>
                </div>
                <div class="menu-item">
                    <a class="menu-item-link" routerLink="/fontes">
                        <i class="size-2 icon icon-color-white">
                            <svg viewBox="0 0 36 36">
                                <use xlink:href="assets/img/icon-font.svg#icon"></use>
                            </svg>
                        </i>
                        <span i18n>Fontes</span>
                    </a>
                </div>
            </ng-container>
            <div class="menu-item">
                <a class="menu-item-link" routerLink="/recursos">
                    <i class="size-2 icon icon-color-white">
                        <svg viewBox="0 0 36 36">
                            <use xlink:href="assets/img/icon-resources.svg#icon"></use>
                        </svg>
                    </i>
                    <span i18n>Recursos</span>
                </a>
            </div>
            <div class="menu-item">
                <a class="menu-item-link" routerLink="/empresa">
                    <i class="size-2 icon icon-color-white">
                        <svg viewBox="0 0 36 36">
                            <use xlink:href="assets/img/icon-profile.svg#icon"></use>
                        </svg>
                    </i>
                    <span>{{auth.user.nome}}</span>
                </a>
            </div>
            <div class="menu-item">
                <a id="logout-button" class="menu-item-link" (click)="auth.logout()">
                    <i class="size-2 icon icon-color-white">
                        <svg viewBox="0 0 36 36">
                            <use xlink:href="assets/img/icon-exit.svg#icon"></use>
                        </svg>
                    </i>
                    <span i18n>Sair</span>
                </a>
            </div>
        </div>

    </div>
</aside>
