<div class="letra-item">
    <div class="character-input-container form-section">
        <label for="caractere" i18n>Caractere</label>
        <input
            class="character-input"
            type="text"
            name="caractere"
            disabled
            [(ngModel)]="letra.caractere"
            [attr.tabindex]="i"
        />
    </div>
    <div>
        <canvas
            class="canvas"
            #canvas
            id="{{ letra.caractere }}"
        ></canvas>
    </div>
    <div class="letra-buttons">
        <button
            class="btn btn-primary"
            type="button"
            name="button"
            (click)="restaurar()"
        >
            <i class="fas fa-trash-restore"></i>
        </button>
    </div>
</div>
