import { Component, OnInit } from '@angular/core';
import { ContextOptionsService } from '@app/services/context-options.service';
import { ProjetoApiService } from '@app/services/projeto-api.service';
import { AsideOpenService } from '@app/services/aside-open.service';
import { AuthService } from '@app/services/auth.service';

import { swipeLeft } from '@app/animations/swipeLeft';

@Component({
    selector: 'app-menu-aside',
    templateUrl: './menu-aside.component.html',
    styleUrls: ['./menu-aside.component.scss'],
    animations: [ swipeLeft ]
})
export class MenuAsideComponent implements OnInit {

    constructor(
        public projetoApi: ProjetoApiService,
        public contextOptions: ContextOptionsService,
        public asideOpen: AsideOpenService,
        public auth: AuthService
    ) {
    }

    ngOnInit() {
    }

    public getProjeto() {
        return this.projetoApi.list_all();
    }

    public trackFn(index, item) {
        return index;
    }
}
