import { Injectable } from '@angular/core';
import { FontesApiService } from './fontes-api.service';
import { RecursosApiService } from './recursos-api.service';
import { VariaveisApiService } from './variaveis-api.service';

@Injectable({
    providedIn: 'root'
})
export class DesenhosApiService {

    constructor(
        public fontesApi: FontesApiService,
        public recursosApi: RecursosApiService,
        public variavelApi: VariaveisApiService,
    ) { }

    public async list_all() {
        const fontes = this.fontesApi.list_all();
        const recursos = this.recursosApi.list_all();
        const variaveis = this.variavelApi.list_all();
        return [...(await fontes), ...(await recursos), ...(await variaveis)];
    }

    public async getTamanho(origem: number, idOrigem: number): Promise<number> {
        if (+origem === 1) {
            const fonte = await this.fontesApi.get_by_id(+idOrigem);
            if (fonte !== undefined)
                return fonte.tamanho;
            else
                return this.fontesApi.list_all()[0].tamanho;
        } else if (+origem == 2) {
            return this.recursosApi.list_all()[0].tamanho;
        } else {
            return 0;
        }
    }
}
