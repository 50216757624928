import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ProjetoApiService } from '@app/services/projeto-api.service';
import { LoaderService } from '@app/services/loader.service';
import { HelpService } from '@app/services/help.service';
import { Alteravel } from '@app/alteravel';
import * as model from '@app/projeto.model';

import { RoteiroApiService } from '@app/services/roteiro-api.service';

@Component({
    selector: 'app-roteiros-cadastrar',
    templateUrl: './roteiros-cadastrar.component.html',
    styleUrls: ['./roteiros-cadastrar.component.scss'],
})
export class RoteirosCadastrarComponent implements OnInit, Alteravel {

    public get alteracao() {
        const newRot = JSON.stringify(this._roteiros);
        const oldCop = JSON.stringify(this._roteiros_orig);
        return newRot !== oldCop;
    }

    public pesquisa: string;
    public tipoExibido: string = "roteiro";
    private projeto_id: number;
    public projeto: model.Projeto;
    private _roteiros: model.Roteiro[] = [];
    private _roteiros_para_deletar: model.Roteiro[] = [];
    private _roteiros_orig: model.Roteiro[] = [];
    public movendo = null;

    tooltips = {
        'move': $localize `Ordenar`,
    };

    set roteiros(roteiros: model.Roteiro[]) {
        this._roteiros = roteiros;
    }

    get roteiros(): model.Roteiro[] {
        if (this._roteiros) {
            return this._roteiros.filter(
                roteiro => this.tipoExibido == 'extra' && roteiro.extra || this.tipoExibido == 'roteiro' && !roteiro.extra
            );
        }
    }

    constructor(
        private route: ActivatedRoute,
        private projetosApi: ProjetoApiService,
        private roteirosApi: RoteiroApiService,
        private router: Router,
        private loader: LoaderService,
        private help: HelpService,
        private titleService: Title,
        private toastr: ToastrService,
    ) { }

    async ngOnInit() {
        this.pesquisa = '';

        this.loader.startLoading();
        this.projeto_id = +this.route.snapshot.paramMap.get('id');
        const projeto = await this.projetosApi.get_by_id(this.projeto_id);
        this.projeto = projeto;
        this.loader.stopLoading();

        if (projeto == undefined) {
            return this.router.navigateByUrl('/home');
        }
        const roteiros = await this.roteirosApi.list_by_parent_id(projeto.id);

        // Atualizar os codigos dos retornos
        roteiros.forEach(roteiro => {
            if (!roteiro.id_retorno) return;
            const retorno = roteiros.find(other => roteiro.id_retorno == other.id);
            roteiro.cod_retorno = retorno.cod;
        });

        const roteiros_json = JSON.stringify(roteiros);
        this._roteiros = JSON.parse(roteiros_json);
        this._roteiros_orig = JSON.parse(roteiros_json);

        this.help.local = 'roteiros';
        this.titleService.setTitle($localize `Roteiros - Thoreb Itinerário`);
    }

    trocaTipo(tipo: string) {
        this.tipoExibido = tipo;
    }

    novo() {
        let nova_ordem = this._roteiros
            .map(roteiro => +roteiro.ordem)
            .reduce((a, b) => a > b ? a: b, 0);
        this._roteiros.push({
            'id': undefined,
            'ordem': (+nova_ordem) + 100,
            'nome': '',
            'extra': false,
            'cod': '',
            'parent_id': this.projeto_id,
        });
    }

    novoExtra() {
        let nova_ordem = this._roteiros
            .map(roteiro => +roteiro.ordem)
            .reduce((a, b) => a > b ? a: b, 0);
        this._roteiros.push({
            'id': undefined,
            'ordem': (+nova_ordem) + 100,
            'nome': '',
            'extra': true,
            'cod': '',
            'parent_id': this.projeto_id,
        });
    }

    duplicar(roteiro: model.Roteiro) {
        let nova_ordem = this._roteiros
            .map(roteiro => +roteiro.ordem)
            .reduce((a, b) => {
                // colocar o novo roteiro no meio da lista, logo depois do que foi duplicado.
                if (a < b && a > roteiro.ordem) {
                    return (a + roteiro.ordem) / 2.0;
                } else if (b < a && b > roteiro.ordem) {
                    return (b + roteiro.ordem) / 2.0;
                } else {
                    return roteiro.ordem + 100;
                }
            }, 0);

        this._roteiros.push({
            'id': undefined,
            'ordem': +nova_ordem,
            'nome': roteiro.nome,
            'extra': roteiro.extra,
            'cod': '',
            'parent_id': roteiro.parent_id,
            'id_para_copia': roteiro.id,
        });

        this.sort();
    }

    async salvar() {
        try {
            this.loader.startLoading();

            // Atualizar as ids dos retornos
            this.roteiros.forEach(roteiro => {
                if (!roteiro.cod_retorno) {
                    roteiro.id_retorno = null;
                    return;
                };
                const retorno = this.roteiros.find(other => roteiro.cod_retorno == other.cod);
                roteiro.id_retorno = retorno?.id;
            });

            await this.roteirosApi.bulk_save(this.projeto_id, this._roteiros);

            this._roteiros_orig = this._roteiros;

            this.router.navigateByUrl('/home');
        } finally {
            this.loader.stopLoading();
        }
    }

    excluir(roteiro: model.Roteiro) {
        this._roteiros_para_deletar.push(
            this._roteiros.find(r => r == roteiro)
        );
        this.roteiros = this._roteiros.filter(
            r => r != roteiro
        );
    }

    ordem_anterior(roteiro: model.Roteiro) {
        let roteiros = this.roteiros;

        let roteiro_idx = roteiros.indexOf(roteiro);
        if(roteiro_idx < 0)
            throw new Error("Roteiros não está cadastrado corretamente");

        let ordem_atual = roteiro.ordem;
        if (roteiro_idx == 0) {
            return +ordem_atual - 100;
        }

        let ordem_anterior = (roteiros[roteiro_idx - 1]).ordem;
        return (+ordem_atual + +ordem_anterior) / 2;
    }

    ordem_seguinte(roteiro: model.Roteiro) {
        let roteiros = this.roteiros;

        let roteiro_idx = roteiros.indexOf(roteiro);
        if(roteiro_idx < 0)
            throw new Error("Roteiros não está cadastrado corretamente");

        let ordem_atual = roteiro.ordem;
        if (roteiro_idx == (roteiros.length - 1)) {
            return +ordem_atual + 100;
        }

        let ordem_seguinte= (roteiros[roteiro_idx + 1]).ordem;
        return (+ordem_atual + +ordem_seguinte) / 2;
    }

    mover_iniciar(roteiro: model.Roteiro) {
        if (roteiro == this.movendo)
            return this.movendo = null;
        if (this.movendo == null)
            this.toastr.info($localize `Escolha para onde mover o roteiro`);
        this.movendo = roteiro;
    }

    mover_finalizar(destination_idx: number) {
        if (this.movendo == null)
            return;

        if(destination_idx == undefined) {
            throw new Error("Número inválido para reordenar");
        }

        this.movendo.ordem = +destination_idx;
        this.movendo = null;
        this.sort();
    }

    sort() {
        this._roteiros.sort((a, b) => a.ordem - b.ordem);
        let ordem = 0;
        this._roteiros.forEach(r => {
            r.ordem = ordem;
            ordem += 10;
        });
        this.roteiros = this._roteiros;
    }

    getRoteiros() {
        return this.roteiros.filter(
            roteiro => this.pesquisa === '' || roteiro.nome.includes(this.pesquisa) || roteiro.cod.includes(this.pesquisa)
        );
    }

    track(index, item) {
        return index;
    }
}
