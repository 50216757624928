import { Injectable } from '@angular/core';
import * as model from '@app/projeto.model';

import { ErrorHandlerService } from '@app/shared/services/error-handler.service';
import { EntityService } from '@app/entity-service';
import { RoteiroApi } from './roteiro.api';
// import { ProjetoApiService } from './projeto-api.service';
import { ToastsService } from '@app/toasts.service';

import { MensagemApiService } from './mensagem-api.service';

@Injectable({
    providedIn: 'root'
})
export class RoteiroApiService implements EntityService<model.Roteiro, model.Projeto> {
    private roteiros: model.Roteiro[] = [];
    private roteiros_by_parent: Record<number, model.Roteiro[]> = {};
    private roteiroAtivo: model.Roteiro;

    constructor(
        private toasts: ToastsService,
        private api: RoteiroApi,
//         private parent: ProjetoApiService,
        private errorHandler: ErrorHandlerService,
        private mensagemApi: MensagemApiService,
    ) { }

    async get_by_id(id: number): Promise<model.Roteiro> {
        const close_toast = this.toasts.api_info($localize `Buscando roteiros`);
        try {
            let projeto = await this.api.get_by_id(id);
            return projeto;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os roteiros`);
        } finally {
            close_toast();
        }
    }
    async list_all(): Promise<model.Roteiro[]> {
        if (this.roteiros != undefined)
            return this.roteiros;
        const close_toast = this.toasts.api_info($localize `Buscando roteiros`);
        try {
            this.roteiros = await this.api.list_all();
            this.sort();
            return this.roteiros;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os roteiros`);
        } finally {
            close_toast();
        }
    }
    async save(item: model.Roteiro) {
        const close_toast = this.toasts.api_info($localize `Salvando projeto`);
        try {
            const data = await this.api.save(item);
            this.toasts.api_success($localize `Salvo com sucesso`);
            this.replaceRoteiro(item, data);
            return data;
        } catch (error) {
            this.errorHandler.handleError(error).subscribe();
        } finally {
            this.clear_caches();
            close_toast();
        }
    }
    async bulk_save(id_projeto: number, items: model.Roteiro[]) {
        const close_toast = this.toasts.api_info($localize `Salvando projeto`);
        try {
            const data = await this.api.bulk_save(id_projeto, items);
            this.toasts.api_success($localize `Salvo com sucesso`);
            return data;
        } catch (error) {
            this.errorHandler.handleError(error).subscribe();
            throw error;
        } finally {
            this.clear_caches();
            close_toast();
        }
    }
    clone(item: model.Roteiro): Promise<model.Roteiro> {
        throw new Error('Method not implemented.');
    }
    async delete(item: model.Roteiro): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize `Excluindo o roteiro`);
        const remover = () => {
            this.toasts.api_success($localize `Excluído com sucesso`);
            this.roteiros = this.roteiros
                .filter((projetoIt) => projetoIt != item);
            this.clear_selected()
        };

        try {
            if (item.id != undefined)
                await this.api.delete(item);
            remover();
            return true;
        } catch (error) {
            this.toasts.api_error($localize `Não foi possível excluir o roteiro`);
            return false;
        } finally {
            this.clear_caches();
            close_toast();
        }
    }
    // get_parent(item: model.Roteiro): Promise<model.Projeto> {
    //     throw new Error('Method not implemented.');
    //     // return this.parent.get_by_id(item.id);
    // }
    async list_by_parent_id(parent_id: number): Promise<model.Roteiro[]> {
        if (this.roteiros_by_parent[parent_id]) {
            return this.roteiros_by_parent[parent_id];
        }

        const close_toast = this.toasts.api_info($localize `Buscando roteiros`);
        try {
            let res = await this.api.list_by_parent_id(parent_id);
            res.sort((a, b) => a.ordem - b.ordem);
            this.roteiros_by_parent[parent_id] = res;
            return res;
        } catch (error) {
            this.toasts.api_error($localize `Erro ao carregar os roteiros`);
        } finally {
            close_toast();
        }
    }

    select(item: model.Roteiro) {
        this.roteiroAtivo = item;
        this.mensagemApi.clear_caches();
    }
    get_selected(): model.Roteiro {
        return this.roteiroAtivo;
    }
    is_selected(item: model.Roteiro): boolean {
        return this.roteiroAtivo == item;
    }
    clear_selected() {
        this.roteiroAtivo = undefined;
    }
    clear_caches() {
        this.roteiros = undefined;
        this.roteiros_by_parent = {};
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////

    sort() {
        function sort_impl(roteiros_desordenados) {
            roteiros_desordenados.sort((a, b) => a.ordem - b.ordem);

            let roteiros = [];
            let extras = [];
            for(let roteiro of roteiros_desordenados) {
                if (roteiro.extra) extras.push(roteiro);
                else roteiros.push(roteiro);
            }
            roteiros_desordenados.length = 0;
            roteiros_desordenados.push(...roteiros, ...extras);
        }
        if(this.roteiros) sort_impl(this.roteiros);
        if(this.roteiros_by_parent) Object.entries(this.roteiros_by_parent).forEach(sort_impl);
    }

    public replaceRoteiro(old: model.Roteiro, newP: model.Roteiro) {
        if(!this.roteiros) return;
        if (old.id == undefined) {
            this.roteiros.push(newP);
            this.sort();
            return
        }

        this.roteiros = this.roteiros.map(
            proj => (+proj.id === +old.id) ? newP : proj
        )
    }

}
