import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-help-fontes',
  templateUrl: './help-fontes.component.html',
  styleUrls: ['./help-fontes.component.scss']
})
export class HelpFontesComponent implements OnInit {

    @Output() fechar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }


}
