import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateResourcesService } from '@app/services/translate-resources.service';
import { GrupoService } from '@app/editor/grupo.service';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-editor-view',
    templateUrl: './editor-view.component.html',
    styleUrls: ['./editor-view.component.scss'],

})
export class EditorViewComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() painel: model.Painel;
    @Input() mensagem: model.Mensagem;
    @Input() rects: boolean;

    _selected: boolean = false;
    @Input()
    set selected(selected: boolean) {
        this._selected = selected;
        this.cdr.detectChanges();
    }
    get selected() {
        return this._selected;
    }

    @Output() editar = new EventEmitter<model.Grupo>();
    @Output() keyPressEvent = new EventEmitter<KeyboardEvent>();
    @Output() keyDownEvent = new EventEmitter<KeyboardEvent>();
    @Output() pasteEvent = new EventEmitter<ClipboardEvent>();

    @HostListener('document:paste', ['$event'])
    pasteListener(event: ClipboardEvent) {
        if (document.activeElement != this.canvas.nativeElement) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        this.pasteEvent.emit(event);
    }

    public pontos: model.Ponto[] = [];
    private retangulos;

    // flicker
    private flickerInterval;
    private dashOffset = 0;
    private flickerRect;
    private subs: Subscription;

    public waiting: boolean;

    @ViewChild('canvas') public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;

    public constructor(
        private cdr: ChangeDetectorRef,
        private translateRes: TranslateResourcesService,
        private grupoService: GrupoService
    ) {
        this.retangulos = [];
    }

    public ngOnInit() {
    }

    public async ngAfterViewInit() {
        await this.makePoints();

        // remove o componente da árvore de detecção
        // isso melhora imensamente a performance da aplicação,
        // pois o angular não precisa recarregar o canvas a cada ciclo de updates
        this.cdr.detach();

        const canvasEl = HTMLCanvasElement = this.canvas.nativeElement;

        this.cx = canvasEl.getContext('2d');

        this.cx.canvas.width = this.painel.dimension_x * 20;
        this.cx.canvas.height = this.painel.dimension_y * 20;

        this.draw();

        this.subs = this.grupoService.grupo$.subscribe((grupo: model.Grupo) => {
            if (this.flickerRect != undefined && this.flickerRect.grupo != grupo) {
                this.stopFlicker();
            }

            if (grupo && this.mensagem.grupos.indexOf(grupo) != -1) {

                this.flicker({
                    'grupo': grupo,
                    'start_x': +grupo.start_x * 20,
                    'start_y': +grupo.start_y * 20,
                    'end_x': (+grupo.end_x + 1) * 20,
                    'end_y': (+grupo.end_y + 1) * 20
                });
            }

        });
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
        this.stopFlicker();
    }

    public focusEditor() {
        console.log("Focusing editor");

        this.canvas.nativeElement.focus();
    }

    public async update() {
        // cada vez que essa função for chamada, ele vai detectar as mudanças
        // e rerenderizar tudo
        if (this.cx !== undefined) {
            this.cdr.detectChanges();
            await this.makePoints();
            this.draw(); // desenhar depois de detectar as mudanças e de refazer os pontos
        }
    }

    public async makePoints() {
        // traduz os pontos do grupo
        let translated$ = this.mensagem.grupos.map((grupo: model.Grupo) => this.translateRes.translate(grupo));
        let translated = await Promise.all(translated$);
        this.pontos = translated.reduce((acc, r) => [...acc, ...r], []);

        if (this.pontos.length == 0)
            console.warn("Nenhum ponto cadastrado");

        // calcular os retângulos
        this.retangulos = [];

        this.mensagem.grupos.forEach((grupo: model.Grupo) => {
            const start_x = +grupo.start_x * 20;
            const start_y = +grupo.start_y * 20;
            const end_x = (+grupo.end_x + 1) * 20;
            const end_y = (+grupo.end_y + 1) * 20;

            this.retangulos.push({
                'grupo': grupo,
                'start_x': start_x,
                'start_y': start_y,
                'end_x': end_x,
                'end_y': end_y
            });
        });
    }

    private point_drawing_cache = {};
    private getCircleWithColor(color): CanvasImageSource {
        if (this.point_drawing_cache[color])
            return this.point_drawing_cache[color];

        console.log("Circle with color", color);

        var texture = document.createElement('canvas');
        texture.width = 16;
        texture.height = 16;
        var ctx = texture.getContext('2d');
        ctx.fillStyle = color;
        ctx.arc(8, 8, 8, 0, 2 * Math.PI, false);
        ctx.fill();

        this.point_drawing_cache[color] = texture;

        return texture;
    }

    private empty_canvas_cache = {
        width: 0,
        height: 0,
        canvas: undefined,
    };
    private drawEmptyCanvas(): CanvasImageSource {
        if (this.empty_canvas_cache.width == this.canvas.nativeElement.width &&
            this.empty_canvas_cache.height == this.canvas.nativeElement.height) {
            return this.empty_canvas_cache.canvas;
        }
        console.log("Init empty canvas texture");

        var off = document.createElement('canvas');
        off.width = this.canvas.nativeElement.width;
        off.height = this.canvas.nativeElement.height;
        var ctx = off.getContext('2d');

        ctx.fillStyle = '#000000';
        ctx.clearRect(0, 0, off.width, off.height); // limpar o canvas

        for (let x = 0; x < this.painel.dimension_x; x++) {
            for (let y = 0; y < this.painel.dimension_y; y++) {
                let texture = this.getCircleWithColor('#333');
                ctx.drawImage(texture, +x * 20, +y * 20);
            }
        }

        this.empty_canvas_cache = {
            width: off.width,
            height: off.height,
            canvas: off,
        };

        return off;
    }

    private draw_group(grupo, enabled_points) {
        for (let x = grupo.start_x; x <= grupo.end_x; x++) {
            for (let y = grupo.start_y; y <= grupo.end_y; y++) {
                let point = enabled_points[x][y];
                let normal_group = point && !grupo.invertido;

                let empty_text_group = grupo.origem === 1 && grupo.content.length === 0;
                let inverted_group_with_content = !point && grupo.invertido && !empty_text_group;

                if (grupo.corFundo != undefined) {
                    let texture = this.getCircleWithColor(grupo.corFundo);
                    this.cx.drawImage(texture, +x * 20, +y * 20);
                }
                if (normal_group || inverted_group_with_content) {
                    let texture = this.getCircleWithColor(grupo.cor);
                    this.cx.drawImage(texture, +x * 20, +y * 20);
                }
                if (point && point.color) {
                    let texture = this.getCircleWithColor(point.color);
                    this.cx.drawImage(texture, +x * 20, +y * 20);
                }
            }
        }
    }

    public draw(): void {
        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas

        let enabled_points = Array(this.painel.dimension_x);
        for (let x = 0; x < this.painel.dimension_x; x++) {
            enabled_points[x] = Array(this.painel.dimension_y);
            for (let y = 0; y < this.painel.dimension_y; y++) {
                enabled_points[x][y] = false;
            }
        }

        this.pontos.forEach((ponto) => {
            try {
                enabled_points[ponto.x][ponto.y] = ponto;
            } catch (e) {
                console.error("Indice inválido ao desenhar", ponto);
            }
        });

        let empty_canvas = this.drawEmptyCanvas();
        this.cx.drawImage(empty_canvas, 0, 0);

        for (let grupo of this.mensagem.grupos) {
            this.draw_group(grupo, enabled_points);
        }

        if (this.rects) {
            this.retangulos.forEach(retangulo => {
                this.cx.beginPath();
                this.cx.lineWidth = 4;
                this.cx.strokeStyle = 'red';

                this.cx.rect(retangulo.start_x, retangulo.start_y, (retangulo.end_x - retangulo.start_x), (retangulo.end_y - retangulo.start_y));
                this.cx.stroke();
            });
        }
    }

    public registerClick(click: MouseEvent) {
        const pos = {
            x: click.offsetX,
            y: click.offsetY
        };

        const propXP = pos.x / this.cx.canvas.clientWidth;
        const propYP = pos.y / this.cx.canvas.clientHeight;

        // verificar se tem algum retangulo dentro da zona clicada
        const retangulo = this.retangulos.find(
            (retanguloIt) =>
                propXP >= (retanguloIt.start_x / this.cx.canvas.width) &&
                propXP <= (retanguloIt.end_x / this.cx.canvas.width) &&
                propYP >= (retanguloIt.start_y / this.cx.canvas.height) &&
                propYP <= (retanguloIt.end_y / this.cx.canvas.height)
        );
        if (retangulo !== undefined && this.rects) {
            this.editar.emit(retangulo.grupo);
            this.flickerRect = retangulo;
            this.flicker(retangulo);
        }
    }

    public clickNew(click: MouseEvent) {
        const pos = {
            x: click.offsetX,
            y: click.offsetY
        };
        let propXP = Math.floor(pos.x * this.painel.dimension_x / this.cx.canvas.clientWidth);
        let propYP = Math.floor(pos.y * this.painel.dimension_y / this.cx.canvas.clientHeight);

        if (propXP < 0) {
            propXP = 0;
        } else if (propXP >= this.painel.dimension_x) {
            propXP = +this.painel.dimension_x - 1;
        }
        if (propYP < 0) {
            propYP = 0;
        } else if (propYP >= this.painel.dimension_y) {
            propYP = +this.painel.dimension_y - 1;
        }

        return [propXP, propYP];
    }

    public updateFlicker() {
        if (this.flickerRect) {
            const grupo = this.flickerRect.grupo;

            const start_x = +grupo.start_x * 20;
            const start_y = +grupo.start_y * 20;
            const end_x = (+grupo.end_x + 1) * 20;
            const end_y = (+grupo.end_y + 1) * 20;

            const retangulo = {
                'grupo': grupo,
                'start_x': start_x,
                'start_y': start_y,
                'end_x': end_x,
                'end_y': end_y
            };
            this.flicker(retangulo);
        }
    }

    public flicker(retangulo: any) {
        this.stopFlicker();

        this.flickerInterval = setInterval(() => {
            this.cx.beginPath();

            this.cx.strokeStyle = 'black';
            this.cx.lineWidth = 4;
            this.cx.strokeRect(retangulo.start_x, retangulo.start_y, (retangulo.end_x - retangulo.start_x), (retangulo.end_y - retangulo.start_y));

            this.cx.setLineDash([10, 10]);
            this.cx.lineDashOffset = -this.dashOffset;
            this.cx.strokeStyle = 'red';

            this.cx.strokeRect(retangulo.start_x, retangulo.start_y, (retangulo.end_x - retangulo.start_x), (retangulo.end_y - retangulo.start_y));
            this.cx.setLineDash([]);

            if (document.activeElement == this.canvas.nativeElement) {
                this.dashOffset++;
            }
            if (this.dashOffset > 256) {
                this.dashOffset = 0;
            }
        }, 10);

        this.flickerRect = retangulo;
    }

    public stopFlicker() {
        this.flickerRect = undefined;
        if (this.flickerInterval) {
            this.draw(); // desenhar de novo o canvas
            clearInterval(this.flickerInterval);
        }
    }

}
