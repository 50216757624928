import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as model from '@app/projeto.model';

@Component({
    selector: 'app-editor-atalhos-modal',
    templateUrl: './editor-atalhos-modal.component.html',
    styleUrls: ['./editor-atalhos-modal.component.scss']
})
export class EditorAtalhosModalComponent implements OnInit {

    @Output() fechar = new EventEmitter<boolean>();
    @Input() shortcuts;

    constructor() { }

    ngOnInit() {
    }

    dispensar() {
        this.fechar.emit(true);
    }
}
