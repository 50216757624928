import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[containedInOptions]',
    providers: [
        {
            provide: NG_VALIDATORS, useExisting: containedInOptionsDirective, multi: true
        }
    ]
})
export class containedInOptionsDirective implements Validator {
    @Input('containedInOptions') options: any;

    validate(c: AbstractControl) {
        const value = c.value;

        if (!value) return null;

        if (this.options.indexOf(value) == -1) {
            return {
                'containedInOptions': true
            };
        }

        return null;
    }

}
