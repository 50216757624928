<div class="floating-form-div bordered">
    <!-- efeito -->

    <span [attr.tooltip]="tooltips.efeito" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-flash.svg#icon"></use>
            </svg>
        </i>
        <select class="" name="efeito" [(ngModel)]="grupo.efeito" (change)='alterar()'  #efeito (ngModelChange)="efeito.blur()">
            <option value="0" i18n>Estático</option>
            <option value="1" i18n>Down</option>
            <option value="3" i18n>Up</option>
            <option value="2" [disabled]="possuiRolagem()" i18n>Rolagem</option>
        </select>
        <span *ngIf="grupo.efeito == 2">
            <!-- <span> -->
                {{grupo.content}}
            <!-- </span> -->
        </span>
    </span>



    <!-- tipo -->
    <span [attr.tooltip]="tooltips.cor" class="item" *ngIf="painel.rgb">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-color.svg#icon"></use>
            </svg>
        </i>
        <label for="cutomizar" class="clickable" i18n>Customizável</label>
        &nbsp;
        <input id="cutomizar" type="checkbox" name="cutomizar" [checked]="grupo.useColors" (change)="toggleCustomizavel()">
    </span>

    <span *ngIf="grupo.useColors" class="item">
        <label i18n>Cor da fonte</label>
        &nbsp;
        <app-color-picker [cor]="grupo.cor" (atualizar$)="alterarCor($event)"></app-color-picker>
    </span>

    <span *ngIf="grupo.useColors" class="item">
        <label i18n>Cor do fundo</label>
        &nbsp;
        <app-color-picker [cor]="grupo.corFundo" (atualizar$)="alterarCorFundo($event)"></app-color-picker>
    </span>

    <span [attr.tooltip]="tooltips.inverter" class="item">
        <label for="inverter" class="clickable" i18n>Inverter</label>
        &nbsp;
        <input id="inverter" type="checkbox" name="inverter" [checked]="grupo.invertido" (change)="toggleInvertido()">
    </span>

    <span [attr.tooltip]="tooltips.tipo" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-font.svg#icon"></use>
            </svg>
        </i>
        <select class="" name="origem" [(ngModel)]="grupo.origem" (change)='alterarTipo();' #origem (ngModelChange)="origem.blur()">
            <option value="1" i18n>Fonte</option>
            <option value="2" i18n>Recurso</option>
            <option value="3" i18n>Variável</option>
        </select>
    </span>

    <ng-container *ngIf="grupo.origem == 1"> <!-- se for fonte, precisa abrir mais campos -->
        <span [attr.tooltip]="tooltips.fonte" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-font-type.svg#icon"></use>
                </svg>
            </i>
            <select class="" name="idOrigem" [(ngModel)]="grupo.idOrigem" (change)='alterarFonte(+fontes_select.value);' #fontes_select (ngModelChange)="fontes_select.blur()">
                <option value="{{fonte.id}}" *ngFor="let fonte of filtrarFontesComCaracteres(fontes)">{{fonte.nome}}</option>
            </select>
        </span>
    </ng-container>

    <ng-container *ngIf="grupo.origem == 3"> <!-- se for variável -->
        <span [attr.tooltip]="tooltips.fonte" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-variable.svg#icon"></use>
                </svg>
            </i>
            <select class="" name="tipoVariavel" (change)='alterarVariavel(+variavel_select.value, +fonte_select.value); variavel_select.blur()' #variavel_select [ngModel]="infoVariavel(grupo.idOrigem).tipoVariavel">
                <option value="1" *ngIf="filtrarTipoVariavelPorFonte(1)" i18n>Hora atual</option>
                <option value="2" *ngIf="filtrarTipoVariavelPorFonte(2)" i18n>Temperatura</option>
                <option value="3" *ngIf="filtrarTipoVariavelPorFonte(3)" i18n>Velocidade</option>
                <option value="4" *ngIf="filtrarTipoVariavelPorFonte(4)" i18n>Box de saída</option>
                <option value="5" *ngIf="filtrarTipoVariavelPorFonte(5)" i18n>Data</option>
                <option value="6" *ngIf="filtrarTipoVariavelPorFonte(6)" i18n>Saída</option>
                <option value="7" *ngIf="filtrarTipoVariavelPorFonte(7)" i18n>Chegada</option>
            </select>
        </span>

        <span [attr.tooltip]="tooltips.fonte" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-font-type.svg#icon"></use>
                </svg>
            </i>
            <select class="" name="idFonte" (change)='alterarVariavel(+variavel_select.value, +fonte_select.value); fonte_select.blur()' #fonte_select [ngModel]="infoVariavel(grupo.idOrigem).idFonte">
                <option value="{{fonte.id}}" *ngFor="let fonte of filtrarFontesComVariaveis(fontes)">{{fonte.nome}}</option>
            </select>
        </span>
    </ng-container>

    <ng-container *ngIf="grupo.origem == 2 && recursos.length > 0">
        <span [attr.tooltip]="tooltips.recurso" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-resource.svg#icon"></use>
                </svg>
            </i>
            <select name="idOrigem" (change)='alterarRecurso(+recursos_select.value); recursos_select.blur()' (change)='shouldAutoSize(); alterar()'  #recursos_select (ngModelChange)="recursos_select.blur()">
                <ng-container *ngFor="let recurso of recursos">
                    <option value="{{recurso.id}}" *ngIf="!recurso.rgb || painel.rgb">{{recurso.nome}}</option>
                </ng-container>
            </select>
        </span>
    </ng-container>

    <ng-container *ngIf="grupo.origem == 1 || grupo.origem == 3">
        <button type="button" name="button" (click)="diminuirEspacamento()" [attr.tooltip]="tooltips.diminuirEspacamento" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-spacing-decrease.svg#icon"></use>
                </svg>
            </i>
        </button>
        <span class="item borderless" [attr.tooltip]="tooltips.espacamento">
            <span>
                {{grupo.spacing}}
            </span>
        </span>
        <button type="button" name="button" (click)="aumentarEspacamento()" [attr.tooltip]="tooltips.aumentarEspacamento" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-spacing-increase.svg#icon"></use>
                </svg>
            </i>
        </button>
    </ng-container>

    <button type="button" name="button" (click)="moverEsquerda()" [attr.tooltip]="tooltips.moverEsquerda" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-position-left.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="moverDireita()" [attr.tooltip]="tooltips.moverDireita" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-position-right.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="moverCima()" [attr.tooltip]="tooltips.moverCima" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-position-up.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="moverBaixo()" [attr.tooltip]="tooltips.moverBaixo" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-position-down.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="alinharEsquerda()" [attr.tooltip]="tooltips.alinharEsquerda" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-align-left.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="justificarHorizontal()" [attr.tooltip]="tooltips.justificarHorizontal" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-center-horizontal.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="alinharDireita()" [attr.tooltip]="tooltips.alinharDireita" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-align-right.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="alinharCima()" [attr.tooltip]="tooltips.alinharCima" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-align-top.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="justificarVertical()" [attr.tooltip]="tooltips.justificarVertical" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-center-vertical.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button type="button" name="button" (click)="alinharBaixo()" [attr.tooltip]="tooltips.alinharBaixo" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-align-bottom.svg#icon"></use>
            </svg>
        </i>
    </button>

    <span class="item">
        <label [attr.tooltip]="tooltips.autoSize" for="autoSize" class="clickable" i18n>Auto Size</label>
        &nbsp;
        <input id="autoSize" type="checkbox" name="autoSize" [checked]="grupo.autoSize" (change)="toggleAutoSize()">
    </span>
    <!-- só mostra os controls se não estiver com o cálculo de autosize ativo -->
    <ng-container *ngIf="!grupo.autoSize">
        <button type="button" name="button" (click)="aumentarGrupo()" [attr.tooltip]="tooltips.aumentarGrupo" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-size-increase.svg#icon"></use>
                </svg>
            </i>
        </button>
        <button type="button" name="button" (click)="diminuirGrupo()" [attr.tooltip]="tooltips.diminuirGrupo" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-size-decrease.svg#icon"></use>
                </svg>
            </i>
        </button>
        <button type="button" name="button" (click)="aumentarAltura()" [attr.tooltip]="tooltips.aumentarAltura" class="item">
            <i class="size-2 icon ">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-size-increase-height.svg#icon"></use>
                </svg>
            </i>
        </button>
        <button type="button" name="button" (click)="diminuirAltura()" [attr.tooltip]="tooltips.diminuirAltura" class="item">
            <i class="size-2 icon">
                <svg viewBox="0 0 24 24">
                    <use xlink:href="assets/img/icon-size-decrease-height.svg#icon"></use>
                </svg>
            </i>
        </button>
    </ng-container>
    <button id="increase-horizontal-padding-button" type="button" name="button" (click)="aumentarOffsetX()" [attr.tooltip]="tooltips.aumentarOffsetHorizontal" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-space-inside-right.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button id="decrease-horizontal-padding-button" type="button" name="button" (click)="diminuirOffsetX()" [attr.tooltip]="tooltips.diminuirOffsetHorizontal" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-space-inside-left.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button id="increase-vertical-padding-button" type="button" name="button" (click)="aumentarOffsetY()" [attr.tooltip]="tooltips.aumentarOffsetVertical" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-space-inside-down.svg#icon"></use>
            </svg>
        </i>
    </button>
    <button id="decrease-vertical-padding-button" type="button" name="button" (click)="diminuirOffsetY()" [attr.tooltip]="tooltips.diminuirOffsetVertical" class="item">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-space-inside-up.svg#icon"></use>
            </svg>
        </i>
    </button>

    <button type="button" name="button" (click)="replicarGrupo()" [attr.tooltip]="tooltips.replicar" class="item">
        <i class="size-2 icon ">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-filter-none.svg#icon"></use>
            </svg>
        </i>
    </button>


    <button type="button" name="button" (click)="excluirGrupo()" [attr.tooltip]="tooltips.excluir" class="item delete-group-button">
        <i class="size-2 icon">
            <svg viewBox="0 0 24 24">
                <use xlink:href="assets/img/icon-delete-2.svg#icon"></use>
            </svg>
        </i>
    </button>
</div>
