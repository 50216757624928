<div class="modal-header" i18n>
    AJUDA DO EDITOR
</div>
<div class="modal-content">
    <p>
        <strong i18n>Na página editor, você pode cadastrar, editar e visualizar as mensagens que aparecerão no Itinerário.</strong>
    </p>
    <p i18n>
        Ao escolher um projeto, um roteiro e um painel, você é redirecionado para esta página, que é onde você vai poder escrever o conteúdo de cada roteiro. Aqui está um exemplo de mensagens cadastradas:
    </p>
    <img class="full-img" src="assets/img/ajuda-editor.png" alt="Roteiro vazio" i18n-alt>
    <p i18n>
        Neste caso, temos 2 mensagens que serão exibidas uma após a outra <em>(você pode conhecer isso como <strong>rolagem</strong>, mas, no Editor Thoreb, rolagem possui outro significado)</em>.
        A primeira mensagem possui os grupos invertidos.
    </p>
    <p i18n>
        O primeiro título dá informações sobre o roteiro atual que está sendo editado, além de fornecer atalhos para navegar para a tela de edição de roteiros ou editar
        o próximo roteiro do projeto. Logo abaixo são exibidos os paineis do projeto, estando <strong>azul</strong> o painel selecionado e <strong>amarelo</strong> o
        painel vazio (sem mensagens). Também é disponibilizada a opção de replicar o conteúdo do painel atual para os outros. Em caso de dúvidas, consulte o tutorial.
    </p>
    <p i18n>
        Para começar a editar, é só clicar em <em>Nova mensagem</em>!
        Após criar uma nova mensagem, você verá um painel que simula um Itinerário. É neste painel que você vai editar os textos e os recursos.
    </p>
    <img class="full-img" src="assets/img/ajuda-editor-2.png" alt="Nova mensagem" i18n-alt>
    <p i18n>
        Os grupos são identificados por uma borda vermelha (elas não são renderizadas no Itinerário depois da edição, apenas nesta tela), e o grupo com uma borda
        animada é o que está selecionado e que sofrerá as alterações. Uma vez que você esteja com um grupo selecionado, é só sair digitando!
    </p>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-icones-mensagem.png" alt="Ícones da mensagem" i18n-alt>
        <span i18n>
            Ao lado do nome da mensagem, podemos ver alguns ícones, sendo que cada um é uma ação diferente que será executada ao ser clicado.
        </span>
        <ul>
            <li i18n>No ícone de relógio um controle para a duração da mensagem é exibido</li>
            <li i18n>Ícone de arquivos representa a função duplicar mensagem</li>
            <li i18n>A lixeira representa a opção de excluir mensagem</li>
        </ul>
    </div>

    <h2 i18n>Sobre o menu flutuante</h2>

    <p i18n>O menu flutuante é a caixinha com vários ícones dentro, e é responsável por prover as opções de edição de grupo e de desenho</p>
    <img class="full-img" src="assets/img/ajuda-editor-flutuante.png" alt="Menu flutuante" i18n-alt>
    <p i18n>Cada ícone é uma funcionalidade diferente do Editor Thoreb, e cada um foi construído pensando na flexibilidade que o software poderá oferecer!</p>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-efeito.png" alt="Efeitos" i18n-alt>
        <span>
            <ng-container i18n>O primeiro item do menu flutuante é um dropdown que representa o efeito de transição do grupo no Itinerário. Atualmente, o editor possui 3 efeitos:</ng-container>
            <ul>
                <li i18n>Estático, onde o grupo permanece estático na tela (é necessário replicar o grupo para as próximas mensagens)</li>
                <li i18n>Down, onde o grupo possui um efeito de "queda"</li>
                <li i18n>Rolagem, onde o grupo rolará o texto do fim para o começo (é interessante combinar esta opção com um grupo de tamanho fixo)</li>
            </ul>
            <ng-container i18n>
                Ao selecionar a opção rolagem, como o texto pode ficar invisível (pois o texto pode ser maior do que o tamanho do grupo), o sistema exibe ao lado do dropdown
                o texto que está escrito no grupo atual.
            </ng-container>
        </span>
    </div>
    <h2 i18n>Modificadores</h2>
    <div class="row">
        <p i18n>
            Com esses controles você tem a opção de alterar a cor dos LEDs (no caso de seu painel ser de uma cor diferente do padrão) e inverter o modo como é renderizado o texto.
            <strong>Atenção!</strong> A cor do LED somente irá alterar a visualização no software web, enquanto que a cor nos paineis físicos é definida pelo tipo de LED usado em sua
            construção.
        </p>
        <img class="inline-img" src="assets/img/ajuda-editor-modificadores.png" alt="Modificadores de cor e inversão" i18n-alt>
    </div>
    <h2 i18n>Fontes</h2>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-fontes.png" alt="Efeitos" i18n-alt>
        <span i18n>
            Os itens de fontes e recursos são a opção de desenhos que o software oferece. Com as fontes, é possível escrever em vários tamanhos e estilos diferentes.
            Já com os recursos, é possível inserir desenhos que você cria nos paineis. Quando você seleciona a opção <em>fonte</em>, as opções de espaçamentos são
            habilitadas.
        </span>
        <img class="inline-img" src="assets/img/ajuda-editor-espacamento.png" alt="Espaçamento" i18n-alt>
    </div>
    <h2 i18n>Posição do grupo</h2>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-posicao.png" alt="Posição dos elementos" i18n-alt>
        <span i18n>
            Os próximos ícones são responsáveis pela posição do grupo no painel. Há quatro ícones de alterar a posição manualmente do grupo, (um para cada lado) e seis
            responsáveis pelo alinhamento. Existem opções para alinhar o grupo aos lados, topo, abaixo e centralizar. A centralização funciona posicionando o grupo na
            metade (vertical ou horizontal) do espaço disponível para ele.
        </span>
    </div>
    <h2 i18n>Auto Size</h2>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-autosize.png" alt="Auto Size" i18n-alt>
        <span i18n>
            Auto Size é uma funcionalidade que atualiza o tamanho do grupo (a borda vermelha representa o tamanho do grupo) conforme necessário, por exemplo, ao
            digitar novos caracteres, alterar o espaçamento ou alterar o tamanho da fonte. O Auto Size vem ativado por padrão em cada grupo, mas você pode desativá-lo
            ou ativá-lo sempre que quiser. Ao desativar, serão disponibilizados novos controles para alterar o tamanho do grupo manualmente.
        </span>
    </div>
    <h2 i18n>Replicar e Excluir</h2>
    <div class="row">
        <img class="inline-img" src="assets/img/ajuda-editor-replicar.png" alt="Replicar e excluir" i18n-alt>
        <span i18n>
            Por último, estão os ícones de excluir e replicar. A função de replicar, copia o grupo selecionado para todas as outras mensagens do mesmo painel que está sendo
            editado. Esta funcionalidade remove grupos vazios que estão na mesma posição (como aqueles criados ao criar uma nova mensagem), mas não replica na mensagem
            que já possui um grupo naquele lugar.<br>
            A função de excluir remove o grupo selecionado.
        </span>
    </div>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
