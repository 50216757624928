<div class="modal-header" i18n>
    AJUDA DO PERFIL
</div>
<div class="modal-content">
    <p>
        <strong i18n>No perfil, você pode ver informações do seu usuário e da sua empresa, bem como ver os outros usuários dela.</strong>
    </p>
    <p i18n>Nesta tela são exibidas as informações da empresa. Caso você seja o administrador dela, você pode gerenciar os outros usuários.</p>
    <img class="full-img" src="assets/img/ajuda-empresa-usuario-1.png" alt="Cadastro da empresa" i18n-alt>
    <p i18n>
        <strong>Atenção!</strong> Você pode cadastrar 3 usuários além do seu na mesma empresa. Todos os usuários veem e editam os mesmos projetos.
    </p>
    <div class="row">
        <p i18n>
            Para o cadastro ou edição de um usuário, você deve informar um email válido e que não esteja cadastrado para outro usuário. Caso o email não se adeque
            a esses critérios, o sistema bloqueará a submissão do formulário até que seja informado um email válido.
        </p>
        <img class="inline-img" src="assets/img/ajuda-empresa-usuario-2.png" alt="Formulário bloqueado" i18n>
    </div>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary" type="button" name="button" (click)="fechar()">Fechar</button> -->
    <a (click)="fechar.emit()" i18n>Fechar</a>
</div>
