import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import * as model from '@app/projeto.model';
import { LoaderService } from '@app/services/loader.service';
import { environment } from '@app/../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ContatoApiService {
    public contatos: model.Contato[] = [];
    private apiPoint = environment.endpoint;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private loader: LoaderService,
    ) { }

    public enviarContato(c: model.Contato): Observable<boolean>{
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        const toast = this.toastr.info($localize `Enviando contato`, '', {
            'disableTimeOut': true
        });



        return this.http.post<boolean>(`${this.apiPoint}/contato`, c, opcoes)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.toastr.success($localize `Obrigado pelo seu contato`, '');
                })
        );
    }

    public buscarContatos(): Observable<model.Contato[]> {
        const toast = this.toastr.info($localize `Buscando contatos`, '', {
            'disableTimeOut': true
        });

        this.loader.startLoading();

        // buscar as fontes da api
        return this.http.get<model.Contato[]>(`${this.apiPoint}/contato`)
            .pipe(
                tap(
                    contatos => this.contatos = contatos
                ),
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.loader.stopLoading();
                })
            );
    }

    public excluirContato(c:model.Contato): Observable<boolean> {
        const opcoes = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };

        const toast = this.toastr.info($localize `Excluindo contato`, '', {
            'disableTimeOut': true
        });



        return this.http.delete<boolean>(`${this.apiPoint}/contato/${c.id}`, opcoes)
            .pipe(
                finalize(() => {
                    setTimeout(() => { toast.toastRef.manualClose(); }, 1000); // toast
                    this.contatos = this.contatos.filter(
                        (v, i) => v != c
                    );
                })
        );
    }
}
