import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-fontes-form-fonte',
    templateUrl: './fontes-form-fonte.component.html',
    styleUrls: ['./fontes-form-fonte.component.scss']
})
export class FontesFormFonteComponent implements OnInit {
    filter_text: String;

    @Input() fonte: model.Fonte;
    @Output() salvar$ = new EventEmitter();

    letras_excluidas: model.Letra[];

    salvando = false;

    constructor() {
        this.letras_excluidas = [];
    }

    async ngOnInit() {
        this.filter_text = null;
        this.letras_excluidas = [];
    }

    adicionarCaractere() {
        this.fonte.letras.push({
            'caractere': '',
            'pontos': [],
            'parent_id': this.fonte.id,
        });
        this.filter_text = null;
    }

    excluirCaractere(index: number) {
        this.letras_excluidas.push(
            ...this.fonte.letras.splice(index, 1)
        );
    }

    RestaurarCaractere(index: number) {
        this.fonte.letras.push(
            ...this.letras_excluidas.splice(index, 1)
        );
    }

    duplicarCaractere(letra: model.Letra) {
        this.fonte.letras.push(
            (({ id, ...attrs }) => attrs).apply(null, [letra])
        );
    }

    async salvar() {
        if (this.salvando)
            return

        this.salvando = true;

        this.salvar$.emit({
            fonte: this.fonte,
            letras: this.fonte.letras,
            letras_excluidas: this.letras_excluidas,
            callback: async (_new_font_id: number) => {
                this.letras_excluidas = [];
                this.salvando = false;
            },
            error_callback: async () => {
                this.salvando = false;
            }
        });
    }

    is_letra_visivel(letra: model.Letra) {
        if (!letra) return false;
        return letra.caractere == this.filter_text || !this.filter_text;
    }

    isFontValid():boolean {
        let letras = this.fonte.letras;
        let isValid = letras.some((letra) => letra.isDuplicated || letra.isEmpty);
        return isValid;
    }
}
