import { Injectable } from '@angular/core';
import { EntityService } from '../entity-service';
import { Recurso } from '../projeto.model';
import { RecursosApi } from './recursos.api';
import { ToastsService } from '../toasts.service';

@Injectable({
    providedIn: 'root'
})
export class RecursosApiService implements EntityService<Recurso, null> {
    private recursos: Recurso[];
    private recursos$: Promise<Recurso[]>;

    constructor(
        private api: RecursosApi,
        private toasts: ToastsService,
    ) { }

    async get_by_id(id: number): Promise<Recurso> {
        let recursos = await this.list_all();
        return recursos.find(r => r.id == id);
    }
    async list_all(): Promise<Recurso[]> {
        if (this.recursos != undefined)
            return this.recursos;
        if (this.recursos$ != undefined)
            return await this.recursos$;

        const close_toast = this.toasts.api_info($localize`Buscando recursos`);
        try {
            this.recursos$ = this.api.list_all();
            this.recursos = await this.recursos$;
            close_toast();
            return this.recursos;
        } catch (error) {
            this.toasts.api_error(
                $localize`Erro ao carregar os recursos`
            )
        } finally {
            this.recursos$ = undefined;
            close_toast();
        }
    }

    async save(item: Recurso) {
        const close_toast = this.toasts.api_info($localize`Salvando recurso`);
        try {
            const data = await this.api.save(item);
            this.toasts.api_success($localize`Salvo com sucesso`);
            this.replacerecurso(item, data);
            return data;
        } finally {
            close_toast();
        }
    }
    clone(_item: Recurso): Promise<Recurso> {
        throw new Error('Method not implemented.');
    }
    async delete(item: Recurso): Promise<boolean> {
        const close_toast = this.toasts.api_info($localize`Excluindo a recurso`);
        const remover_recurso = () => {
            this.toasts.api_success($localize`Excluído com sucesso`);
            this.recursos = this.recursos
                .filter((projetoIt) => projetoIt.id != item.id);
        };

        try {
            if (item.id != undefined)
                await this.api.delete(item);
            remover_recurso();
            return true;
        } catch (error) {
            this.toasts.api_error($localize`Não foi possível excluir o projeto`);
            return false;
        } finally {
            close_toast();
        }
    }

    get_parent(_item: Recurso): Promise<null> {
        throw new Error('Method not implemented.');
    }
    list_by_parent_id(_parent_id: number): Promise<Recurso[]> {
        throw new Error('Method not implemented.');
    }

    select(_item: Recurso) {
        throw new Error('Method not implemented.');
    }
    get_selected(): Recurso {
        throw new Error('Method not implemented.');
    }
    is_selected(_item: Recurso): boolean {
        throw new Error('Method not implemented.');
    }
    clear_selected() {
        throw new Error('Method not implemented.');
    }
    clear_caches() {
        this.recursos = undefined;
    }

    //////////////////////////
    //    Métodos únicos    //
    //////////////////////////


    public replacerecurso(old: Recurso, newP: Recurso) {
        if (old.id == undefined)
            return this.recursos.push(newP);

        this.recursos.map(
            (proj) => (+proj.id === +old.id) ? newP : proj
        )

    }

    async upload(image: File) {
        const close_toast = this.toasts.api_info($localize`Salvando recurso`);
        try {
            const data = await this.api.upload(image);
            this.toasts.api_success($localize`Salvo com sucesso`);
            this.recursos.push(data);
            return data;
        } finally {
            close_toast();
        }
    }
}
