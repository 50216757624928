import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[notEqualMultiple]',
    providers: [
        {
            provide: NG_VALIDATORS, useExisting: NotEqualMultipleDirective, multi: true
        }
    ]
})
export class NotEqualMultipleDirective implements Validator {
    @Input('notEqualMultiple') options: any;

    validate(c: AbstractControl) {

        // const value = c.value;
        // if ((value == null || value == undefined || value == '') && this.notEqualMultiple) {
        //     return {
        //         'notEqualMultiple': {condition: this.requiredIf}
        //     };
        // }
        const value = c.value;
        // console.log('value' + value);
        // console.log('options' + this.options);
        // console.log(JSON.stringify(this.options));
        // console.log('indexof' + this.options.indexOf(value));
        if (this.options.indexOf(value) != -1) {
            return {
                'notEqualMultiple': true
            };
        }
        return null;
    }

}
