<section>
    <article class="central">
        <img src="assets/img/thoreblogo.png" alt="Logo Thoreb" i18n-alt>
        <h2 i18n>Recuperar minha senha</h2>
        <p i18n>
            Para recuperar sua senha, insira o email da conta e depois aguarde o recebimento de um email contendo um código. Insira este código no formulário, e você
            poderá criar uma nova senha. Caso tenha se esquecido do email ou tenha dúvidas, contato o suporte da Thoreb Brasil.
        </p>
        <form #recuperarForm="ngForm">
            <!-- não enviou ainda o email de pedido de troca de senha -->
            <div class="form-section">
                <label for="email" i18n>Email</label>
                <input type="mail" name="email" [(ngModel)]="email" placeholder="Digite seu email" i18n-placeholder>
            </div>
            <button class="btn btn-primary" type="submit" name="button" (click)="enviarEmail()" [disabled]="submitting" *ngIf="!enviouEmail" i18n>Enviar email</button>

            <!-- Já enviou o email com o token de troca senha -->
            <ng-container *ngIf="enviouEmail">
                <div class="form-section">
                    <label for="token" i18n>Código de acesso</label>
                    <input type="text" name="token" [(ngModel)]="token" #tokenT="ngModel" placeholder="Cole o código recebido por email" autocomplete="token" required [class.danger]="!tokenT.valid && !tokenT.pristine" i18n-placeholder>
                    
                    <div class="alert danger" *ngIf="tokenT.invalid && !tokenT.pristine">
                        <div *ngIf="tokenT.errors?.required" i18n>Este campo é obrigatório</div>
                    </div>
                </div>


                <div class="form-section password-container">
                    <label for="password" i18n>Senha</label>
                    <input [attr.type]="passwordToggled ? 'text' : 'password'" name="password" [(ngModel)]="senha" placeholder="Digite sua nova senha" required minlength="8" [pattern]="regex" #senhaT="ngModel" [class.danger]="!senhaT.valid && !senhaT.pristine" i18n-placeholder>
                    <span class="password-icon" (click)="togglePassword()">
                        <i class="icon size-2 clickable">
                            <svg viewBox="0 0 24 24">
                                <use [attr.xlink:href]="passwordToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                            </svg>
                        </i>
                    </span>

                    <div class="alert danger" *ngIf="!senhaT.valid && !senhaT.pristine">
                        <div *ngIf="senhaT.errors.minlength" i18n>Mínimo de 8 caracteres</div>
                        <div *ngIf="senhaT.errors.pattern" i18n>A senha deve possuir pelo menos uma letra e um número</div>
                        <div *ngIf="senhaT.errors.required" i18n>Este campo é obrigatório</div>
                    </div>
                </div>


                <button type="submit" name="button" class="btn btn-success" (click)="trocarSenha()" [disabled]="!recuperarForm.form.valid || submitting" i18n>Alterar senha</button>
            </ng-container>

            <div class="alert danger" *ngIf="error">
                {{error}}
            </div>
        </form>
    </article>
</section>
